import { GuidedWalkthroughPage } from '../data-models/models';

export class NewUserWalkthrough {

    private walkthrough: GuidedWalkthroughPage = {
        page_id: 0,
        title: 'Welcome',
        page_walkthrough_completed: false,
        steps: [
          {
            step_id: 0,
            title: "Let's get started",
            elevateParentElement: 'header',
            target_element_id: 'navContainer',
            target_query_path: '.newUserStepOne',
            step_message: {
              title: "Let's get started",
              message: "Here is where all of your personal account actions and notifications live and it's the start of your journey through Swarm!",
            },
            isTopLevel: false,
            isInformational: false,
            step_completed: false
          },
          {
            step_id: 1,
            title: "Profile",
            elevateParentElement: 'header',
            target_element_id: 'navContainer',
            target_query_path: '.newUserStepTwo',
            step_message: {
              title: "Profile",
              message: "You can access and change all of your account settings, as well, go here to accept any link requests sent by other teams and companies.",
            },
            openUserMenu: true,
            highlightYellow: true,
            isTopLevel: false,
            isInformational: false,
            step_completed: false
          },
          {
            step_id: 2,
            title: "Local Bench Stock",
            elevateParentElement: 'header',
            target_element_id: 'navContainer',
            target_query_path: '.newUserStepThree',
            step_message: {
              title: "Local Bench Stock",
              message: "At times you may check out company inventory and assign it to yourself, check it out for yourself to see what you have been issued.",
            },
            openUserMenu: true,
            highlightYellow: true,
            isTopLevel: false,
            isInformational: false,
            step_completed: false
          },
          {
            step_id: 3,
            title: "Partner Program",
            elevateParentElement: 'header',
            target_element_id: 'navContainer',
            target_query_path: '.newUserStepFour',
            step_message: {
              title: "Partner Program",
              message: "Every day is a missed opportunity, if you know anyone you'd like to join the Swarm team you can refer them here.",
            },
            openUserMenu: true,
            highlightYellow: true,
            isTopLevel: false,
            isInformational: false,
            step_completed: false
          },
          {
            step_id: 4,
            title: "Team Switch",
            elevateParentElement: 'header',
            target_element_id: 'navContainer',
            target_query_path: '.newUserStepFive',
            parentMessageLocation: '.newUserStepFiveParent',
            step_message: {
              title: "Team Switch",
              message: "With Swarm, you have the ability to easily switch between as many companies as you'd like. All linked to one single user account."
            },
            openUserMenu: true,
            highlightYellow: true,
            isTopLevel: false,
            isInformational: false,
            step_completed: false
          },
          {
            step_id: 5,
            title: "Register Company",
            elevateParentElement: 'header',
            target_element_id: 'navContainer',
            target_query_path: '.newUserStepSix',
            parentMessageLocation: '.newUserStepSixParent',
            step_message: {
              title: "Register Company",
              message: "If you'd like to register your own company or side gig to use with Swarm, simply click the add button and follow the registration steps.",
            },
            openUserMenu: true,
            highlightYellow: true,
            isTopLevel: false,
            isInformational: true,
            step_completed: false
          }

        ]
    };
   
    constructor() { }

    public getWalkthrough(): GuidedWalkthroughPage {
        return this.walkthrough;
    }

}
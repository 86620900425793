import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'; 

import { PagesComponent } from './base/pages.component';
import { ForeignViewPagesComponent } from './foreign-viewer/foreign-viewer-pages.component'
import { VendorPagesComponent } from './vendor/vendor-pages.component';
import { NotFoundComponent } from './base/not-found/not-found.component';
import { LockScreenComponent } from './base/lock-screen/lock-screen.component';

import { PlatformStates } from './services/vendor/platform.service'

export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponent, children: [
            { path: '', loadChildren: () => import('./base/swarm-home/hive_home.module').then(m => m.HiveHomeModule) },
            { path: 'home', loadChildren: () => import('./base/swarm-home/hive_home.module').then(m => m.HiveHomeModule) },
            { path: 'platform-features', loadChildren: () => import('./base/platform-features/platform-features.module').then(m => m.PlatformFeaturesModule) },
            { path: 'solutions', loadChildren: () => import('./base/platform/platform.module').then(m => m.PlatformModule) },
            { path: 'trades', loadChildren: () => import('./base/trades/trades.module').then(m => m.TradesModule) },
            { path: 'honey', loadChildren: () => import('./base/honey_home/honey_home.module').then(m => m.HoneyHomeModule) },
            { path: 'hive', loadChildren: () => import('./base/swarm-home/hive_home.module').then(m => m.HiveHomeModule) },
            { path: 'partner', loadChildren: () => import('./base/partner/partner.module').then(m => m.PartnerModule) },
            { path: 'about', loadChildren: () => import('./base/about/about.module').then(m => m.AboutModule) },
            { path: 'contact', loadChildren: () => import('./base/contact/contact.module').then(m => m.ContactModule) },
            { path: 'properties', loadChildren: () => import('./base/properties/properties.module').then(m => m.PropertiesModule) },
            { path: 'joblistings', loadChildren: () => import('./base/job_listings/job_listings.module').then(m => m.JobListingsModule) },
            { path: 'agents', loadChildren: () => import('./base/agents/agents.module').then(m => m.AgentsModule) },
            { path: 'compare', loadChildren: () => import('./base/compare/compare.module').then(m => m.CompareModule) },
            { path: 'pricing', loadChildren: () => import('./base/pricing/pricing.module').then(m => m.PricingModule) },
            { path: 'faq', loadChildren: () => import('./base/faq/faq.module').then(m => m.FaqModule) },

            { path: 'login', loadChildren: () => import('./base/login/login.module').then(m => m.LoginModule) },
            { path: 'register', loadChildren: () => import('./base/register/register.module').then(m => m.RegisterModule) },
            { path: 'terms-conditions', loadChildren: () => import('./base/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
            { path: 'esign-consent', loadChildren: () => import('./base/esign-consent/esign-consent.module').then(m => m.EsignConsentModule) },
            { path: 'account', loadChildren: () => import('./user/account/account.module').then(m => m.UserAccountModule) }, 
            
            { path: 'submit-property', loadChildren: () => import('./base/submit-property/submit-property.module').then(m => m.SubmitPropertyModule) },
        ]
    },
    { 
        path: 'twilio', 
        component: PagesComponent, children: [
            { path: 'voice', loadChildren: () => import('./base/twilio/twilio.module').then(m => m.TwilioModule) },
        ]
    },
    {
        path: 'hive',
        children: [
            {
                path: 'professional',
                component: VendorPagesComponent, children: [
                    //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
                    { path: 'dispatch-console', loadChildren: () => import('./vendor/control-center/control-center.module').then(m => m.ControlCenterModule), data: {id: 0, title: 'Dispatch Console', state: PlatformStates.PROFESSIONAL} },
                    { path: 'work-orders', loadChildren: () => import('./vendor/work-order-processing/work-order-processing.module').then(m => m.WorkOrderProcessingModule), data: {id: 1, title: 'Work Scheduling', state: PlatformStates.PROFESSIONAL} },
                    { path: 'admin-account', loadChildren: () => import('./vendor/admin-account/admin-account.module').then(m => m.VendorAdminAccountModule), data: {id: 2, title: 'Admin Settings', state: PlatformStates.PROFESSIONAL} },
                    { path: 'invoice', loadChildren: () => import('./vendor/invoice/invoice.module').then(m => m.InvoiceModule), data: {id: 3, title: 'Invoice', state: PlatformStates.PROFESSIONAL} },
                    { path: 'shift-scheduling', loadChildren: () => import('./vendor/shift-scheduling/shift-scheduling.module').then(m => m.ShiftSchedulingModule), data: {id: 4, title: 'Shift Scheduling', state: PlatformStates.PROFESSIONAL} },
                    { path: 'inventory', loadChildren: () => import('./vendor/cmms/cmms.module').then(m => m.CMMSModule), data: {id: 5, title: 'CMMS', viewMode: 0, state: PlatformStates.PROFESSIONAL} },
                    { path: 'field-operator', loadChildren: () => import('./vendor/field-operator/field-operator.module').then(m => m.FieldOperatorModule), data: {id: 6, title: 'Field Operator', state: PlatformStates.PROFESSIONAL} },
                    { path: 'profile-account', loadChildren: () => import('./vendor/account/account.module').then(m => m.VendorAccountModule), data: {id: 7, title: 'Settings', state: PlatformStates.PROFESSIONAL} },
                    { path: 'local-benchstock', loadChildren: () => import('./vendor/cmms/cmms.module').then(m => m.CMMSModule), data: {id: 8, title: 'Bench Stock', viewMode: 1, state: PlatformStates.PROFESSIONAL} },
                    { path: 'partner-program', loadChildren: () => import('./vendor/partner-program/partner-program.module').then(m => m.PartnerProgramModule), data: {id: 9, title: 'Partner Portal', state: PlatformStates.PROFESSIONAL} },
                    { path: 'crm', loadChildren: () => import('./vendor/crm/crm.module').then(m => m.CRMModule), data: {id: 10, title: 'Projects', state: PlatformStates.PROFESSIONAL} },
                    { path: 'forms', loadChildren: () => import('./vendor/forms/forms.module').then(m => m.FormsBuilderModule), data: {id: 11, title: 'Forms', state: PlatformStates.PROFESSIONAL} },
                    { path: 'registration', loadChildren: () => import('./vendor/registration/registration.module').then(m => m.RegistrationModule), data: {id: 12, title: 'Registration', state: PlatformStates.PROFESSIONAL} },

                    { path: 'lock-screen', component: LockScreenComponent, data: {id: 13, title: 'Forms', state: PlatformStates.PROFESSIONAL} },


                    { path: 'internaltools', loadChildren: () => import('./vendor/swarm-internal-tools/swarm-internal-tools.module').then(m => m.SwarmInternalToolsModule), data: {id: 100, title: 'Internal Tools', state: PlatformStates.PROFESSIONAL} },

                ]
            },
            {
                path: 'demo',
                component: VendorPagesComponent, children: [
                    //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
                    { path: 'dispatch-console', loadChildren: () => import('./vendor/control-center/control-center.module').then(m => m.ControlCenterModule), data: {id: 0, title: 'Dispatch Console', state: PlatformStates.DEMO} },
                    { path: 'work-orders', loadChildren: () => import('./vendor/work-order-processing/work-order-processing.module').then(m => m.WorkOrderProcessingModule), data: {id: 1, title: 'Work Scheduling', state: PlatformStates.DEMO} },
                    { path: 'admin-account', loadChildren: () => import('./vendor/admin-account/admin-account.module').then(m => m.VendorAdminAccountModule), data: {id: 2, title: 'Admin Settings', state: PlatformStates.DEMO} },
                    { path: 'invoice', loadChildren: () => import('./vendor/invoice/invoice.module').then(m => m.InvoiceModule), data: {id: 3, title: 'Invoice', state: PlatformStates.DEMO} },
                    { path: 'shift-scheduling', loadChildren: () => import('./vendor/shift-scheduling/shift-scheduling.module').then(m => m.ShiftSchedulingModule), data: {id: 4, title: 'Shift Scheduling', state: PlatformStates.DEMO} },
                    { path: 'inventory', loadChildren: () => import('./vendor/cmms/cmms.module').then(m => m.CMMSModule), data: {id: 5, title: 'CMMS', viewMode: 0, state: PlatformStates.DEMO} },
                    { path: 'field-operator', loadChildren: () => import('./vendor/field-operator/field-operator.module').then(m => m.FieldOperatorModule), data: {id: 6, title: 'Field Operator', state: PlatformStates.DEMO} },
                    { path: 'profile-account', loadChildren: () => import('./vendor/account/account.module').then(m => m.VendorAccountModule), data: {id: 7, title: 'Settings', state: PlatformStates.DEMO} },
                    { path: 'local-benchstock', loadChildren: () => import('./vendor/cmms/cmms.module').then(m => m.CMMSModule), data: {id: 8, title: 'Bench Stock', viewMode: 1, state: PlatformStates.DEMO} },
                    { path: 'partner-program', loadChildren: () => import('./vendor/partner-program/partner-program.module').then(m => m.PartnerProgramModule), data: {id: 9, title: 'Partner Portal', state: PlatformStates.DEMO} },
                    { path: 'crm', loadChildren: () => import('./vendor/crm/crm.module').then(m => m.CRMModule), data: {id: 10, title: 'Projects', state: PlatformStates.DEMO} },
                    { path: 'forms', loadChildren: () => import('./vendor/forms/forms.module').then(m => m.FormsBuilderModule), data: {id: 11, title: 'Forms', state: PlatformStates.DEMO} },
                    { path: 'registration', loadChildren: () => import('./vendor/registration/registration.module').then(m => m.RegistrationModule), data: {id: 12, title: 'Registration', state: PlatformStates.DEMO} },

                ]
            },
            { path: 'lock-screen', component: LockScreenComponent, data: {id: 12, title: 'Locked', state: PlatformStates.PROFESSIONAL} },
        ]
    },
// /foreignviewer/viewer/customer/job/test/
// 4bf38e54fc4b
// e70d2f148fee
    {
        path: 'foreignviewer',
        children: [
            {
                path: 'viewer',
                component: ForeignViewPagesComponent, children: [
                    //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
                    { path: 'customer', loadChildren: () => import('./foreign-viewer/customer/customer.module').then(m => m.CustomerModule), data: { id: 0, title: 'Customer' } }
                ]
            },
            {
                path: 'forms',
                component: ForeignViewPagesComponent, children: [
                    //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
                    { path: 'form', loadChildren: () => import('./foreign-viewer/customer/customer.module').then(m => m.CustomerModule), data: { id: 0, title: 'Forms' } },
                    { path: 'document', loadChildren: () => import('./foreign-viewer/document/document.module').then(m => m.DocumentModule), data: { id: 0, title: 'Document' } },
                    { path: 'workorder', loadChildren: () => import('./foreign-viewer/document/document.module').then(m => m.DocumentModule), data: { id: 0, title: 'Work Order' } }
                ]
            },
            {
                path: 'verification',
                component: ForeignViewPagesComponent, children: [
                    //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
                    { path: 'processing', loadChildren: () => import('./foreign-viewer/verification/verification.module').then(m => m.VerificationModule), data: { id: 0, title: 'Verification', altAppearance: true } },
                ]
            },
            {
                path: 'members',
                component: ForeignViewPagesComponent, children: [
                    //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
                    { path: 'fee', loadChildren: () => import('./foreign-viewer/verification/verification.module').then(m => m.VerificationModule), data: { id: 0, title: 'Verification', altAppearance: true } },
                ]
            },
            {
                path: 'twilio',
                component: ForeignViewPagesComponent, children: [
                    //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
                    { path: 'connect', loadChildren: () => import('./foreign-viewer/twilio/twilio-connect.module').then(m => m.TwilioConnectModule), data: { id: 0, title: 'Twilio Connect' } }
                ]
            },
            {
                path: 'account',
                component: ForeignViewPagesComponent, children: [
                    //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
                    { path: 'create', loadChildren: () => import('./foreign-viewer/create-linked-account/create-linked-account.module').then(m => m.CreateLinkedAccountModule), data: { id: 0, title: 'Account' } }
                ]
            }
        ]
    },

    {
        path: 'members',
        children: [
            {
                path: 'fee',
                component: ForeignViewPagesComponent, children: [
                    //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
                    { path: 'processing', loadChildren: () => import('./foreign-viewer/verification/verification.module').then(m => m.VerificationModule), data: { id: 0, title: 'Verification', altAppearance: true } },
                ]
            }
        ]
    },

    { path: 'landing', loadChildren: () => import('./base/landing/landing.module').then(m => m.LandingModule) },
    // { path: 'lock-screen', component: LockScreenComponent },
    { path: '**', component: NotFoundComponent }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)

        // RouterModule.forRoot(routes, {
        //     preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
        //     useHash: false
        // })
    ],
    
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { MongoService }  from '../mongo_support';
import { Router, NavigationEnd } from '@angular/router';

// Base 
import { CompanyProfile, Profile, Address, Query, ScheduleSwap, AssetWorkOrder, FacilityWorkOrder, WorkOrderCustomField, GroupPermissions, InventoryWorkOrder, CMMSLocationType, CMMSLocation, InvoiceSettings, PaymentSettings, Page, NotificationCategory, CMMSPartRequest, CMMSVendor, PageSection, GeneralDataSync, LinkedAccount, PagePermission, LinkedAccountRequest, WOInvoice, LinkedEmployeeRequest, Automation, AutomationCategory, TaskList, ApplicationNotifications, InfoPanelTrackedDetails, CompanyTimeChart, TwilioAccount, TextMessage, CallLog, Contact, RevInvoice, Revenue, CRMDeal, CRMPipe, CRMPipeline, CRMDealArchive, AutomationTag, FormDocument, CustomCalendarEvent, ColorTheme, AutocompleteServices, PlatformInvoice } from '../../data-models/models';

// Shift Scheduling
import { EmployeeSchedule, Shift, Group } from '../../data-models/models';

// Inventory
import { InventoryTrackedFields, InventoryItem } from '../../data-models/models';

// Inventory
import { AssetTrackedFields, AssetItem } from '../../data-models/models';

// Work Order Builder
import { WorkOrder, WorkOrderTemplate, WorkOrderRow } from '../../data-models/models';

// Geo
import { LatLng } from '../../data-models/models';


import { Time } from '@angular/common';
import { stringify } from '@angular/compiler/src/util';
import { he } from 'date-fns/locale';
import * as moment from 'moment';
import { async } from 'rxjs/internal/scheduler/async';
import { not, THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { group } from 'console';
import { HeartBeatService } from './heartbeat.service';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Portal } from '@angular/cdk/portal';
import { StyleUtils } from '@angular/flex-layout';
import { WorkOrderAuditService } from './work-order-audit.service';
import { AdditionalFeature } from 'src/app/app.models';
import { NgxMaterialTimepickerThemeDirective } from 'ngx-material-timepicker';
import { String } from 'aws-sdk/clients/apigateway';

export enum workOrderElementEnum {
  TEXT_FIELD = 0,
  HR = 1,
  TEXT_AREA = 2,
  QUILL_EDITOR = 3,
  IMG_IMPORT = 4,
  HEADER = 5,
  PARAGRAPH = 6,
  BLANK = 7,
  DROP_DOWN_MENU = 8,
  DATE_DROP_DOWN_MENU = 9,

  FOLLOW_ON_TABLE = 20,
  ATTACH_PART = 21,
  SERVICE_LIST = 22
}

export enum FormTypes {
  FORM = 0,
  WORK_ORDER = 1,
  INVOICE = 2
}

export enum keyTypesEnum {
  COMPANY_ID = 0,
  FORM_ID = 1,
  FIELD_ID = 2,
  PROFILE_ID = 3,
  WORK_ORDER_ID = 4,
  SHIFT_ID = 5,
  SHIFT_SWAP_REQUEST_ID = 6,
  MESSAGE_ID = 7,
  IMAGE_ID = 8,
  AVATAR_ID = 9,
  ASSET_ID = 10,
  INVENTORY_ID = 11,
  BARCODE_UPC_A = 12,
  BARCODE_UPC_E = 13,
  GROUP_ID = 14,
  CMMS_LOCATION_ID = 15,
  CMMS_RESERVATION_ID = 16,
  INVOICE_CUSTOM_CHARGE_FIELD = 17,
  NOTIFICATION_ID = 18,
  PURCHASE_ORDER_ID = 19,
  PURCHASE_ORDER_NEW_PART_ID = 20,
  LINKED_ACCOUNT_REQUEST_ID = 21,
  AUDIT_LOG_ID = 22,
  TASK_LIST = 23,
  AUTOMATION = 24,
  TIME_CHART = 25,
  CONTACT = 26,
  CRM_PIPELINE = 27,
  CRM_PIPE = 28,

  SIGNATURE_SECURITY_CODE = 29,
  WORK_ORDER_ACCESS_CODE = 30,
  COMPANY_THEME_ID = 31
}

export enum workOrderTypesEnum {
  ASSET = 0,
  INVENTORY = 1,
  FACILITY = 2,
  FIELD_TECH = 3,
  INVOICE = 4,
  FOREIGN = 5
}

export enum pageEnum {
  DISPATCH = 0,
  WORK_ORDER_SCHEDULING = 1,
  SHIFT_SCHEDULING = 2,
  CMMS = 3,
  INVOICE = 4,
  FIELD_OPERATOR = 5,
  ADMIN = 6,
  PROJECTS = 7,
  FORMS = 8,
  PAYROLL = 9
}

// ============================= CALL CENTER ===============================

export enum callStatusEnum {
  INCOMING_CONNECTED = 0,
  INCOMING_MISSED = 1,
  INCOMING_REJECTED = 2,
  OUTBOUND_CONNECTED = 3,
  OUTBOUND_MISSED = 4,
  OUTBOUND_REJECTED = 5
}

// =============================== NOTIFICATIONS ===============================

export enum notificationCategoriesEnum {
  DISPATCH = 0,
  WO_SCHEDULING = 1,
  BILLING = 2,
  SHIFT_SCHEDULING = 3,
  CMMS = 4,
  INVENTORY_MANAGEMENT = 5,
  ADMIN = 6,
  FIELD_OPERATOR = 7,
  MESSAGING = 8,
  FORMS = 9,
  PROJECTS = 10
}

export enum notificationStatusEnum {
  GOOD = 0,
  INFORMATIONAL = 1,
  WARNING = 2,
  BAD = 3,
  CRITICAL = 4
}

// =============================== AUTOMATIONS ===============================
export enum automationCategoriesEnum {
  WORK_ORDER = 0,
  INVOICE = 1,
  CMMS = 2,
  TASK_LIST = 3,
  Tag = 4
}

export enum automationActionCategoryEnum {
  CREATE = 0,
  NOTIFY = 1,
  CONFIRM = 2
}

export enum automationGeneralActionEnum {
  NOTIFY_INDIVIDUAL = 0,
  NOTIFY_TEAM = 1,
  CONFIRM_CUSTOMER = 2,
  CONFIRM_INDIVIDUAL = 3,
  CONFIRM_TEAM = 4
}

export enum automationWorkOrderActionEnum {
  CREATE_INVOICE = 0,
  CREATE_TASK = 1,
  CREATE_PART_REQUEST = 2
}

export enum automationInvoiceActionEnum {
  CREATE_TASK = 0
}

export enum automationCMMSActionEnum {
  CREATE_INVENTORY_ITEM_WORK_ORDER = 0,
  CREATE_ASSET_ITEM_WORK_ORDER = 1,
  CREATE_FACILITY_WORK_ORDER = 2,
  CREATE_TASK = 3,
  CREATE_PART_REQUEST = 4
}

export enum automationWorkOrderTriggerEnum {
  WORK_ORDER_CREATED = 0,
  WORK_ORDER_STATUS_CHANGED = 1,
  WORK_ORDER_STATUS_EQUALS = 2,
  WORK_ORDER_DATE_LIMIT = 3,
}

export enum automationInvoiceTriggerEnum {
  WORK_ORDER_CREATED = 0,
  WORK_ORDER_STATUS_CHANGED = 1,
  WORK_ORDER_STATUS_EQUALS = 2,
  WORK_ORDER_DATE_LIMIT = 3
}

export enum automationCMMSTriggerEnum {
  INVENTORY_ITEM_CREATED = 0,
  INVENTORY_ITEM_RESTOCKED = 1,
  INVENTORY_RESTOCK = 2,
  INVENTORY_ITEM_WORK_ORDER_CREATED = 3,
  INVENTORY_ITEM_WORK_ORDER_STATUS_CHANGED = 4,
  INVENTORY_ITEM_WORK_ORDER_STATUS_EQUALS = 5,
  INVENTORY_ITEM_WORK_ORDER_DATE_LIMIT = 6,

  ASSET_CREATED = 7,
  ASSET_RESTOCKED = 8,
  ASSET_RESTOCK = 9,
  ASSET_WORK_ORDER_CREATED = 10,
  ASSET_WORK_ORDER_STATUS_CHANGED = 11,
  ASSET_WORK_ORDER_STATUS_EQUALS = 12,
  ASSET_WORK_ORDER_DATE_LIMIT = 13
}

export enum automationActionRequirementEnum {
  GROUP = 0,
  TASK_LIST = 1,
  EMPLOYEE = 2
}

export enum automationTriggerRequirementEnum {
  DAYS = 0,
  WORK_ORDER_STATUS = 1
}
// ======================================================================


export enum shiftSwapStatusRequestEnum {
  REVIEW = 0,
  APPROVED = 1,
  DENIED = 2,
}

export enum workOrderAuditTypes {
  CREATED = 0,
  CONFIRMED = 1,
  CUSTOMER_CONFIRMED = 2,
  SCHEDULED = 3,
  RESCHEDULED = 4,
  ASSIGNED = 5,
  REASSIGNED = 6, 

  WORK_STATUS_CHANGE = 7,

  CANCELED = 8,
  COMPLETED = 9,
  CLOSED = 10
}

export enum workOrderStatusTypes {
  STANDBY = 0,
  HOLD = 1,
  ENROUTE = 2,
  STARTED = 3,
  WORKING = 4,
  COMPLETED = 5,
  CLOSED = 6,
  CANCELED = 7,
  SCHEDULED = 8
}

@Injectable({
  providedIn: 'root'
})


export class NavigatorService {

  public url = "assets/data/vendor/";
  public apiKey = 'AIzaSyA1rF9bttCxRmsNdZYjW7FzIoyrul5jb-s';
  private geoApiKey = "AIzaSyD50gI2gregFXPOn_uVHBQRT97_MS1s0Vg";
  private weatherApiKey = "8641f6e1f17359651c06de0eec5e63fa";

  // private newTimeShiftSubject = new Subject<TimeShiftTemplateSubject>();
  // private timeKeeperSubject = new Subject<TimeKeeper[]>();
  
  private assetSubject = new Subject<AssetItem[]>();
  private assetSelectSubject = new Subject<AssetItem[]>();
  private assetDistictSubject = new Subject<AssetItem[]>();
  private assetMeteredSubject = new Subject<AssetItem[]>();

  private cmmsLocationSubject = new Subject<CMMSLocation[]>();

  private inventorySubject = new Subject<InventoryItem[]>();
  private inventoryDistictSubject = new Subject<InventoryItem[]>();
  private inventorySelectSubject = new Subject<InventoryItem[]>();
  private inventoryMeteredSubject = new Subject<InventoryItem[]>();

  private shiftSubject = new Subject<Shift[]>();
  private employeeShiftSheduleSubject = new Subject<EmployeeSchedule>();
  private allShiftSheduleSubject = new Subject<EmployeeSchedule[]>();
  private workOrderTemplateSubject = new Subject<WorkOrderTemplate>();
  private shiftSwapRequestsSubject = new Subject<ScheduleSwap[]>();

  private groupPermissionSubject = new Subject<GroupPermissions[]>();
  private serviceAreaSubject = new Subject<LatLng[]>();
  private addressToLatLngSubject = new Subject<LatLng>();
  // private groupsSubject = new Subject<GroupCategories[]>();
  // private newGroupSubject = new Subject<GroupCategories>();
  private customWorkOrderBuilderIsFullScreenSubject = new Subject<boolean>();
  private companyProfileSubject = new Subject<CompanyProfile>();
  private companyLogoSubject = new Subject<any>();
  private profileSubject = new Subject<Profile>();
  private profilesSubject = new Subject<Profile[]>();
  private profileIdSubject = new Subject<string>();
  private weatherSubject = new Subject<any>();


  private activePageTitle: string = "HIVE";
  public startPageUrl: string = "/hive/professional/crm";
  public newlyRegisteredUserRoute: string = '/hive/professional/registration'

  // private groups: Array<GroupCategories>;
  // private shiftTemplates: Array<TimeShiftTemplate> = [];
  // private timeKeeper: Array<TimeKeeper> = [];
  private inventoryTrackedFields: Array<InventoryTrackedFields> = [];
  private inventory: Array<InventoryItem> = [];
  private assets: Array<AssetItem> = [];
  private filteredFacilityWorkOrderSubject = new Subject<FacilityWorkOrder[]>();
  private facilityWorkOrderSubject = new Subject<FacilityWorkOrder[]>();
  private assetWorkOrderSubject = new Subject<AssetWorkOrder[]>();
  private inventoryWorkOrderSubject = new Subject<AssetWorkOrder[]>();
  private filteredAssetWorkOrderSubject = new Subject<AssetWorkOrder[]>();
  private filteredInventoryWorkOrderSubject = new Subject<InventoryWorkOrder[]>();
  private workOrderCustomFieldsSubject = new Subject<WorkOrderCustomField[]>();
  
  private filteredPagedOpenWorkOrderSubject = new Subject<WorkOrder[]>();
  private filteredPagedClosedWorkOrderSubject = new Subject<WorkOrder[]>();
  private filteredPagedOpenPartRequestsSubject = new Subject<WorkOrder[]>();
  private filteredPagedClosedPartRequestsSubject = new Subject<WorkOrder[]>();

  private filteredPagedWorkOrderSubject = new Subject<WorkOrder[]>();
  
  private workOrderSubject = new Subject<WorkOrder[]>();
  private weekWorkOrderSubject = new Subject<WorkOrder[]>();
  private monthWorkOrderSubject = new Subject<WorkOrder[]>();
  private monthClosedWorkOrderSubject = new Subject<WorkOrder[]>();
  private rangeClosedWorkOrderSubject = new Subject<WorkOrder[]>();
  private rangeOpenWorkOrderSubject = new Subject<WorkOrder[]>();
  private rangeWorkOrderSubject = new Subject<WorkOrder[]>();
  private workOrderTemplate: WorkOrderTemplate;
  private workOrderElements: any = workOrderElementEnum; 

  private pageTypes: any = pageEnum;
  private keyTypes: any = keyTypesEnum;
  private notificationCategories: any = notificationCategoriesEnum;
  public formTypes: any = FormTypes;

  // ============== AUTOMATIONS ==============
  private automationCategories: any = automationCategoriesEnum;
  private automationActionTypes: any = automationActionCategoryEnum;

  private automationGeneralActions: any = automationGeneralActionEnum;
  private automationWorkOrderActions: any = automationWorkOrderActionEnum;
  private automationInvoiceActions: any = automationInvoiceActionEnum;
  private automationCMMSActions: any = automationCMMSActionEnum;

  private automationActionRequirement: any = automationActionRequirementEnum; 
  private automationTriggerRequirement: any = automationTriggerRequirementEnum; 
  
  private automationWorkOrderTriggers: any = automationWorkOrderTriggerEnum;
  private automationInvoiceTriggers: any = automationInvoiceTriggerEnum;
  private automationCMMSTriggers: any = automationCMMSTriggerEnum;

  private workOrderCreatedTrigger = new Subject<number>();
  private workOrderStatusChangedTrigger = new Subject<number>();

  private inventoryItemWorkOrderCreatedTrigger = new Subject<number>();
  private inventoryItemWorkOrderStatusChangedTrigger = new Subject<number>();

  private assetWorkOrderCreatedTrigger = new Subject<number>();
  private assetWorkOrderStatusChangedTrigger = new Subject<number>();

  private workOrderStatusEqualsTrigger = new Subject<number>();
  private workOrderDateLimitTrigger = new Subject<number>();


  //=========================================

  private jobAuditTypes: any = workOrderAuditTypes;
  private jobStatusTypes: any = workOrderStatusTypes;
  private shiftSwapStatusRequest: any = shiftSwapStatusRequestEnum;
  private companyIDKeys: Array<string> = [];
  private companyProfileIDKeys: Array<string> = [];
  private companyFormIDKeys: Array<string> = [];
  private workOrderFieldKeys: Array<any> = [];

  private user: any;
  private profile: Profile;
  private userPagePermissions: Array<Page> = [];
  private platformName: string = "Swarm Operative";
  private platformId: string = "25733455785f0ff4c796f5dd8883000b3813";
  private companyName: string = "";
  private companyId: string;
  private companyLogo: string;
  private linkedAccounts: Array<LinkedAccount>;
  private profileId: string = "";
  private callCenterId: string = "";
  private email: string = "";
  private password: string = "";

  // Notification Page Categories
  private notificationPageCategories: Array<NotificationCategory> = [
    {
      id: this.notificationCategories.DISPATCH,
      title: "Dispatching"
    },
    {
      id: this.notificationCategories.WO_SCHEDULING,
      title: "W/O Scheduling"
    },
    {
      id: this.notificationCategories.BILLING,
      title: "Billing"
    },
    {
      id: this.notificationCategories.SHIFT_SCHEDULING,
      title: "Shift Scheduling"
    },
    {
      id: this.notificationCategories.CMMS,
      title: "CMMS"
    },
    {
      id: this.notificationCategories.INVENTORY_MANAGEMENT,
      title: "Inventory Management"
    },
    {
      id: this.notificationCategories.ADMIN,
      title: "Admin"
    },
    {
      id: this.notificationCategories.FIELD_OPERATOR,
      title: "Field Operator"
    },
    {
      id: this.notificationCategories.MESSAGING,
      title: "Messaging"
    },
    {
      id: this.notificationCategories.PROJECTS,
      title: "Projects"
    },
    {
      id: this.notificationCategories.FORMS,
      title: "Forms"
    }
  ];

  // Notification Page Categories
  private automationActions: Array<AutomationCategory> = [
    {
      category: this.automationCategories.WORK_ORDER,
      title: "Work orders",

      actionTypes: [

        {
          id: this.automationActionTypes.CREATE,
          title: "Create",
          actions: [
            {
              id: this.automationWorkOrderActions.CREATE_INVOICE,
              title: "Invoice"
            },
            {
              id: this.automationWorkOrderActions.CREATE_TASK,
              title: "Task / Task List",
              additional_req: this.automationActionRequirement.TASK_LIST
            },
            {
              id: this.automationWorkOrderActions.CREATE_PART_REQUEST,
              title: "Part Request"
            }
          ]
        },

        {
          id: this.automationActionTypes.NOTIFY,
          title: "Notify",
          actions: [
            {
              id: this.automationGeneralActions.NOTIFY_INDIVIDUAL,
              title: "Individual",
              additional_req: this.automationActionRequirement.EMPLOYEE
            },
            {
              id: this.automationGeneralActions.NOTIFY_TEAM,
              title: "Team",
              additional_req: this.automationActionRequirement.GROUP
            }
          ]
        },

        {
          id: this.automationActionTypes.CONFIRM,
          title: "Confirm With",
          actions: [
            {
              id: this.automationGeneralActions.CONFIRM_CUSTOMER,
              title: "Customer"
            },
            {
              id: this.automationGeneralActions.CONFIRM_INDIVIDUAL,
              title: "Individual",
              additional_req: this.automationActionRequirement.EMPLOYEE
            },
            {
              id: this.automationGeneralActions.CONFIRM_TEAM,
              title: "Team",
              additional_req: this.automationActionRequirement.GROUP
            }
          ]
        }

      ],

      triggers: [
        {
          id: this.automationWorkOrderTriggers.WORK_ORDER_CREATED,
          title: "Work Order Created"
        },

        {
          id: this.automationWorkOrderTriggers.WORK_ORDER_STATUS_CHANGED,
          title: "Work Order Status Changed"
        },
        {
          id: this.automationWorkOrderTriggers.WORK_ORDER_STATUS_EQUALS,
          title: "Work Order Status is",
          additional_req: this.automationTriggerRequirement.WORK_ORDER_STATUS
        },
        {
          id: this.automationWorkOrderTriggers.WORK_ORDER_DATE_LIMIT,
          title: "Work Order Days Passed and No Change",
          additional_req: this.automationTriggerRequirement.DAYS
        },
      ]

    },

    {
      category: this.automationCategories.INVOICE,
      title: "Invoice",

      actionTypes: [

        {
          id: this.automationActionTypes.CREATE,
          title: "Create",
          actions: [
            {
              id: this.automationInvoiceActions.CREATE_TASK,
              title: "Task / Task List",
              additional_req: this.automationActionRequirement.TASK_LIST
            }
          ]
        },

        {
          id: this.automationActionTypes.NOTIFY,
          title: "Notify",
          actions: [
            {
              id: this.automationGeneralActions.NOTIFY_INDIVIDUAL,
              title: "Individual",
              additional_req: this.automationActionRequirement.EMPLOYEE
            },
            {
              id: this.automationGeneralActions.NOTIFY_TEAM,
              title: "Team",
              additional_req: this.automationActionRequirement.GROUP
            }
          ]
        }

      ],

      triggers: [
        {
          id: this.automationWorkOrderTriggers.WORK_ORDER_CREATED,
          title: "Work Order Created"
        },

        {
          id: this.automationWorkOrderTriggers.WORK_ORDER_STATUS_CHANGED,
          title: "Work Order Status Changed"
        },
        {
          id: this.automationWorkOrderTriggers.WORK_ORDER_STATUS_EQUALS,
          title: "Work Order Status is",
          additional_req: this.automationTriggerRequirement.WORK_ORDER_STATUS
        },
        {
          id: this.automationWorkOrderTriggers.WORK_ORDER_DATE_LIMIT,
          title: "Work Order Days Passed and No Change",
          additional_req: this.automationTriggerRequirement.DAYS
        },
      ]

    }

  ];

  // Page Permissions
  // NOTE: DO NOT change page or section id values. These are currently static and hard coded throughout the platform
  private pagePermissionsTemplate: Array<Page> = [
    {
      id: this.pageTypes.ADMIN,
      page: "Admin",
      permission: 
        {
          pageAccess: false,
          sections: [ ]
      }
      
    },

    {
      id: this.pageTypes.PROJECTS,
      page: "projects",
      permission: 
        {
          pageAccess: false,
          sections: [
            {
              id: "j39g1ip",
              title: "Projects",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            }
          ]
      }
      
    },

    {
      id: this.pageTypes.FORMS,
      page: "forms",
      permission: 
        {
          pageAccess: false,
          sections: [
            {
              id: "u56fqbc",
              title: "Forms",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            }
          ]
      }
      
    },

    {
      id: this.pageTypes.PAYROLL,
      page: "payroll",
      permission: 
        {
          pageAccess: false,
          sections: [
            {
              id: "gcv7d32",
              title: "Payroll",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            }
          ]
      }
      
    },

    {
      id: this.pageTypes.DISPATCH,
      page: "dispatch",
      permission: 
        {
          pageAccess: false,
          sections: [
            {
              id: "8177hbv",
              title: "Work Orders",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            }
          ]
      }
      
    },

    {
      id: this.pageTypes.WORK_ORDER_SCHEDULING,
      page: "work order scheduling",
      permission: 
        {
          pageAccess: false,
          sections: [
            {
              id: "a987ayf",
              title: "Work Orders",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "l9823kl",
              title: "Efficiency Score",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            }
          ]
      }
      
    },

    {
      id: this.pageTypes.SHIFT_SCHEDULING,
      page: "Shift Scheduling",
      permission: 
        {
          pageAccess: false,
          sections: [
            {
              id: "ad91k35",
              title: "Shift Templates",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "32k912j",
              title: "Schedule Shifts",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "jl29jn2",
              title: "Shift Swap Request",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "2l3n91j",
              title: "Shift Swap Review",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "ls92i46",
              title: "PTO Request",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "09j281h",
              title: "PTO Review",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            }
          ]
      }
      
    },

    {
      id: this.pageTypes.CMMS,
      page: "CMMS",
      permission: 
        {
          pageAccess: false,
          sections: [
            {
              id: "knls293",
              title: "inventory",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "gg83ja0",
              title: "non-tracked inventory",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "1j93lmd",
              title: "inventory work orders",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "0lso3nf",
              title: "assets",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "nkd9245",
              title: "asset work orders",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "ndmdop8",
              title: "facility",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "md871b3",
              title: "facility work orders",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "nd876j3",
              title: "files",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "ldpke78",
              title: "purchase orders",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "mki83hh",
              title: "reports",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "lkj123r",
              title: "Request Management",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            }
          ]
      }
      
    },

    {
      id: this.pageTypes.INVOICE,
      page: "invoice",
      permission: 
        {
          pageAccess: false,
          sections: [
            {
              id: "vbyt783",
              title: "google integration",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "zxwe5gg",
              title: "invoices",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            }
          ]
      }
      
    },

    {
      id: this.pageTypes.FIELD_OPERATOR,
      page: "field operator",
      permission: 
        {
          pageAccess: false,
          sections: [
            {
              id: "qwsd678",
              title: "work orders",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "pye8712",
              title: "follow-on work orders",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            },
            {
              id: "bbny654",
              title: "employee overwatch",
              permissions: {
                create: false,
                read: false,
                update: false,
                delete: false
              }
            }
          ]
      }
      
    }

  ]

  // Map Styles
  // https://mapstyle.withgoogle.com/
  private mapStyles: any = {

    standard: [],

    standardAlt: [
      {
          "featureType": "poi.attraction",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.attraction",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.attraction",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "poi.business",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.business",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.government",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.medical",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.park",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.place_of_worship",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.place_of_worship",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.school",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.school",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.sports_complex",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.sports_complex",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      }
    ],
  
    coloredDarkMap: [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#212121"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#212121"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#181818"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#1b1b1b"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#2c2c2c"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#8a8a8a"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#373737"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#3c3c3c"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#e5a22e"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#4e4e4e"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#e76f13"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#4b6a8b"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#3d3d3d"
          }
        ]
      }
    ],
  
    darkMap: [
      { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ],
  
    retro: [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ebe3cd"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#523735"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f1e6"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#c9b2a6"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#dcd2be"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#ae9e90"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dfd2ae"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dfd2ae"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#93817c"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#a5b076"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#447530"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f1e6"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#fdfcf8"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f8c967"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#e9bc62"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e98d58"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#db8555"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#806b63"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dfd2ae"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#8f7d77"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#ebe3cd"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dfd2ae"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#b9d3c2"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#92998d"
          }
        ]
      }
    ]
  
  }


  // Drop Down Menu Options
  // keyLookup.push({ title: "Priority", data: "priority" });
  // keyLookup.push({ title: "Skill Level", data: "skillLvl" });
  // keyLookup.push({ title: "Status", data: "status" });
  // keyLookup.push({ title: "Completed", data: "isCompleted" });
  // keyLookup.push({ title: "Paid", data: "isPaid" });

  private CMMSLocationTypes: Array<CMMSLocationType> = [ 
    {
      title: "Facility",
      type: 0
    },
    {
      title: "Vehicle",
      type: 1
    }
  ];

  private priorityOptions: Array<any> = [ 
    { 
      title: "Low",
      id: 0,
      key: "0"
    },
    { 
      title: "Medium",
      id: 1,
      key: "1"
    },
    { 
      title: "High",
      id: 2,
      key: "2"
    },
    { 
      title: "Scheduled",
      id: 3,
      key: "3"
    },
    { 
      title: "Safety",
      id: 4,
      key: "4"
    },
    { 
      title: "Emergency",
      id: 5,
      key: "5"
    },
  ];

  private skillLvlOptions: Array<any> = [ 
    { 
      title: "Level 1",
      id: 0,
      key: "lvl1"
    },
    { 
      title: "Level 2",
      id: 1,
      key: "lvl2"
    },
    { 
      title: "Level 3",
      id: 2,
      key: "lvl3"
    },
    { 
      title: "Level 4",
      id: 3,
      key: "lvl4"
    },
    { 
      title: "Level 5",
      id: 4,
      key: "lvl5"
    }
  ];

  private statusOptions: Array<any> = [ 
    { 
      title: "Scheduled",
      id: this.jobStatusTypes.SCHEDULED,
      key: this.jobStatusTypes.SCHEDULED
    },
    { 
      title: "Standby",
      id: this.jobStatusTypes.STANDBY,
      key: this.jobStatusTypes.STANDBY
    },
    { 
      title: "Enroute",
      id: this.jobStatusTypes.ENROUTE,
      key: this.jobStatusTypes.ENROUTE
    },
    { 
      title: "Started",
      id: this.jobStatusTypes.STARTED,
      key: this.jobStatusTypes.STARTED
    },
    { 
      title: "Working",
      id: this.jobStatusTypes.WORKING,
      key: this.jobStatusTypes.WORKING
    },
    { 
      title: "Completed",
      id: this.jobStatusTypes.COMPLETED,
      key: this.jobStatusTypes.COMPLETED
    },
    { 
      title: "Closed",
      id: this.jobStatusTypes.CLOSED,
      key: this.jobStatusTypes.CLOSED
    },
    { 
      title: "Canceled",
      id: this.jobStatusTypes.CANCELED,
      key: this.jobStatusTypes.CANCELED
    },
    { 
      title: "Hold",
      id: this.jobStatusTypes.HOLD,
      key: this.jobStatusTypes.HOLD
    }
  ];

  private completedOptions: Array<any> = [ 
    { 
      title: "Not Complete",
      id: 0,
      key: false
    },
    { 
      title: "Complete",
      id: 1,
      key: true
    }
  ];

  private paidOptions: Array<any> = [ 
    { 
      title: "Not Paid",
      id: 0,
      key: false
    },
    { 
      title: "Paid",
      id: 1,
      key: true
    }
  ];

  

  constructor(
    private http:HttpClient, 
    private realm:MongoService, 
    private router:Router, 
    private snackBar: MatSnackBar) { 

  }

  public getCustomWorkOrderBuilderIsFullScreen(): Observable<boolean> {
    return this.customWorkOrderBuilderIsFullScreenSubject.asObservable();
  }

  public setCustomWorkOrderBuilderIsFullScreen(isFullScreen: boolean): void {
    this.customWorkOrderBuilderIsFullScreenSubject.next(isFullScreen);
  }

  public getFooterTitle(): Observable<string> {
    // return this.http.get<string>(this.activePageTitle);
    return of(this.activePageTitle);
  }

  public setFooterTitle(pageTitle: string): void {
    this.activePageTitle = pageTitle;
  }

  public paginator(items, page?, perPage?) { 
    var page = page || 1,
    perPage = perPage || 4,
    offset = (page - 1) * perPage,   
    paginatedItems = items.slice(offset).slice(0, perPage),
    totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination:{
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
      }
    };
  }

  public getPages(): pageEnum {
    return this.pageTypes;
  }

  public getKeyTypes(): keyTypesEnum {
    return this.keyTypes;
  }

  public getJobAuditTypes(): workOrderAuditTypes {
    return this.jobAuditTypes;
  }

  public getJobStatusTypes(): workOrderStatusTypes {
    return this.jobStatusTypes;
  }

  public getShiftSwapRequestStatuses(): shiftSwapStatusRequestEnum {
    return this.shiftSwapStatusRequest;
  }

  public getMapStyles(): any {
    return this.mapStyles;
  }

  public sendMessage(message: any): any {
    let query: any = { };
  
    this.realm.mongoBasicInsertOne(query, "contact", "emails", message, false);
  }

  public newsletterSubscribe(email: any): any {
    let query: any = { "data.email": email };
  
    this.realm.mongoBasicInsertOne(query, "contact", "subscriber", email, false);
  }

  public translateOption(id: number, options: Array<any>): string {
    if(options.length == 0 || options[0].title == undefined) {
      return null;
    }

    let translate: string = "";
    let optionIndex: number = options.findIndex( (option: any) => { return id == option.id; });

    if(optionIndex > -1) {
      translate = options[optionIndex].title;
    }

    return translate;
  }

// ===========================================
// MENU
// ===========================================

  public getPriorityOptions(): Array<any> {
    return this.priorityOptions;
  }

  public getSkillLvlOptions(): Array<any> {
    return this.skillLvlOptions;
  }

  public getStatusOptions(): Array<any> {
    return this.statusOptions;
  }

  public getWorkCompletedOptions(): Array<any> {
    return this.completedOptions;
  }

  public getPaidOptions(): Array<any> {
    return this.paidOptions;
  }

  public getNotificationPageCategories(): Array<NotificationCategory> {
    return this.notificationPageCategories;
  }

// ===========================================
// CALL CENTER - Account
// ===========================================

public async getCallCenterAccountInfo(): Promise<TwilioAccount | null> {

  let accountInfo: TwilioAccount | null = null;

  let query: any = {
    "data.swarmCompanyId": this.getCompanyId(),
  };

  await this.realm.mongoGetOne(query, "hive-call-center", "account-info", "admin", "Swarm_Admin").then(info => {
      console.log("Successfully pulled Account Info", info);

      if(info != undefined && info != null) {
        
        accountInfo = info.data;

      }
    }
  );

  console.log("Account Info Results: ", accountInfo);

  return accountInfo;
}

// ===========================================
// CALL CENTER - General
// ===========================================

public async getContacts(): Promise<Array<Contact>> {
  let contacts: Array<Contact> = [];
  
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "hive-call-center", "contacts", "admin", this.getCompanyId()).then(contactLog => {
      console.log("Successfully pulled Contacts", contactLog);

      if(contactLog != undefined && contactLog != null) {
  
        for(let contact of contactLog) {
          contacts.push(contact.data);
        }

      }
    }
  );

  return contacts;
}

public async upsertContact(contact: Contact): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.id": contact.id
  };

  await this.realm.mongoUpsertOne(query, "hive-call-center", "contacts", contact, "admin", this.getCompanyId(), false).then( async (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}

public async getCallLog(): Promise<Array<CallLog>> {
  let logs: Array<CallLog> = [];

  let startDate: Date = new Date();
  let endDate: Date = new Date();

  startDate.setMonth(startDate.getMonth() - 1);

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);
  
  let query: any = {
    company_id: this.getCallCenterId(),
    role: "admin",
    $and: [
      {"data.startTime": { $gte: startDate } },
      {"data.startTime": { $lte: endDate } }
    ]
  };

  await this.realm.mongoGet(query, "hive-call-center", "phone-log", "admin", this.getCallCenterId()).then(callLogs => {
      console.log("Successfully pulled Call Logs", callLogs);

      if(callLogs != undefined && callLogs != null) {
  
        for(let log of callLogs) {
          logs.push(log.data);
        }

      }
    }
  );

  return logs;
}

public async insertCallLog(log: CallLog): Promise<boolean> {
  let status: boolean = false;

  await this.realm.mongoInsertOne("hive-call-center", "phone-log", log, "admin", this.getCallCenterId()).then( async (completionStatus: boolean) => {
    completionStatus;

    if(completionStatus) {  

    }

  });

  return status;
}


// ===========================================
// Call Center - Text Messaging
// ===========================================

public async getAsyncCallCenterID(): Promise<string> {

  let id: string = "";

  let query: any = {
    "data.swarmCompanyId": this.getCompanyId()
  };

  await this.realm.mongoGetOne(query, "hive-call-center", "account-info", "admin", this.getCompanyId()).then(callCenterAccount => {
      console.log("Successfully pulled Call Center Account", callCenterAccount);

      if(callCenterAccount != undefined && callCenterAccount != null) {
  
        id = callCenterAccount.data.accountSid;

        this.callCenterId = id;

      }
    }
  );

  return id;
}

public async getAsyncTextMessages(phoneNumber: string): Promise<Array<TextMessage>> {
  if(phoneNumber == undefined || phoneNumber == null || phoneNumber.length != 10) {
    return;
  }

  let textMessages: any = [];
  let pageNumber: number = 0;
  let limit: number = 50;

  let sortQuery: string = "data.dateSent"
  let matchQuery: any = {

    $match: {
      "data.accountSid": this.getCallCenterId(),
  
      $or: [
        {"data.from": "+1" + phoneNumber},
        {"data.to": "+1" + phoneNumber}
      ]
    }

  };
  
  console.log("Search Query: ", matchQuery);    

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-call-center", "text-messaging", "admin", this.getCompanyId(), pageNumber, limit).then(messages => {

    console.log("Successfully pulled text messages", messages);

    if(messages != undefined && messages != null) {
      
      for(let message of messages) {
        textMessages.push(message.data);
      }

    }

  });

  console.log("Text Message Results: ", textMessages);

  return textMessages;
}

public async insertTextMessage(message: TextMessage): Promise<boolean> {
  let status: boolean = false;

  await this.realm.mongoInsertOne("hive-call-center", "text-messaging", message, "admin", this.getCompanyId()).then( async (completionStatus: boolean) => {
    completionStatus;

    if(completionStatus) {  

      await this.getAsyncGeneralDataSync().then( async (dataSync: GeneralDataSync | null) => {

        if(dataSync != undefined && dataSync != null) {

          dataSync.categories.text_message = new Date();
          this.updateGeneralDataSync(dataSync);

        }

      });

    }

  });

  return status;
}

// ===========================================
// REVENUE
// ===========================================

public async getAsyncRevenueInvoice(id: string): Promise<Revenue | null> {
  let revenue: Revenue = null;
  
  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": id,
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-financial-ledger", "invoices", "admin", this.getCompanyId()).then(invoice => {
      console.log("Successfully pulled Invoices", invoice);

      if(invoice != undefined && invoice != null) {
  
        revenue = invoice.data;

      }
    }
  );

  return revenue;
}

public async upsertRevenueInvoice(revenueInvoice: Revenue): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": revenueInvoice.id,
    role: "admin"
  };

  await this.realm.mongoUpsertOne(query, "hive-financial-ledger", "invoices", revenueInvoice, "admin", this.getCompanyId(), false).then( async (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}

// ===========================================
// Platform Invoice
// ===========================================

public async getPlatformInvoice(id: string): Promise<PlatformInvoice | null> {
  let revenue: PlatformInvoice = null;
  
  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": id,
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-financial-ledger", "platform-invoices", "admin", this.getCompanyId()).then(invoice => {
      console.log("Successfully pulled Platform Invoice", invoice);

      if(invoice != undefined && invoice != null) {
  
        revenue = invoice.data;

      }
    }
  );

  return revenue;
}

public async getLatestPlatformMembershipInvoice(): Promise<PlatformInvoice | null> {
  let revenue: PlatformInvoice = null;
  let sortQuery: string = "data.paid";
  let pageNumber: number = 0;
  let limit: number = 100;
  
  let startDate: Date = new Date();
  let endDate: Date = new Date();

  // Setting Start of the Year
  startDate.setMonth(0);
  startDate.setDate(0);

  // Setting End of the Year
  endDate.setMonth(11);
  endDate.setDate(31);

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let matchQuery = 
  {
    $match: {
      $and: [
        { company_id: this.getCompanyId() },
        { role: "admin" },
        { "data.created_date": { $gte: startDate } },
        { "data.created_date": { $lte: endDate } }
      ],
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-financial-ledger", "platform-invoices", "admin", this.getCompanyId(), pageNumber, limit).then(invoice => {
      console.log("Successfully pulled Platform Membership Invoice", invoice);

      if(invoice != undefined && invoice != null && invoice.length > 0) {
  
        revenue = invoice[0].data;

      }

    }

  );

  return revenue;
}

public async getUnpaidPlatformInvoices(): Promise<Array<PlatformInvoice>> {
  let invoices: Array<PlatformInvoice> = [];
  let sortQuery: string = "data.paid";
  let pageNumber: number = 0;
  let limit: number = 100;

  let matchQuery = 
  {
    $match: {
      $and: [
        { company_id: this.getCompanyId() },
        { role: "admin" },
        { "data.paid": null }
      ],
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-financial-ledger", "platform-invoices", "admin", this.getCompanyId(), pageNumber, limit).then(platformInvoices => {
      console.log("Successfully pulled Unpaid Platform Membership Invoices", platformInvoices);

      if(platformInvoices != undefined && platformInvoices != null) {
  
        for(let platformInvoice of platformInvoices) {
          invoices = platformInvoice.data;
        }

      }
    }
  );

  return invoices;
}

public async getAllPlatformInvoices(): Promise<Array<PlatformInvoice>> {
  let invoices: Array<PlatformInvoice> = [];
  let sortQuery: string = "data.paid";
  let pageNumber: number = 0;
  let limit: number = 100;

  let matchQuery = 
  {
    $match: {
      $and: [
        { company_id: this.getCompanyId() },
        { role: "admin" }
      ],
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-financial-ledger", "platform-invoices", "admin", this.getCompanyId(), pageNumber, limit).then(platformInvoices => {
      console.log("Successfully pulled Unpaid Platform Membership Invoices", platformInvoices);

      if(platformInvoices != undefined && platformInvoices != null) {
  
        for(let platformInvoice of platformInvoices) {
          invoices = platformInvoice.data;
        }

      }
    }
  );

  return invoices;
}

public async getDateRangedPlatformInvoices(startDate: Date, endDate: Date): Promise<Array<PlatformInvoice>> {
  let invoices: Array<PlatformInvoice> = [];
  let sortQuery: string = "data.paid";
  let pageNumber: number = 0;
  let limit: number = 100;

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let matchQuery = 
  {
    $match: {
      $and: [
        { company_id: this.getCompanyId() },
        { role: "admin" },
        { "data.created_date": { $gte: startDate } },
        { "data.created_date": { $lte: endDate } }
      ],
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-financial-ledger", "platform-invoices", "admin", this.getCompanyId(), pageNumber, limit).then(platformInvoices => {
      console.log("Successfully pulled Unpaid Platform Membership Invoices", platformInvoices);

      if(platformInvoices != undefined && platformInvoices != null) {
  
        for(let platformInvoice of platformInvoices) {
          invoices = platformInvoice.data;
        }

      }
    }
  );

  return invoices;
}


// ===========================================
// CRM
// ===========================================
public async getAsyncAllCRMPipeline(): Promise<Array<CRMPipeline>> {
  let pipelines: Array<CRMPipeline> = [];
  
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "hive-crm", "pipelines", "admin", this.getCompanyId()).then(crmPipelines => {
      console.log("Successfully pulled Pipeline", crmPipelines);

      if(crmPipelines != undefined && crmPipelines != null) {
  
        for(let pipeline of crmPipelines) {
          pipelines.push(pipeline.data);
        }

      }
      
    }
  );

  return pipelines;
}

public async getAsyncCRMPipeline(id: string): Promise<CRMPipeline | null> {
  let pipeline: CRMPipeline = null;
  
  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": id,
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-crm", "pipelines", "admin", this.getCompanyId()).then(crmPipeline => {
      console.log("Successfully pulled Pipeline", crmPipeline);

      if(crmPipeline != undefined && crmPipeline != null) {
  
        pipeline = crmPipeline.data;

      }
      
    }
  );

  return pipeline;
}

public async upsertCRMPipeline(pipeline: CRMPipeline): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": pipeline.id,
    role: "admin"
  };

  await this.realm.mongoUpsertOne(query, "hive-crm", "pipelines", pipeline, "admin", this.getCompanyId(), false).then( async (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}

public async getAsyncAllCRMPipelinePipes(pipelineId: string): Promise<Array<CRMPipe>> {
  let pipes: Array<CRMPipe> = [];

  await this.getAsyncCRMPipeline(pipelineId).then( async (pipeline: CRMPipeline) => {

    if(pipeline != undefined && pipeline != null) {

      for(let pipe of pipeline.pipes) {

        await this.getAsyncCRMPipe(pipe).then( (retrievedPipe: CRMPipe) => {

          if(retrievedPipe != undefined && retrievedPipe !=null) {
            pipes.push(retrievedPipe);
          }

        });

      }

    }

  });

  return pipes;
}

public async getAsyncAllCRMPipes(): Promise<Array<CRMPipe>> {
  let pipes: Array<CRMPipe> = [];
  
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "hive-crm", "pipes", "admin", this.getCompanyId()).then(crmPipes => {
      console.log("Successfully pulled Pipes", crmPipes);

      if(crmPipes != undefined && crmPipes != null) {
  
        for(let pipe of crmPipes) {
          pipes.push(pipe.data);
        }

      }
      
    }
  );

  return pipes;
}

public async getAsyncCRMPipe(id: string): Promise<CRMPipe | null> {
  let pipe: CRMPipe = null;
  
  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": id,
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-crm", "pipes", "admin", this.getCompanyId()).then(crmPipe => {
      console.log("Successfully pulled Pipe", crmPipe);

      if(crmPipe != undefined && crmPipe != null) {
  
        pipe = crmPipe.data;

      }
      
    }
  );

  return pipe;
}

public async upsertCRMPipe(pipe: CRMPipe): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": pipe.id,
    role: "admin"
  };

  await this.realm.mongoUpsertOne(query, "hive-crm", "pipes", pipe, "admin", this.getCompanyId(), false).then( async (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}


public async getAsyncCRMDeal(id: string): Promise<CRMDeal | null> {
  let deal: CRMDeal = null;
  
  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": id,
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-crm", "deals", "admin", this.getCompanyId()).then(crmDeal => {
      console.log("Successfully pulled Deal", crmDeal);

      if(crmDeal != undefined && crmDeal != null) {
  
        deal = crmDeal.data;

      } else {
        console.log("Failed Deal Pull", query);
      }

    }
  );

  return deal;
}

public async getAsyncCRMDeals(pipeId: string): Promise<Array<CRMDeal>> {
  let deals: Array<CRMDeal> = [];
  
  let query: any = {
    company_id: this.getCompanyId(),
    "data.pipe": pipeId,
    role: "admin"
  };

  await this.realm.mongoGet(query, "hive-crm", "deals", "admin", this.getCompanyId()).then(crmDeals => {
      console.log("Successfully pulled Deal", crmDeals);

      if(crmDeals != undefined && crmDeals != null) {
  
        for(let deal of crmDeals) {
          deals.push(deal.data);
        }

      } else {
        console.log("Failed Deals Pull", query);
      }

    }
  );

  return deals;
}

public async getAsyncAssignedCRMDeals(profileId: string = this.getProfileId()): Promise<Array<CRMDeal>> {
  let deals: Array<CRMDeal> = [];

  await this.getAsyncEmployeeGroupPermissions(profileId).then( async (groups: Array<GroupPermissions>) => {

    let groupIds: Array<string> = [];

    for(let group of groups) {
      groupIds.push(group.id);
    }

    let query: any = {
      company_id: this.getCompanyId(),
      $or: [
        { "data.assignedStaff": profileId },
        { "data.assignedTeams": { $in: groupIds } },
      ],
      role: "admin"
    };

    console.log("Assigned Projects Query:", query);

    await this.realm.mongoGet(query, "hive-crm", "deals", "admin", this.getCompanyId()).then( crmDeals => {
      console.log("Successfully pulled Assigned Deals", crmDeals);

        if(crmDeals != undefined && crmDeals != null) {
    
          for(let deal of crmDeals) {
            deals.push(deal.data);
          }

        } else {
          console.log("Failed Deals Pull", query);
        }

      }
    );

  });

  return deals;
}

public async upsertCRMDeal(deal: CRMDeal): Promise<boolean> {
  let status: boolean = false;
  let clonedDeal: CRMDeal = {...deal};

  clonedDeal.last_update = new Date();
  clonedDeal.builtTags = [];
  clonedDeal.assignedStaffImgs = [];

  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": clonedDeal.id,
    role: "admin"
  };

  await this.realm.mongoUpsertOne(query, "hive-crm", "deals", clonedDeal, "admin", this.getCompanyId(), false).then( async (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}

public async getAsyncAllCRMArchivedDeals(): Promise<Array<CRMDealArchive>> {
  let deals: Array<CRMDealArchive> = [];
  
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "hive-crm", "deal-archives", "admin", this.getCompanyId()).then(crmDeals => {
      console.log("Successfully pulled Deals", crmDeals);

      if(crmDeals != undefined && crmDeals != null) {
  
        for(let deal of crmDeals) {
          deals.push(deal.data);
        }

      }
      
    }
  );

  return deals;
}

public async getAsyncCRMArchivedDeal(id: string): Promise<CRMDealArchive | null> {
  let deal: CRMDealArchive = null;
  
  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": id,
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-crm", "deal-archives", "admin", this.getCompanyId()).then(crmDeal => {
      console.log("Successfully pulled Deals", crmDeal);

      if(crmDeal != undefined && crmDeal != null) {
  
        deal = crmDeal.data;

      }
      
    }
  );

  return deal;
}

public async upsertCRMArchivedDeal(deal: CRMDealArchive): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": deal.id,
    role: "admin"
  };

  await this.realm.mongoUpsertOne(query, "hive-crm", "deal-archives", deal, "admin", this.getCompanyId(), false).then( async (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}

// ===========================================
// AUTOCOMPLETE SERVICES
// ===========================================

public async upsertAutocompleteServices(services: AutocompleteServices): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoUpsertOne(query, "hive-company-definitions", "autocomplete-services", services, "admin", this.getCompanyId(), false).then( async (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}

public async getAsyncAutocompleteServices(): Promise<AutocompleteServices> {
  let services: AutocompleteServices = {
    labor: [],
    parts: [],
    equipment: [],
    travel: [],
    misc: []
  };
  
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-company-definitions", "autocomplete-services", "admin", this.getCompanyId()).then(incomingServices => {
      console.log("Successfully pulled Autocomoplete Services", incomingServices);

      if(incomingServices != undefined && incomingServices != null) {
  
        services = incomingServices.data;

      }
      
    }
  );

  return services;
}


// ===========================================
// MULTI PURPOSE CALENDAR
// ===========================================

public async getAsyncCalendarEvent(id: string): Promise<CustomCalendarEvent | null> {
  let calendarEvent: CustomCalendarEvent = null;
  
  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": id,
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-schedule", "calendar", "admin", this.getCompanyId()).then(event => {
      console.log("Successfully pulled event", event);

      if(event != undefined && event != null) {
  
        calendarEvent = event.data;

      } else {
        console.log("Failed Calendar Event Pull", query);
      }

    }
  );

  return calendarEvent;
}

public async getAsyncAllCalendarEvents(start: Date): Promise<Array<CustomCalendarEvent>> {
  let calendarEvents: Array<CustomCalendarEvent> = [];

  let pageNumber: number = 0;
  let limit: number = 1000;

  let sortQuery: string = "data.title"

  let startDate: Date = new Date(start);
  let endDate: Date = new Date(start);

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  endDate.setMonth(endDate.getMonth() + 1);
  endDate.setDate(0);

  let matchQuery: any = 
  {
    $match: {
      company_id: this.getCompanyId(),
      role: "admin",
      $and: [
        {"data.start": { $gte: startDate } },
        {"data.start": { $lte: endDate } }
      ],
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-schedule", "calendar", "admin", this.getCompanyId(), pageNumber, limit).then(events => {
      console.log("Successfully pulled Calendar Events", events);

      if(events != undefined && events != null) {
  
        for(let event of events) {
          calendarEvents.push(event.data);
        }

      } else {
        console.log("Failed Calendar Events Pull", matchQuery);
      }

    }
  );

  return calendarEvents;
}

public async getAsyncCalendarEventsByStaff(staff: Array<string>, start: Date): Promise<Array<CustomCalendarEvent>> {
  let assignedStaffFilter: Array<any> = [];
  let calendarEvents: Array<CustomCalendarEvent> = [];

  let pageNumber: number = 0;
  let limit: number = 1000;

  let sortQuery: string = "data.title"
  let createdKey: string = "data.assignedStaff";

  let startDate: Date = new Date(start);
  let endDate: Date = new Date();

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  startDate.setDate(startDate.getDate() + 30);

  for(let assigned of staff) {
    assignedStaffFilter.push( {[createdKey]: { '$regex': assigned, '$options': 'i' }} );
  }

  let matchQuery: any = 
  {
    $match: {
      company_id: this.getCompanyId(),
      role: "admin",
      $and: [
        {"data.start": { $gte: startDate } },
        {"data.start": { $lte: endDate } },
        { $or: assignedStaffFilter }
      ],
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-schedule", "calendar", "admin", this.getCompanyId(), pageNumber, limit).then(events => {
      console.log("Successfully pulled Calendar Events", events);

      if(events != undefined && events != null) {
  
        for(let event of events) {
          calendarEvents.push(event.data);
        }

      } else {
        console.log("Failed Calendar Events Pull", matchQuery);
      }

    }
  );

  return calendarEvents;
}

public async upsertCalendarEvent(event: CustomCalendarEvent): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": event.id,
    role: "admin"
  };

  await this.realm.mongoUpsertOne(query, "hive-schedule", "calendar", event, "admin", this.getCompanyId(), false).then( async (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}

// ===========================================
// NOTIFICATIONS
// ===========================================

public async getAsyncNotifications(id: string): Promise<ApplicationNotifications> {
  if(id == undefined || id == null || id == "") {
    return;
  }

  let notifications: ApplicationNotifications = {
    id: id,
    companyId: this.getCompanyId(),
    lastUpdated: new Date(),
    messages: []
  };

  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": id,
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "swarm-platform-sync", "notifications", "admin", this.getCompanyId()).then(messages => {
      console.log("Successfully pulled notifications", messages);

      if(messages != undefined && messages != null) {
        
        notifications = messages.data

      }
    }
  );

  console.log("Notification Results: ", notifications);

  return notifications;
}

public async updateNotifications(notification: ApplicationNotifications): Promise<boolean> {
  let status: boolean = false;

  if(notification == undefined || notification == null || notification.id == "") {
    return false;
  }

  notification.lastUpdated = new Date();

  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": notification.id,
    role: "admin"
  };


  await this.realm.mongoUpsertOne(query, "swarm-platform-sync", "notifications", notification, "admin", this.getCompanyId(), false).then( (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}


// ===========================================
// COMPANY LEVEL SETTINGS
// ===========================================

public async getCompanyLevelInvoiceSettings(): Promise<InvoiceSettings> {
  let returnData: InvoiceSettings = null;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "swarm-company-level-settings","invoice-settings", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Invoice Settings! ", data);

    if(data != undefined) {
      
      if(data.length > 0) {
        returnData = data[0].data;
      }

    }
  });

  return returnData;
}

public async getCompanyLevelPaymentSettings(): Promise<PaymentSettings> {
  let returnData: PaymentSettings = null;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "swarm-company-level-settings","payment-settings", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Payment Settings! ", data);

    if(data != undefined) {
      
      if(data.length > 0) {
        returnData = data[0].data;
      }

    }
  });

  return returnData;
}

public async getCompanyLevelPaymentSettingsAsForeign(companyId: string): Promise<PaymentSettings> {
  let returnData: PaymentSettings = null;

  let query: any = {
    company_id: companyId,
    role: "admin"
  };

  await this.realm.mongoGetOneAsForeign(query, "swarm-company-level-settings","payment-settings", "admin", companyId).then(data => {
    console.log("Successfully found Payment Settings! ", data);

    if(data != undefined) {
      
        returnData = data.data;

    }
  });

  return returnData;
}

public async updateCompanyLevelInvoice(invoiceSettings: InvoiceSettings): Promise<void> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoUpsertOne(query, "swarm-company-level-settings", "invoice-settings", invoiceSettings, "admin", this.getCompanyId(), false);
}

public async updateCompanyLevelPayment(invoiceSettings: PaymentSettings): Promise<void> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoUpsertOne(query, "swarm-company-level-settings", "payment-settings", invoiceSettings, "admin", this.getCompanyId(), false);
}

// ===========================================



// ===========================================
// USER LEVEL SETTINGS
// ===========================================

public async getAsyncUserAgreement(): Promise<InfoPanelTrackedDetails> {
  let userAgreement: InfoPanelTrackedDetails = null;

  let query: any = {
    "data.userId": this.getProfileId(),
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "swarm-user-level-settings", "user-agreements", "admin", this.getCompanyId()).then( data => {
    console.log("User Agreement", data);

    if(data != undefined) {
      
      userAgreement = (data.data);

    }

  });

  return userAgreement;
}

public async updateUserAgreement(userAgreement: InfoPanelTrackedDetails): Promise<boolean> {
  let completionStatus: boolean = false;

  let query: any = {
    "data.userId": this.getProfileId(),
    role: "admin",
  };

  await this.realm.mongoUpsertOneUserLevel(query, "swarm-user-level-settings", "user-agreements", userAgreement, "admin").then( (status: boolean) => {
    
    completionStatus = status;

  });

  return completionStatus;
}

// ===========================================


// ===========================================
// DATA SYNC
// ===========================================

private async getAsyncGeneralDataSync(): Promise<GeneralDataSync> {
  let dataSync: GeneralDataSync = null;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "swarm-platform-sync", "general", "admin", this.getCompanyId(), false).then( data => {
    console.log("Navigator Heartbeat", data);

    if(data != undefined) {
      
        dataSync = (data.data);

    }
  });

  return dataSync;
}

private updateGeneralDataSync(dataSync: GeneralDataSync): void {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  this.realm.mongoUpsertOne(query, "swarm-platform-sync", "general", dataSync, "admin", this.getCompanyId(), false).then( data => {
    
  });
}

// ===========================================
// AUTOMATIONS
// ===========================================

  public getAutomationActionAdditionalReqKeys(): any {
    return this.automationActionRequirement;
  }

  public getAutomationMenu(): Array<AutomationCategory> {
    return this.automationActions;
  }

  public async getAsyncAutomations(): Promise<Array<Automation>> {
    let returnData: Array<any> = [];
    let query: any = {
      company_id: this.getCompanyId(),
      role: "admin"
    };

    await this.realm.mongoGet(query, "hive-automations", "automations", "admin", this.getCompanyId(), false).then(data => {
        console.log("Successfully pulled automations", data);

        if(data != undefined) {
          

          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }

        }
      }
    );

    console.log("Automation Results: ", returnData);

    return returnData;
  }

  public async updateAutomations(automation: Automation): Promise<boolean> {
    let status: boolean = false;

    let query: any = {
      "data.companyId": this.getCompanyId(),
      "data.id": automation.id,
      role: "admin"
    };


    await this.realm.mongoUpsertOne(query, "hive-automations", "automations", automation, "admin", this.getCompanyId(), false).then( (completionStatus: boolean) => {
      status = completionStatus;
    });

    return status;
  }

  public async getAsyncAutomationTag(tagId: string): Promise<AutomationTag> {
    let tag: AutomationTag = null;

    let query: any = {
      company_id: this.getCompanyId(),
      'data.id': tagId,
      role: "admin"
    };

    await this.realm.mongoGetOne(query, "hive-automations", "tags", "admin", this.getCompanyId()).then(data => {
        console.log("Successfully pulled automation tags", data);

        if(data != undefined) {
          
          tag = data.data;

        }

      }
    );

    console.log("Automation Tag Results: ", tag);

    return tag;
  }

  public async getAsyncAutomationTagAsForeign(tagId: string, companyId: string): Promise<AutomationTag> {
    let tag: AutomationTag = null;

    let query: any = {
      company_id: companyId,
      'data.id': tagId,
      role: "admin"
    };

    await this.realm.mongoGetOneAsForeign(query, "hive-automations", "tags", "admin", companyId).then(data => {
        console.log("Successfully pulled automation tags", data);

        if(data != undefined) {
          
          tag = data.data;

        }

      }
    );

    console.log("Automation Tag Results: ", tag);

    return tag;
  }

  public async getAsyncAutomationTags(): Promise<Array<AutomationTag>> {
    let returnData: Array<any> = [];
    let query: any = {
      company_id: this.getCompanyId(),
      role: "admin"
    };

    await this.realm.mongoGet(query, "hive-automations", "tags", "admin", this.getCompanyId(), false).then(data => {
        console.log("Successfully pulled automation tags", data);

        if(data != undefined) {
          

          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }

        }
      }
    );

    console.log("Automation Tag Results: ", returnData);

    return returnData;
  }

  public async getAsyncAutomationTagsByType(type: number): Promise<Array<AutomationTag>> {
    let returnData: Array<any> = [];
    let query: any = {
      company_id: this.getCompanyId(),
      'data.tagType': type,
      role: "admin"
    };

    await this.realm.mongoGet(query, "hive-automations", "tags", "admin", this.getCompanyId(), false).then(data => {
        console.log("Successfully pulled automation tags", data);

        if(data != undefined) {
          

          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }

        }
      }
    );

    console.log("Automation Tag Results: ", returnData);

    return returnData;
  }

  public async getAsyncAutomationTagsByTypeAsForeign(type: number, companyId: string): Promise<Array<AutomationTag>> {
    let returnData: Array<any> = [];
    let query: any = {
      company_id: companyId,
      'data.tagType': type,
      role: "admin"
    };
  
    await this.realm.mongoGetAsForeign(query, "hive-automations", "tags", "admin", companyId).then( data => {
      console.log("Successfully pulled automation tags", data);

      if(data != undefined) {
        

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

      }
  
    });
  
    console.log("Automation Tag Results: ", returnData);
    return returnData;
  }

  public async updateAutomationTag(tag: AutomationTag): Promise<boolean> {
    let status: boolean = false;

    let query: any = {
      "data.companyId": this.getCompanyId(),
      "data.id": tag.id,
      role: "admin"
    };


    await this.realm.mongoUpsertOne(query, "hive-automations", "tags", tag, "admin", this.getCompanyId(), false).then( (completionStatus: boolean) => {
      status = completionStatus;
    });

    return status;
  }

  public async deleteAutomationTag(tag: AutomationTag): Promise<boolean> {
    let status: boolean = false;

    let query: any = {
      "data.companyId": this.getCompanyId(),
      "data.id": tag.id,
      role: "admin"
    };

    await this.realm.mongoDeleteOne(query, "hive-automations", "tags", "admin", this.getCompanyId()).then( (completionStatus: boolean) => {
      status = completionStatus;
    });

    return status;
  }

  public getWorkOrderCreatedTrigger(): Observable<number> {
    return this.workOrderCreatedTrigger.asObservable();
  }

  public getWorkOrderStatusChangedTrigger(): Observable<number> {
    return this.workOrderStatusChangedTrigger.asObservable();
  }

  public getInventoryItemWorkOrderCreatedTrigger(): Observable<number> {
    return this.inventoryItemWorkOrderCreatedTrigger.asObservable();
  }

  public getInventoryItemWorkOrderStatusChangedTrigger(): Observable<number> {
    return this.inventoryItemWorkOrderStatusChangedTrigger.asObservable();
  }

  public getAssetWorkOrderCreatedTrigger(): Observable<number> {
    return this.assetWorkOrderCreatedTrigger.asObservable();
  }

  public getAssetWorkOrderStatusChangedTrigger(): Observable<number> {
    return this.assetWorkOrderStatusChangedTrigger.asObservable();
  }

  public getWorkOrderStatusEqualsTrigger(): Observable<number> {
    return this.workOrderStatusEqualsTrigger.asObservable();
  }

  public getWorkOrderDateLimitTrigger(): Observable<number> {
    return this.workOrderDateLimitTrigger.asObservable();
  }



// ===========================================
// Company Themes
// ===========================================

  public async getAsyncCompanyThemes(): Promise<Array<ColorTheme>> {
    let themes: Array<ColorTheme> = [];
    let query: any = {
      company_id: this.getCompanyId(),
      role: "admin"
    };

    await this.realm.mongoGet(query, "swarm-company-level-settings", "theme", "admin", this.getCompanyId(), false).then(data => {
        console.log("Successfully pulled Company Themes", data);

        if(data != undefined) {
          
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            themes.push(data[curserIndex].data);
          }

        }
      }
    );

    console.log("Company Theme Results: ", themes);

    return themes;
  }

  public async getAsyncCompanyTheme(themeId: string): Promise<ColorTheme> {
    let theme: ColorTheme = null;
    let query: any = {
      company_id: this.getCompanyId(),
      role: "admin",
      "data.id": themeId
    };

    await this.realm.mongoGetOne(query, "swarm-company-level-settings", "theme", "admin", this.getCompanyId()).then(themeData => {
        console.log("Successfully pulled Company Theme", themeData);

        if(themeData != undefined) {
          
          theme = themeData.data;

        }
      }
    );

    console.log("Company Theme Results: ", theme);

    return theme;
  }

  public async deleteCompanyTheme(theme: ColorTheme): Promise<boolean> {
    let status: boolean = false;

    let query: any = {
      company_id: this.getCompanyId(),
      "data.id": theme.id,
      role: "admin"
    };

    await this.realm.mongoDeleteOne(query, "swarm-company-level-settings", "theme", "admin", this.getCompanyId()).then( (completionStatus: boolean) => {
      status = completionStatus;
    });

    return status;
  }

  public async updateCompanyTheme(theme: ColorTheme): Promise<boolean> {
    let status: boolean = false;

    let query: any = {
      company_id: this.getCompanyId(),
      role: "admin",
      "data.id": theme.id
    };


    await this.realm.mongoUpsertOne(query, "swarm-company-level-settings", "theme", theme, "admin", this.getCompanyId(), false).then( (completionStatus: boolean) => {
      status = completionStatus;
    });

    return status;
  }


// ===========================================
// Task List
// ===========================================

  public async getAsyncTaskLists(): Promise<Array<TaskList>> {
    let taskLists: Array<TaskList> = [];
    let query: any = {
      company_id: this.getCompanyId(),
      role: "admin"
    };

    await this.realm.mongoGet(query, "hive-automations", "task-list", "admin", this.getCompanyId(), false).then(data => {
        console.log("Successfully pulled Task Lists", data);

        if(data != undefined) {
          
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            taskLists.push(data[curserIndex].data);
          }

        }
      }
    );

    console.log("Task Lists Results: ", taskLists);

    return taskLists;
  }

  public async getAsyncTaskList(listId: string): Promise<TaskList> {
    let taskList: TaskList = null;
    let query: any = {
      company_id: this.getCompanyId(),
      role: "admin",
      "data.id": listId
    };

    await this.realm.mongoGetOne(query, "hive-automations", "task-list", "admin", this.getCompanyId()).then(taskListData => {
        console.log("Successfully pulled Task List", taskListData);

        if(taskListData != undefined) {
          
            taskList = taskListData.data;

        }
      }
    );

    console.log("Task List Results: ", taskList);

    return taskList;
  }

  public async updateTaskList(taskList: TaskList): Promise<boolean> {
    let status: boolean = false;

    let query: any = {
      company_id: this.getCompanyId(),
      role: "admin",
      "data.id": taskList.id
    };


    await this.realm.mongoUpsertOne(query, "hive-automations", "task-list", taskList, "admin", this.getCompanyId(), false).then( (completionStatus: boolean) => {
      status = completionStatus;
    });

    return status;
  }

// ===========================================
// Forms
// ===========================================

public async searchAsyncDocuments(search: string): Promise<Array<FormDocument>> {
  let documents: Array<FormDocument> = [];
  let sort: string = "";
  let page: number = 0;
  let limit: number = 100;


  //Custom Fields
  // Object.keys(customFields).forEach(key => {
  // let createdKey: string = "data.custom_fields." + key;

  // query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  // });

  let query: Array<any> = []

  query.push({["data.title"]: { '$regex': search, '$options': 'i' }});

  let matchQuery = 
  {
    $match : {
      company_id: this.getCompanyId(),
      role: "admin",
      $and: [

        {
          
          $or: [
            { 'data.type': this.formTypes.FORM },
            { 'data.type': undefined }
          ]

        },

        {
          $or: query
        }

      ]

    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sort, "hive-forms", "documents", "admin", this.getCompanyId(), page, limit).then(data => {
      console.log("Successfully pulled documents", data);

      if(data != undefined) {
        
        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          documents.push(data[curserIndex].data);
        }

      }
    }
  );

  console.log("Documents Results: ", documents);

  return documents;
}

public async getAsyncDocuments(): Promise<Array<FormDocument>> {
  let documents: Array<FormDocument> = [];
  let sort: string = "";
  let page: number = 0;
  let limit: number = 100;

  // let query: any = {
  //   company_id: this.getCompanyId(),
  //   role: "admin",
  //   $or: [
  //     { 'data.type': this.formTypes.FORMS }
  //   ]
  // };

  let matchQuery = 
  {
    $match : {
      company_id: this.getCompanyId(),
      role: "admin",
      $or: [
        { 'data.type': this.formTypes.FORM },
        { 'data.type': undefined }
      ]
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sort, "hive-forms", "documents", "admin", this.getCompanyId(), page, limit).then(data => {
      console.log("Successfully pulled documentss", data);

      if(data != undefined) {
        
        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          documents.push(data[curserIndex].data);
        }

      }
    }
  );

  console.log("Document Results: ", documents);

  return documents;
}

public async getAsyncDocument(docId: string): Promise<FormDocument> {
  let document: FormDocument = null;
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.id": docId
  };

  await this.realm.mongoGetOne(query, "hive-forms", "documents", "admin", this.getCompanyId()).then(doc => {
      console.log("Successfully pulled document", doc);

      if(doc != undefined) {
        
          document = doc.data;

      }
    }
  );

  console.log("Document Results: ", document);

  return document;
}

public async updateDocument(document: FormDocument): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.id": document.id
  };

  document.dateLastUpdated = new Date();

  await this.realm.mongoUpsertOne(query, "hive-forms", "documents", document, "admin", this.getCompanyId(), false).then( (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}


public async getDocumentsAsForeign(companyId: string, documentId: string, code: string): Promise<Array<FormDocument>> {
  let returnedData: Array<FormDocument> = []; 

  // Needs to be adjusted to check code date
  let query: any = {
    company_id: companyId,
    "data.id": documentId,
    "data.code": code,
    $or: [
      { 'data.type': this.formTypes.FORM },
      { 'data.type': undefined }
    ]
  };

  await this.realm.mongoGetAsForeign(query, "hive-forms", "documents", "admin", companyId).then( docs => {
    console.log("Successfully found Documents! ", docs);

    if(docs != undefined && docs != null) {

      for(let doc of docs) {
        returnedData.push(doc.data);
      }
    }

  });

  return returnedData;
}

public async getDocumentAsForeign(companyId: string, documentId: string, code: string): Promise<FormDocument> {
  let returnedData: FormDocument = null; 

   // Needs to be adjusted to check code date
  //  let query: any = {
  //   company_id: companyId,
  //   "data.id": documentId,
  //   "data.code": code
  // };

  let query: any = {
    company_id: companyId,
    "data.id": documentId,
    $or: [
      { 'data.type': this.formTypes.FORM },
      { 'data.type': undefined }
    ]
  };

  await this.realm.mongoGetOneAsForeign(query, "hive-forms", "documents", "admin", companyId).then( doc => {
    console.log("Successfully found Foreign Document! ", doc);

    if(doc != undefined && doc != null) {
        returnedData = doc.data;
    }

  });

  return returnedData;
}

public async updateDocumentAsForeign(document: FormDocument): Promise<boolean> {
  let completedStatus: boolean = false;

  await this.getDocumentAsForeign(document.companyId, document.id, null).then( async (doc: FormDocument) => {

    document.dateLastUpdated = new Date();

    await this.realm.mongoUpdateAsForeign({ "data.id": document.id }, "hive-forms", "documents", document, "admin", document.companyId).then( (status: boolean) => {

      completedStatus = status;
  
    });

  });

  
  return completedStatus;
}


public async searchAsyncWorkOrderVersions(search: string): Promise<Array<FormDocument>> {
  let documents: Array<FormDocument> = [];
  let sort: string = "";
  let page: number = 0;
  let limit: number = 100;


  //Custom Fields
  // Object.keys(customFields).forEach(key => {
  // let createdKey: string = "data.custom_fields." + key;

  // query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  // });

  let query: Array<any> = []

  query.push({["data.title"]: { '$regex': search, '$options': 'i' }});

  let matchQuery = 
  {
    $match : {
      company_id: this.getCompanyId(),
      role: "admin",
      $and: [

        {
          
          $or: [
            { 'data.type': this.formTypes.WORK_ORDER }
          ]

        },

        {
          $or: query
        }

      ]

    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sort, "hive-forms", "documents", "admin", this.getCompanyId(), page, limit).then(data => {
      console.log("Successfully pulled documents", data);

      if(data != undefined) {
        
        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          documents.push(data[curserIndex].data);
        }

      }
    }
  );

  console.log("Documents Results: ", documents);

  return documents;
}

public async getAsyncWorkOrderVersions(): Promise<Array<FormDocument>> {
  let documents: Array<FormDocument> = [];
  let sort: string = "";
  let page: number = 0;
  let limit: number = 100;

  // let query: any = {
  //   company_id: this.getCompanyId(),
  //   role: "admin",
  //   $or: [
  //     { 'data.type': this.formTypes.FORMS }
  //   ]
  // };

  let matchQuery = 
  {
    $match : {
      company_id: this.getCompanyId(),
      role: "admin",
      $or: [
        { 'data.type': this.formTypes.WORK_ORDER }
      ]
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sort, "hive-forms", "documents", "admin", this.getCompanyId(), page, limit).then(data => {
      console.log("Successfully pulled documentss", data);

      if(data != undefined) {
        
        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          documents.push(data[curserIndex].data);
        }

      }
    }
  );

  console.log("Document Results: ", documents);

  return documents;
}

public async getAsyncActiveWorkOrderVersion(): Promise<FormDocument> {
  let document: FormDocument = null;

  let query = 
  {
    company_id: this.getCompanyId(),
    role: "admin",
    'data.activeWorkOrderVersion': true,
    $or: [
      { 'data.type': this.formTypes.WORK_ORDER }
    ]
  };

  await this.realm.mongoGetOne(query, "hive-forms", "documents", "admin", this.getCompanyId()).then(data => {
      console.log("Successfully pulled active work order version", data);

      if(data != undefined) {
        
        document = data.data;

      }
    }
  );

  console.log("Version Results: ", document);

  return document;
}

public async getAsyncWorkOrderVersion(docId: string): Promise<FormDocument> {
  let document: FormDocument = null;
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.id": docId
  };

  await this.realm.mongoGetOne(query, "hive-forms", "documents", "admin", this.getCompanyId()).then(doc => {
      console.log("Successfully pulled document", doc);

      if(doc != undefined) {
        
          document = doc.data;

      }
    }
  );

  console.log("Document Results: ", document);

  return document;
}

public async updateWorkOrderVersion(document: FormDocument): Promise<boolean> {
  let status: boolean = false;

  document.type = this.formTypes.WORK_ORDER

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.id": document.id
  };

  document.dateLastUpdated = new Date();

  await this.realm.mongoUpsertOne(query, "hive-forms", "documents", document, "admin", this.getCompanyId(), false).then( (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}



public async searchAsyncSignDocuments(search: string): Promise<Array<FormDocument>> {
  let documents: Array<FormDocument> = [];
  let sort: string = "";
  let page: number = 0;
  let limit: number = 100;


  //Custom Fields
  // Object.keys(customFields).forEach(key => {
  // let createdKey: string = "data.custom_fields." + key;

  // query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  // });

  let query: Array<any> = []

  query.push({["data.title"]: { '$regex': search, '$options': 'i' }});

  let matchQuery = 
  {
    $match : {
      company_id: this.getCompanyId(),
      role: "admin",
      $and: [

        {
          
          $or: [
            { 'data.type': this.formTypes.FORM },
            { 'data.type': undefined }
          ]

        },

        {
          $or: query
        }

      ]
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sort, "hive-forms", "sign-documents", "admin", this.getCompanyId(), page, limit).then(data => {
      console.log("Successfully pulled documents", data);

      if(data != undefined) {
        
        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          documents.push(data[curserIndex].data);
        }

      }
    }
  );

  console.log("Documents Results: ", documents);

  return documents;
}

public async getAsyncSignDocuments(): Promise<Array<FormDocument>> {
  let documents: Array<FormDocument> = [];
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    $or: [
      { 'data.type': this.formTypes.FORM },
      { 'data.type': undefined }
    ]
  };

  await this.realm.mongoGet(query, "hive-forms", "sign-documents", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled documentss", data);

      if(data != undefined) {
        
        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          documents.push(data[curserIndex].data);
        }

      }
    }
  );

  console.log("Document Results: ", documents);

  return documents;
}

public async getAsyncSignDocument(docId: string): Promise<FormDocument> {
  let document: FormDocument = null;
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.id": docId
  };

  await this.realm.mongoGetOne(query, "hive-forms", "sign-documents", "admin", this.getCompanyId()).then(doc => {
      console.log("Successfully pulled document", doc);

      if(doc != undefined) {
        
          document = doc.data;

      }
    }
  );

  console.log("Document Results: ", document);

  return document;
}

public async updateSignDocument(document: FormDocument): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.id": document.id
  };

  document.dateLastUpdated = new Date();

  await this.realm.mongoUpsertOne(query, "hive-forms", "sign-documents", document, "admin", this.getCompanyId(), false).then( (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}


public async getSignDocumentsAsForeign(companyId: string, documentId: string, code: string): Promise<Array<FormDocument>> {
  let returnedData: Array<FormDocument> = []; 

  // Needs to be adjusted to check code date
  let query: any = {
    company_id: companyId,
    "data.id": documentId,
    "data.code": code,
    $or: [
      { 'data.type': this.formTypes.FORM },
      { 'data.type': undefined }
    ]
  };

  await this.realm.mongoGetAsForeign(query, "hive-forms", "sign-documents", "admin", companyId).then( docs => {
    console.log("Successfully found Documents! ", docs);

    if(docs != undefined && docs != null) {

      for(let doc of docs) {
        returnedData.push(doc.data);
      }
    }

  });

  return returnedData;
}

public async getSignDocumentAsForeign(companyId: string, documentId: string, code: string): Promise<FormDocument> {
  let returnedData: FormDocument = null; 

   // Needs to be adjusted to check code date
  //  let query: any = {
  //   company_id: companyId,
  //   "data.id": documentId,
  //   "data.code": code
  // };

  let query: any = {
    company_id: companyId,
    "data.id": documentId,
    $or: [
      { 'data.type': this.formTypes.FORM },
      { 'data.type': undefined }
    ]
  };

  await this.realm.mongoGetOneAsForeign(query, "hive-forms", "sign-documents", "admin", companyId).then( doc => {
    console.log("Successfully found Foreign Document! ", doc);

    if(doc != undefined && doc != null) {
        returnedData = doc.data;
    }

  });

  return returnedData;
}

public async updateSignDocumentAsForeign(document: FormDocument): Promise<boolean> {
  let completedStatus: boolean = false;

  await this.getDocumentAsForeign(document.companyId, document.id, null).then( async (doc: FormDocument) => {

    document.dateLastUpdated = new Date();

    await this.realm.mongoUpdateAsForeign({ "data.id": document.id }, "hive-forms", "sign-documents", document, "admin", document.companyId).then( (status: boolean) => {

      completedStatus = status;
  
    });

  });

  
  return completedStatus;
}




public async searchAsyncTemplates(search: string): Promise<Array<FormDocument>> {
  let documents: Array<FormDocument> = [];
  let sort: string = "";
  let page: number = 0;
  let limit: number = 100;


  //Custom Fields
  // Object.keys(customFields).forEach(key => {
  // let createdKey: string = "data.custom_fields." + key;

  // query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  // });

  let query: Array<any> = []

  query.push({["data.title"]: { '$regex': search, '$options': 'i' }});

  let matchQuery = 
  {
    $match : {
      company_id: this.getCompanyId(),
      role: "admin",
      $or: query
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sort, "hive-forms", "templates", "admin", this.getCompanyId(), page, limit).then(data => {
      console.log("Successfully pulled templates", data);

      if(data != undefined) {
        
        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          documents.push(data[curserIndex].data);
        }

      }
    }
  );

  console.log("Templates Results: ", documents);

  return documents;
}

public async getAsyncTemplates(): Promise<Array<FormDocument>> {
  let documents: Array<FormDocument> = [];
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "hive-forms", "templates", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled templates", data);

      if(data != undefined) {
        
        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          documents.push(data[curserIndex].data);
        }

      }
    }
  );

  console.log("Templates Results: ", documents);

  return documents;
}

public async getAsyncTemplate(docId: string): Promise<FormDocument> {
  let document: FormDocument = null;
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.id": docId
  };

  await this.realm.mongoGetOne(query, "hive-forms", "templates", "admin", this.getCompanyId()).then(doc => {
      console.log("Successfully pulled Template", doc);

      if(doc != undefined) {
        
          document = doc.data;

      }
    }
  );

  console.log("Template Results: ", document);

  return document;
}

public async updateTemplate(document: FormDocument): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.id": document.id
  };


  await this.realm.mongoUpsertOne(query, "hive-forms", "templates", document, "admin", this.getCompanyId(), false).then( (completionStatus: boolean) => {
    status = completionStatus;
  });

  return status;
}


public async getTemplatesAsForeign(companyId: string, documentId: string, code: string): Promise<Array<FormDocument>> {
  let returnedData: Array<FormDocument> = []; 

  // Needs to be adjusted to check code date
  let query: any = {
    company_id: companyId,
    "data.id": documentId,
    "data.code": code
  };

  await this.realm.mongoGetAsForeign(query, "hive-forms", "templates", "admin", companyId).then( docs => {
    console.log("Successfully found Documents! ", docs);

    if(docs != undefined && docs != null) {

      for(let doc of docs) {
        returnedData.push(doc.data);
      }
    }

  });

  return returnedData;
}

public async getTemplateAsForeign(companyId: string, documentId: string, code: string): Promise<FormDocument> {
  let returnedData: FormDocument = null; 

   // Needs to be adjusted to check code date
   let query: any = {
    company_id: companyId,
    "data.id": documentId,
    "data.code": code
  };

  await this.realm.mongoGetOneAsForeign(query, "hive-forms", "templates", "admin", companyId).then( doc => {
    console.log("Successfully found Foreign Document! ", doc);

    if(doc != undefined && doc != null) {
        returnedData = doc.data;
    }

  });

  return returnedData;
}

public async updateTemplateAsForeign(document: FormDocument): Promise<boolean> {
  let completedStatus: boolean = false;

  await this.getDocumentAsForeign(document.companyId, document.id, null).then( async (doc: FormDocument) => {

    document.dateLastUpdated = new Date();

    await this.realm.mongoUpdateAsForeign({ "data.id": document.id }, "hive-forms", "templates", document, "admin", document.companyId).then( (status: boolean) => {

      completedStatus = status;
  
    });

  });

  
  return completedStatus;
}

// ===========================================
// PROFILE
// ===========================================

  public getPlatformName(): string {
    return this.platformName;
  }

  public getPlatformId(): string {
    return this.platformId;
  }

  public getCompanyName(): string {
    return this.companyName;
  }

  public getCompanyId(): string {
    return this.companyId;
  }

  public getCompanyLogo(): any {
    return this.companyLogo;
  }

  public getCompanyLogoObservable(): Observable<any> {
    return this.companyLogoSubject.asObservable();
  }

  public getLinkedAccounts(): Array<LinkedAccount> {
    return this.linkedAccounts;
  }

  public getProfileId(): string {
    return this.profileId;
  }

  public getCallCenterId(): string {
    return this.callCenterId;
  }

  public getProfilePagePermissions(): Array<Page> {
    return this.userPagePermissions;
  }

  public getCurrentProfile(): Profile {
    return this.profile;
  }

  public monitorLoggedInProfileId(): Observable<string> {
    return this.profileIdSubject.asObservable();
  }

  public getUser(): any {
    return this.user;
  }

  public getPassword(): string {
    return this.password;
  }

  public setCurrentProfile(profile: Profile): void {
    this.profile = profile;
  }

  public setUser(user: any): void {
    this.user = user;
  }

  public setPassword(password: string): void {
    this.password = password;
  }

  public setEmail(email: string): void {
    this.email = email;
  }

  public setCompanyName(companyName: string): void {
    this.companyName = companyName;
  }

  public setCompanyId(companyId: string): void {
    this.companyId = companyId;
  }

  public setCompanyLogo(logoId: string): void {

    if(this.companyLogo != logoId) {
      this.companyLogo = logoId;
      this.companyLogoSubject.next(this.companyLogo);
    }

  }

  public setProfilePagePermissions(userPagePermissions: Array<Page>): void {
    this.userPagePermissions = userPagePermissions;
  }

  public setLinkedAccounts(linkedAccounts: Array<LinkedAccount>): void {
    this.linkedAccounts = linkedAccounts;
  }

  public setProfileId(profileId: string): void {
    this.profileId = profileId;

    this.profileIdSubject.next(this.profileId);
  }

  public async login(email: string, password: string): Promise<boolean> {
    let success: boolean = false;

    await this.realm.mongoLogin(email, password).then( async (data) => {

      if(data != undefined) {
        
        success = true;
        await this.getAsyncLoggedInProfile();

        this.setUser(this.realm.getUser());
        this.setEmail(email);
        this.setPassword(password);
        
      }

    });

    return success;
  }

  adminLogin(email: string, password: string) {
    this.realm.mongoLogin(email, password).then( data => {

      if(data != undefined) {
        
        this.getLoggedInProfile();

        this.setUser(this.realm.getUser());

        // this.router.navigate(['/hive/demo/dispatch-console']);

      }

    });
  }

  public logout() {
    let profile: Profile = this.getCurrentProfile();
    profile.online_status = 3;

    console.log("Updating New Current Logout Profile Data: ", profile);


    this.updateProfile(profile).then( data => {

      this.getAsyncGeneralDataSync().then( (dataSync: GeneralDataSync) => {

        if(dataSync == null) {
          dataSync = {
            categories: {
              init_sync: new Date,
              dispatch: new Date,
              work_orders_open: new Date,
              work_orders_closed: new Date,
              invoice_open: new Date,
              invoice_closed: new Date,
              shift_schedule_published: new Date,
              inventory: new Date,
              inventory_work_order: new Date,
              asset: new Date,
              asset_work_order: new Date,
              employee_management: new Date,
              employees: new Date,
              automation: new Date,
              admin: new Date,
              notification: new Date,
              messaging: new Date,
              phone_call: new Date,
              text_message: new Date
            }
          };
        }

        dataSync.categories.employees = new Date();
        this.updateGeneralDataSync(dataSync);

        this.setProfileId("");
        this.realm.mongoLogout();
        this.profileSubject.next();
      });
      
    });

  }

  public removeTempLoggedUser(): void {
    this.realm.mongoRemoveTempLoggedUser();
  }

  public async adminResetPassword(id: string, email: string, newPassword: string): Promise<boolean> {
    let status: boolean = false;

    await this.realm.mongoAdminResetPassword(email, newPassword).then( (success: boolean) => {

      if(success && id == this.getProfileId()) {

        this.setEmail(email);
        this.setPassword(newPassword);

      }

      status = success;
    });

    return status;
  }

  public async authenticatedProfileResetPassword(id: string, email: string, newPassword: string): Promise<boolean> {
    let status: boolean = false;

    await this.realm.mongoAdminResetPassword(email, newPassword).then( (success: boolean) => {

      if(success && id == this.getProfileId()) {

        this.setEmail(email);
        this.setPassword(newPassword);

      }

      status = success;
    });

    return status;
  }

  public async registerNewCompany(company: CompanyProfile): Promise<boolean> {
    let completionStatus: boolean = false;

    await this.realm.mongoRegister("hive-admin", "company-profiles", company, "register", company.id).then( async (companyStatus: boolean) => {

      if(companyStatus) {

        console.log("Successfully Registered New Company!");

        let profileData: Profile = this.getCurrentProfile();

        if(profileData.linked_accounts == undefined || profileData.linked_accounts == null) {
          profileData.linked_accounts = [];
        }

        profileData.linked_accounts.push({
          id: company.id,
          requestId: this.generateKey(),
          profileImg: company.image,
          jobTitle: ""
        });
        
        await this.updateOneProfile(profileData, true).then( async (profileStatus: boolean) => {

          completionStatus = profileStatus;

          await this.getAsyncProfile().then( async (profile: Profile) => {

            if(profileStatus && profile != undefined && profile != null) {
              console.log("Obtaining New Current Profile Data: ", profile);
              this.setCurrentProfile(profile);
              this.setLinkedAccounts(profile.linked_accounts);
              await this.switchCompanyProfile(company.id);
            }

          });
          

        });

      }

    });

    return completionStatus;
  }

  public async getPagedSearchCompanies(searchFilter: string, pageNumber: number, limit: number): Promise<CompanyProfile[]> {
    let filteredCompanies: Array<CompanyProfile> = [];

    let matchQuery: any;
    let query: Array<any> = [];
    let sortQuery: string = "data.company_name"

    let createdKey: string = "data.company_name";

    query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});

    matchQuery = 
    {
      $match: {
        $or: query
      }
    };
  
    console.log("Search Query: ", query);    
  
    await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-admin", "company-profiles", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered companies", data);
  
        if(data != undefined) {
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            filteredCompanies.push(data[curserIndex].data);
          }

        }
      }
    );
  
    //Using the same Subject since this will be applied to searching and allowing auto updating
    return filteredCompanies;
  }

  public async getPagedDateRangeCompanies(startDate: Date, endDate: Date, pageNumber: number, limit: number): Promise<CompanyProfile[]> {
    let filteredCompanies: Array<CompanyProfile> = [];

    let matchQuery: any;
    let query: Array<any> = [];
    let sortQuery: string = "data.company_name"

    // Resetting Time
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(24, 0, 0, 0);

    console.log("Start Date: ", startDate.toLocaleDateString());
    console.log("End Date: ", endDate.toLocaleDateString());

    matchQuery = 
    {
      $match: {
        $and: [
          {"data.created_date": { $gte: startDate } },
          {"data.created_date": { $lte: endDate } }
        ],
      }
    };
  
    console.log("Search Query: ", query);    
  
    await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-admin", "company-profiles", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered companies", data);
  
        if(data != undefined) {
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            filteredCompanies.push(data[curserIndex].data);
          }

        }
      }
    );
  
    //Using the same Subject since this will be applied to searching and allowing auto updating
    return filteredCompanies;
  }

  public async getPagedDateRangeSearchCompanies(searchFilter: string, pageNumber: number, limit: number): Promise<CompanyProfile[]> {
    let filteredCompanies: Array<CompanyProfile> = [];

    let matchQuery: any;
    let query: Array<any> = [];
    let sortQuery: string = "data.company_name"

    let createdKey: string = "data.company_name";

    let startDate: Date = new Date();
    let endDate: Date = new Date();

    // Resetting Time
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(24, 0, 0, 0);

    startDate.setDate(startDate.getDate() - 30);

    console.log("Start Date: ", startDate.toLocaleDateString());
    console.log("End Date: ", endDate.toLocaleDateString());

    matchQuery = 
    {
      $match: {
        $and: [
          {"data.created_date": { $gte: startDate } },
          {"data.created_date": { $lte: endDate } },
          {[createdKey]: { '$regex': searchFilter, '$options': 'i' }}
        ],
      }
    };
  
    console.log("Search Query: ", query);    
  
    await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-admin", "company-profiles", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered companies", data);
  
        if(data != undefined) {
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            filteredCompanies.push(data[curserIndex].data);
          }

        }
      }
    );
  
    //Using the same Subject since this will be applied to searching and allowing auto updating
    return filteredCompanies;
  }

  public async registerNewUser(accountType: number, first_name:string, last_name: string, email: string, password: string): Promise<boolean> {
    let registrationStatus: boolean = false;

    await this.realm.mongoRegisterNewUser(email, password).then( async data => {

      if(data) {
        console.log("Successfully Added New User!");

        await this.realm.mongoLogin(email, password).then( async data => {
          console.log("New User Data: " + data);

          let profileData: Profile = {
            id: data.id,
            company_name: "",
            company_id: "",
            linked_account_requests: [ ],
            linked_accounts: [  ],
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: null,
            address: {
              street: "",
              city: "",
              state: "",
              zip: null
            },

            role: {
              title: "admin"
            },

            createdDate: new Date()
          }

          this.setUser(this.realm.getUser());

          this.setCurrentProfile(profileData);
          this.setProfileId(profileData.id);
          this.setCompanyName(profileData.company_name);
          this.setCompanyId(profileData.company_id);
          this.setLinkedAccounts(profileData.linked_accounts);

          await this.getAsyncCallCenterID();
          await this.upsertOneProfile(profileData, true);

          if(this.user) {
            this.router.navigate([this.newlyRegisteredUserRoute]);
            registrationStatus = true;
          }

        });

      }

    });

    return registrationStatus;
  }

  public async adminRegisterNewUser(username: string, email: string, password: string, adminEmail: string, adminPassword: string, profileData: Profile): Promise<void> {
    await this.realm.mongoAdminRegisterNewUser(email, password).then( async data => {
      console.log("Successfully Added New User!", data);

      // profileData.id = this.generateKey(this.keyTypes.PROFILE_ID);
      // this.updateOneProfile(profileData, true);

      // Logging in as the newly registered user to admin add them to the company ID
      await this.realm.mongoLogin(email, password).then( async data => {
        console.log("New User Data: ", data);
        // id = data.id;

        profileData.id = data.id;

        // Logging back out of the newly registered user
        await this.realm.mongoLogout().then( async data => {

          // Logging in as the admin user
          await this.realm.mongoLogin(this.email, this.password).then( async data => {
            console.log("Admin Relogged in");
    
            await this.updateOneProfile(profileData, true);
          });
        });
      });

      
      // this.removeTempLoggedUser();
      
    });
  }

  public async upsertOneProfile(profileData: Profile, adminAddedProfile?: boolean): Promise<boolean> {
    let status: boolean = false;

    let query: any = { 
      "data.id": profileData.id, 
      $or: [
        { "data.company_id": this.getCompanyId() },
        { "data.linked_accounts.id": this.getCompanyId() }
      ]
    };

    // If the user isn't focused on a company then display their logged in account unassociated with a company
    if(this.getCompanyId() == null || "") {
      query = {
        "data.id": this.getProfileId()
      };
    }

    profileData.last_update = new Date();

    if(this.profileId == profileData.id || adminAddedProfile)  {
      await this.realm.mongoUpsertOneProfile(query , "hive-admin","employees", profileData, "admin", this.getCompanyId(), false).then( (profileStatus: boolean) => {

        if(profileStatus && this.profileId == profileData.id) {
          this.setCompanyId(profileData.company_id);
          this.setLinkedAccounts(profileData.linked_accounts);

          this.snackBar.open('Profile information updated successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

          status = profileStatus;
        }

      });
    } else {
      console.error("Incoming Profile ID does not match outgoing Profile ID!");
    }

    return status;
  }

  public async updateOneProfile(profileData: Profile, adminAddedProfile?: boolean): Promise<boolean> {
    let status: boolean = false;

    let query: any = { 
      "data.id": profileData.id, 
      $or: [
        { "data.company_id": this.getCompanyId() },
        { "data.linked_accounts.id": this.getCompanyId() }
      ]
    };

    // If the user isn't focused on a company then display their logged in account unassociated with a company
    if(this.getCompanyId() == null || "") {
      query = {
        "data.id": this.getProfileId()
      };
    }

    console.log("Updating New Current Profile Data: ", profileData);

    profileData.last_update = new Date();

    if(this.profileId == profileData.id || adminAddedProfile)  {
      await this.realm.mongoUpdateOneProfile(query , "hive-admin","employees", profileData, "admin", this.getCompanyId(), false).then( (profileStatus: boolean) => {

        if(profileStatus && this.profileId == profileData.id) {
          this.setCompanyId(profileData.company_id);
          this.setCurrentProfile(profileData);
          this.setLinkedAccounts(profileData.linked_accounts);

          this.snackBar.open('Profile information updated successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

          status = profileStatus;
        }

      });
    } else {
      console.error("Incoming Profile ID does not match outgoing Profile ID!");
    }

    return status;
  }

  public async updateProfile(profileData: Profile, adminAddedProfile?: boolean): Promise<boolean> {

    let status: boolean = false;

    if(this.profileId == profileData.id || adminAddedProfile)  {
      let query: any = {
        "data.id": profileData.id,
        $or: [
          { "data.company_id": this.getCompanyId() },
          { "data.linked_accounts.id": this.getCompanyId() }
        ]
      };

      // If the user isn't focused on a company then display their logged in account unassociated with a company
      if(this.getCompanyId() == null) {
        query = {
          "data.id": this.getProfileId(),
        };
      }

      console.log("Updating New Current Secondary Profile Data: ", profileData);
      profileData.last_update = new Date();

      await this.realm.mongoUpdateOneProfile(query, "hive-admin","employees", profileData, "admin", this.getCompanyId(), false).then( async data => {

        if(data != undefined && data != null) {
          this.getProfile();
          status = true;
        }

      });
    } else {
      console.error("Incoming Profile ID does not match outgoing Profile ID!");
    }


    return status;
  }

  public async insertNewLinkRequest(email: String): Promise<LinkedAccountRequest> {
    let expirationDate: Date = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);

    let linkedAccountRequest: LinkedAccountRequest = {
      id: this.generateKey(this.keyTypes.LINKED_ACCOUNT_REQUEST_ID),
      email: email,
      companyId: this.getCompanyId(),
      expirationDate: expirationDate,
      acceptedDate: null,
      revoked: false
    };

    // Saving the new link request in separate company database
    await this.realm.mongoInsertOne("hive-admin", "employee-link-requests", linkedAccountRequest, "admin", this.getCompanyId()).then( (status: boolean) => {

      if(!status) {
        linkedAccountRequest = null;
      }

    });

    return linkedAccountRequest;
  }

  public async sendCompanyLinkRequest(email: string): Promise<any> {
    let self = this;
    let companyStatus: boolean = false;
    let userStatus: boolean = false;

    // Locating the requested user via email
    await this.getAsyncEmployeeByEmail(email).then( async (profile: Profile) => {

      if(profile == undefined || profile == null) { return { user: userStatus, company: companyStatus } }

      let query: any = {
        "data.id": profile.id
      };  

      if(profile.linked_account_requests == undefined || profile.linked_account_requests == null) {
        profile.linked_account_requests = [];
      }

      
      // Getting Company Profile to update it's records of the new link request
      await this.getCompanyProfile(this.companyId).then( async (company: CompanyProfile) => {

        if(company == undefined || company == null) { return { user: userStatus, company: companyStatus } }

        
        this.insertNewLinkRequest(email).then( async (request: LinkedAccountRequest) => {

          if(request != null) {

            let linkedEmployeeRequest: LinkedEmployeeRequest = {
              id: request.id,
              profileId: profile.id
            };
    
            if(company.linked_employee_requests == undefined || company.linked_employee_requests == null) {
              company.linked_employee_requests = [];
            }
    
            profile.linked_account_requests.push(request);
            company.linked_employee_requests.push(linkedEmployeeRequest);

            profile.last_update = new Date();
    
            // Saving the new link request to the company
            await self.updateCompanyProfile(company).then( (cStatus: boolean) => {
    
                companyStatus = cStatus;
    
            });
    
            // Saving the new link request to invitee profile
            await self.realm.mongoUpdateOneUser(query, "hive-admin", "employees", profile, "admin", self.getCompanyId()).then( (uStatus: boolean) => {
    
              userStatus = uStatus;
        
            });

          }

        });
       



      });

    });
    
    return { user: userStatus, company: companyStatus };
  }

  public async cancelCompanyLinkRequestByCompany(email: string, requestId: string): Promise<any> {
    let companyStatus: boolean = false;
    let userStatus: boolean = false;

    // Getting User Profile to update it's records of the link request removal
    await this.getAsyncEmployeeByEmail(email).then( async (profile: Profile) => {
      let query: any = {
        "data.id": profile.id
      };  

      if(profile == undefined || profile == null) { return { user: userStatus, company: companyStatus } }

      if(profile.linked_account_requests == undefined || profile.linked_account_requests == null) {
        profile.linked_account_requests = [];
      }

      let linkedAccountRequestIndex: number = profile.linked_account_requests.findIndex( (linkedRequest: LinkedAccountRequest) => {
        return linkedRequest.id == requestId;
      })

      if(linkedAccountRequestIndex > -1) {
        profile.linked_account_requests.splice(linkedAccountRequestIndex, 1);
      }


      // Getting Company Profile to update it's records of the link request removal
      await this.getCompanyProfile(this.companyId).then( async (company: CompanyProfile) => {

        if(company == undefined || company == null) { return { user: userStatus, company: companyStatus } }

        if(company.linked_employee_requests == undefined || company.linked_employee_requests == null) {
          company.linked_employee_requests = [];
        }

        let linkedEmployeeRequestIndex: number = company.linked_employee_requests.findIndex( (linkedRequest: LinkedEmployeeRequest) => {
          return linkedRequest.id == requestId;
        })

        if(linkedEmployeeRequestIndex > -1) {
          company.linked_employee_requests.splice(linkedEmployeeRequestIndex, 1);
        }
        

        // Saving the new link request to the company
        await this.updateCompanyProfile(company).then( (cStatus: boolean) => {

          companyStatus = cStatus;

        });

        this.rejectCompanyLinkRequestTransaction(this.getCompanyId(), requestId).then( async (success: boolean) => {

          if(success) {
            profile.last_update = new Date();

            await this.realm.mongoUpdateOneUser(query, "hive-admin","employees", profile, "admin", this.getCompanyId()).then( async (uStatus: boolean) => {
              
                userStatus = uStatus;

            });

          }

        });

      });

    });
    
    return { user: userStatus, company: companyStatus }
  }

  public async cancelCompanyLinkRequestByCurrentUser(companyId: string, requestId: string): Promise<boolean> {
    let completionStatus: boolean = false;
    let profile: Profile = this.getCurrentProfile();

    let query: any = {
      "data.id": profile.id
    };  

    if(profile.linked_account_requests == undefined || profile.linked_account_requests == null) {
      profile.linked_account_requests = [];
    }

    let linkedRequestIndex: number = profile.linked_account_requests.findIndex( (linkedRequest: LinkedAccountRequest) => {
      return linkedRequest.id == requestId;
    })

    if(linkedRequestIndex > -1) {
      profile.linked_account_requests.splice(linkedRequestIndex, 1);
    }

    this.rejectCompanyLinkRequestTransaction(companyId, requestId).then( async (success: boolean) => {

      if(success) {
        profile.last_update = new Date();

        await this.realm.mongoUpdateOneUser(query, "hive-admin","employees", profile, "admin", this.getCompanyId()).then( async (status: boolean) => {

          if(status) {
          
            completionStatus = status;

          }

        });

      }

    });
    
    return completionStatus;
  }

  public async getCompanyLinkRequest(id: string, companyId: string): Promise<LinkedAccountRequest> {

    let request: LinkedAccountRequest = null;

    let query: any = {
      "data.id": id,
      "data.companyId": companyId
    };  

    await this.realm.mongoGetOneAsForeign(query, "hive-admin", "employee-link-requests", "admin", companyId).then( async (requestData: any) => {
      console.log("Found Link Request", requestData);
      request = requestData?.data;
    });

    return request;
  }

  public async getCompanyOpenLinkRequests(companyId: string): Promise<Array<LinkedAccountRequest>> {

    let requests: Array<LinkedAccountRequest> = [];

    let query: any = {
      "data.companyId": companyId,
      "data.acceptedDate": null,
      "data.revoked": false
    };  

    await this.realm.mongoGet(query, "hive-admin", "employee-link-requests", "admin", companyId).then( async (requestData: Array<any>) => {
      console.log("Found Link Requests", requestData);

      for(let request of requestData) {
        requests.unshift(request.data);
      }

    });

    return requests;
  }

  public async getCompanyClosedLinkRequests(companyId: string): Promise<Array<LinkedAccountRequest>> {

    let requests: Array<LinkedAccountRequest> = [];

    let query: any = {
      "data.companyId": companyId,
      "data.revoked": true
    };  

    await this.realm.mongoGet(query, "hive-admin", "employee-link-requests", "admin", companyId).then( async (requestData: Array<any>) => {
      console.log("Found Link Requests", requestData);

      for(let request of requestData) {
        requests.unshift(request.data);
      }

    });

    return requests;
  }


   // Changes the company link request from the user's request array to its linked array
  public async approveCompanyLinkRequestByCurrentUser(companyId: string, requestId: string): Promise<boolean> {
    let completionStatus: boolean = false;
    let profile: Profile = this.getCurrentProfile();

    let query: any = {
      "data.id": profile.id
    };  

    if(profile.linked_account_requests == undefined || profile.linked_account_requests == null) {
      profile.linked_account_requests = [];
    }

    if(profile.linked_accounts == undefined || profile.linked_accounts == null) {
      profile.linked_accounts = [];
    }

    let linkedRequestIndex: number = profile.linked_account_requests.findIndex( (linkedRequest: LinkedAccountRequest) => {
      return linkedRequest.id == requestId;
    })

    if(linkedRequestIndex > -1) {
      profile.linked_account_requests.splice(linkedRequestIndex, 1);
    }

    profile.linked_accounts.push( {
      id: companyId,
      requestId: requestId,
      profileImg: "",
      jobTitle: ""
    });

    await this.approveCompanyLinkRequestTransaction(companyId, requestId).then( async (success: boolean) => { 

      if(success) {
        profile.last_update = new Date();

        await this.realm.mongoUpdateOneUser(query, "hive-admin","employees", profile, "admin", this.getCompanyId()).then( async (status: boolean) => {

          if(status) {
          
            completionStatus = status;

          }

        });

      }

    });
    
    return completionStatus;
  }

  // Adjusts the stored/governing record in which a user can have access to a company
  public async approveCompanyLinkRequestTransaction(companyId: string, requestId: string): Promise<boolean> {
    let completionStatus: boolean = false;

    let query: any = {
      "data.id": requestId,
      "data.companyId": companyId
    };  

    await this.realm.mongoGetOne(query, "hive-admin", "employee-link-requests", "admin", companyId).then( async (requestData: any) => {
      console.log("Found Link Request", requestData);
      

      if(requestData != undefined && requestData != null) {
        let request: LinkedAccountRequest = requestData.data
        request.acceptedDate = new Date();
      
        await this.realm.mongoUpdateOne(query, "hive-admin", "employee-link-requests", request, "admin", companyId, false).then( async (updateStatus: boolean) => {
          console.log("Updated Link Request", updateStatus);

          if(updateStatus) {
          
            completionStatus = updateStatus;
    
          }
    
        });

      }

    });

    
    return completionStatus;
  }

  // Adjusts the stored/governing record in which a user can have access to a company
  public async approveCompanyLinkRequestTransactionAsForeign(companyId: string, requestId: string): Promise<boolean> {
    let completionStatus: boolean = false;

    let query: any = {
      "data.id": requestId,
      "data.companyId": companyId
    };  

    await this.realm.mongoGetOneAsForeign(query, "hive-admin","employee-link-requests", "admin").then( async (request: LinkedAccountRequest) => {

      if(request != undefined && request != null) {

        request.acceptedDate = new Date();
      
        await this.realm.mongoUpdateAsForeign(query, "hive-admin","employee-link-requests", request, "admin", this.getCompanyId()).then( async (updateStatus: boolean) => {

          if(updateStatus) {
          
            completionStatus = updateStatus;
    
          }
    
        });

      }

    });

    
    return completionStatus;
  }

  // Adjusts the stored/governing record in which a user can have access to a company
  public async rejectCompanyLinkRequestTransaction(companyId: string, requestId: string): Promise<boolean> {
    let completionStatus: boolean = false;

    let query: any = {
      "data.id": requestId,
      "data.companyId": companyId
    };  

    await this.realm.mongoGetOne(query, "hive-admin","employee-link-requests", "admin", this.getCompanyId()).then( async (requestData: any) => {

      if(requestData != undefined && requestData != null) {
        let request: LinkedAccountRequest = requestData.data;

        request.revoked = true;
      
        await this.realm.mongoUpdateOne(query, "hive-admin","employee-link-requests", request, "admin", this.getCompanyId(), false).then( async (updateStatus: boolean) => {

          if(updateStatus) {
          
            completionStatus = updateStatus;
    
          }
    
        });

      }

    });

    
    return completionStatus;
  }

  public async removeCompanyId(userData: Profile, deleteCompanyId: string, adminAddedProfile?: boolean): Promise<boolean> {

    let query: any = { 
      "data.id": userData.id, 
      $or: [
        { "data.company_id": this.getCompanyId() },
        { "data.linked_accounts.id": this.getCompanyId() }
      ]
    };

    let status: boolean = false;
    
    let activeCompanyIdIndex: boolean = userData.company_id == deleteCompanyId;

    if(userData.linked_accounts != undefined && userData.linked_accounts != null && userData.linked_accounts.length > 0) {
      let companyIdIndex: number = userData.linked_accounts.findIndex( (linkedAccount: LinkedAccount) => { return linkedAccount.id == deleteCompanyId });

      if(companyIdIndex > -1) {
        this.rejectCompanyLinkRequestTransaction(this.getCompanyId(), userData.linked_accounts[companyIdIndex].requestId);
        userData.linked_accounts.splice(companyIdIndex, 1);
      }

    }

    if(activeCompanyIdIndex) {
      userData.company_id = "";
    }

    console.log("Delete User: ", userData);

    if(this.profileId == userData.id || adminAddedProfile)  {
      userData.last_update = new Date();

      await this.realm.mongoRemoveCompanyID(query, "hive-admin", "employees", userData, "admin").then( async successStatus => {

        status = successStatus;

      });
    } else {
      console.error("Incoming Profile ID does not match outgoing Profile ID!");
    }

    return status;
  }

  public async getPagedSearchProfiles(searchFilter: string, pageNumber: number, limit: number): Promise<Profile[]> {
    let filteredEmployees: Array<Profile> = [];

    let matchQuery: any;
    let query: Array<any> = [];
    let sortQuery: string = "data.first_name"

    let createdKey1: string = "data.first_name";
    let createdKey2: string = "data.last_name";

    query.push({[createdKey1]: { '$regex': searchFilter, '$options': 'i' }});
    query.push({[createdKey2]: { '$regex': searchFilter, '$options': 'i' }});


    matchQuery = 
    {
      $match: {
        "data.company_id": this.getCompanyId(),
        $or: query
      }
    };
  
    console.log("Search Query: ", query);    
  
    await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-admin", "employees", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered employees", data);
  
        if(data != undefined) {
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            filteredEmployees.push(data[curserIndex].data);
          }

        }
      }
    );
  
    return filteredEmployees;
  }

  public async getPagedDateRangeSearchEmployees(searchFilter: string, pageNumber: number, limit: number): Promise<Profile[]> {
    let filteredEmployees: Array<Profile> = [];

    let matchQuery: any;
    let query: Array<any> = [];
    let sortQuery: string = "data.first_name"

    let createdKey: string = "data.first_name";

    let startDate: Date = new Date();
    let endDate: Date = new Date();

    // Resetting Time
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(24, 0, 0, 0);

    startDate.setDate(startDate.getDate() - 30);

    console.log("Start Date: ", startDate.toLocaleDateString());
    console.log("End Date: ", endDate.toLocaleDateString());

    matchQuery = 
    {
      $match: {
        $and: [
          {"data.email_verified": { $gte: startDate } },
          {"data.email_verified": { $lte: endDate } },
          {[createdKey]: { '$regex': searchFilter, '$options': 'i' }}
        ],
      }
    };
  
    console.log("Search Query: ", query);    
  
    await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-admin", "employees", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered employees", data);
  
        if(data != undefined) {
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            filteredEmployees.push(data[curserIndex].data);
          }

        }
      }
    );
  
    return filteredEmployees;
  }

  public getProfile(): Observable<Profile> {

    let query: any = {
      "data.id": this.getProfileId(),
      $or: [
        { "data.company_id": this.getCompanyId() },
        { "data.linked_accounts.id": this.getCompanyId() }
      ],
      role: "admin"
    };

    // If the user isn't focused on a company then display their logged in account unassociated with a company
    if(this.getCompanyId() == null) {
      query = {
        "data.id": this.getProfileId(),
        role: "admin"
      };
    }

    let cursor = this.realm.mongoGetOne(query, "hive-admin","employees", "admin", this.getCompanyId()).then( async (profileData: any) => {
        console.log("Successfully found Profile! ", profileData);

        if(profileData != undefined) {
          let profile: Profile = profileData.data

          this.setCompanyId(profile.company_id);
          this.setLinkedAccounts(profile.linked_accounts);
          this.setProfileId(profile.id);

          this.setCurrentProfile(profile);

          await this.getAsyncCallCenterID();
          await this.updateUserPagePermissions();

          this.profileSubject.next(profile);
        }
      }
    );

    return this.profileSubject.asObservable();
  }

  public async getAsyncProfile(): Promise<Profile> {
    let profile: Profile = null;

    let query: any = {
      "data.id": this.getProfileId(),
      $or: [
        { "data.company_id": this.getCompanyId() },
        { "data.linked_accounts.id": this.getCompanyId() }
      ],
      role: "admin"
    };

    // If the user isn't focused on a company then display their logged in account unassociated with a company
    if(this.getCompanyId() == null) {
      query = {
        "data.id": this.getProfileId(),
        role: "admin"
      };
    }

    await this.realm.mongoGetOne(query, "hive-admin","employees", "admin", this.getCompanyId()).then( async (profileData: any) => {
        console.log("Successfully found Profile! ", profileData);
        

        if(profileData != undefined) {

          profile = profileData.data

          if(profile.id == this.getProfileId()) {
            this.setCompanyId(profileData.data.company_id);
            this.setLinkedAccounts(profileData.data.linked_accounts);
            this.setProfileId(profileData.data.id);
            this.setCurrentProfile(profile);

            await this.getAsyncCallCenterID();
            await this.updateUserPagePermissions();
          }

        }
      }
    );

    return profile;
  }

  public async getAsyncProfiles(ids: Array<string>): Promise<Array<Profile>> {
    let profiles: Array<Profile> = [];
    let profileSearch: Array<any> = [];

    for(let id of ids) {
      profileSearch.push(
        { "data.id": id }
      );
    }

    let query: any = {
      "data.company_id": this.getCompanyId(),
      $or: profileSearch,
      role: "admin"
    };

    await this.realm.mongoGet(query, "hive-admin","employees", "admin", this.getCompanyId()).then( async (profileData: any) => {
        console.log("Successfully found Profile! ", profileData);
        

        if(profileData != undefined) {

          for(let profile of profileData) {
            profiles.push(profile.data);
          }

        }

      }

    );

    return profiles;
  }

  public getLoggedInProfile(): Observable<Profile> {
    let cursor = this.realm.mongoGetLoggedInProfile({}, "hive-admin","employees", "admin", true).then(profileQuery => {
        console.log("Successfully found Profile! ", profileQuery);

        if(profileQuery != undefined) {
          profileQuery.data.online_status = 0;

          let profileId: string = profileQuery.data.id;
          let assignedCompany: string = profileQuery.data.company_id;

          this.setCompanyId(profileQuery.data.company_id);
          this.setLinkedAccounts(profileQuery.data.linked_accounts);
          this.setProfileId(profileQuery.data.id);
          this.setCurrentProfile(profileQuery.data);

          this.updateUserPagePermissions();

          this.getAsyncCallCenterID();
          this.getAccurateLocation();

          console.log("Updating New Current Logged In Profile Data: ", profileQuery.data);


          this.updateProfile(profileQuery.data).then( data => {

            this.getAsyncGeneralDataSync().then( (dataSync: GeneralDataSync) => {

              if(dataSync == null) {
                dataSync = {
                  categories: {
                    init_sync: new Date,
                    dispatch: new Date,
                    work_orders_open: new Date,
                    work_orders_closed: new Date,
                    invoice_open: new Date,
                    invoice_closed: new Date,
                    shift_schedule_published: new Date,
                    inventory: new Date,
                    inventory_work_order: new Date,
                    asset: new Date,
                    asset_work_order: new Date,
                    employee_management: new Date,
                    employees: new Date,
                    automation: new Date,
                    admin: new Date,
                    notification: new Date,
                    messaging: new Date,
                    phone_call: new Date,
                    text_message: new Date
                  }
                };
              }
      
              dataSync.categories.employees = new Date();
              this.updateGeneralDataSync(dataSync);
      
            });

          });

          this.profileSubject.next(profileQuery.data);

          if(
            assignedCompany != undefined && 
            assignedCompany != null &&
            assignedCompany.length > 0 &&
            assignedCompany != profileId) {
              this.router.navigate([this.startPageUrl]);
          } else {
            this.router.navigate([this.newlyRegisteredUserRoute]);
          } 

        }
      }
    );

    return this.profileSubject.asObservable();
  }

  public async getAsyncLoggedInProfile(): Promise<Profile> {
    let profile: Profile = null;

    await this.realm.mongoGetLoggedInProfile({}, "hive-admin","employees", "admin", true).then(async (profileQuery) => {
        console.log("Successfully found Profile! ", profileQuery);
        profile = profileQuery

        if(profileQuery != undefined) {
          profileQuery.data.online_status = 0;

          let profileId: string = profileQuery.data.id;
          let assignedCompany: string = profileQuery.data.company_id;

          this.setCompanyId(profileQuery.data.company_id);
          this.setLinkedAccounts(profileQuery.data.linked_accounts);
          this.setProfileId(profileQuery.data.id);
          this.setCurrentProfile(profileQuery.data);

          await this.updateUserPagePermissions();

          await this.getAsyncCallCenterID();
          this.getAccurateLocation();

          console.log("Updating New Current Logged In Profile Data: ", profileQuery.data);


          await this.updateProfile(profileQuery.data).then(async (data) => {

            await this.getAsyncGeneralDataSync().then(async (dataSync: GeneralDataSync) => {

              if(dataSync == null) {
                dataSync = {
                  categories: {
                    init_sync: new Date,
                    dispatch: new Date,
                    work_orders_open: new Date,
                    work_orders_closed: new Date,
                    invoice_open: new Date,
                    invoice_closed: new Date,
                    shift_schedule_published: new Date,
                    inventory: new Date,
                    inventory_work_order: new Date,
                    asset: new Date,
                    asset_work_order: new Date,
                    employee_management: new Date,
                    employees: new Date,
                    automation: new Date,
                    admin: new Date,
                    notification: new Date,
                    messaging: new Date,
                    phone_call: new Date,
                    text_message: new Date
                  }
                };
              }
      
              dataSync.categories.employees = new Date();
              this.updateGeneralDataSync(dataSync);
      
            });

          });

          this.profileSubject.next(profileQuery.data);

          if(
            assignedCompany != undefined && 
            assignedCompany != null &&
            assignedCompany.length > 0 &&
            assignedCompany != profileId) {
              this.router.navigate([this.startPageUrl]);
          } else {
            this.router.navigate([this.newlyRegisteredUserRoute]);
          } 

        }

      }

    );

    return profile;
  }

  public async switchCompanyProfile(companyId: string): Promise<boolean> {
    let completionStatus: boolean = false;

    let profile: Profile = this.getCurrentProfile();
    profile.company_id = companyId;

    this.setCompanyId(companyId);

    console.log("Switch Company Profile Data: ", profile);
    await this.updateOneProfile(profile).then(async (profileStatus: boolean) => {

      if(profileStatus) {
        await this.getAsyncProfile().then( async (updatedProfile: Profile) => {

          if(updatedProfile != undefined && updatedProfile != null) {
            
            await this.realm.mongoRefreshUserCustomData().then( async (status: boolean) => {

              if(status) {
                completionStatus = true;
                let self = this;

                setTimeout(async function() {
                  self.router.navigate(['/hive/professional/lock-screen']);
                  await self.getAsyncEmployeePagesPermissions(self.getProfileId()).then( permissions => {

                    setTimeout(function() {
                      self.router.navigate([self.startPageUrl]);
                      self.snackBar.open('Account Switch Completed!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
                    }, 500);

                  });
                  
                }, 500);

              }

            });
            
          } else {
            this.snackBar.open('Account Switch Failed!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });

            this.router.navigate(['/login']);
          } 

        });
      }
  
    });

    return completionStatus;
  }

  public async getAsyncCompanyProfile(companyId?: string): Promise<CompanyProfile> {
    let query: any = null;
    
    if(companyId != undefined && companyId != null && companyId.length > 0) {
      query = {
        "data.id": companyId,
        role: "admin"
      };
    } else {
      query = {
        "data.id": this.getCompanyId(),
        role: "admin"
      };
    }

    let companyProfile: CompanyProfile = null;

    await this.realm.mongoGetOne(query, "hive-admin","company-profiles", "admin", this.getCompanyId()).then(data => {

        if(data != undefined) {
          companyProfile = data.data;
        }
      }
    );

    return companyProfile;
  }

  public async getAsyncCompanyProfileAsForeign(companyId: string): Promise<CompanyProfile> {
    let query: any = null;

    query = {
      "data.id": companyId,
      role: "admin"
    };

    let companyProfile: CompanyProfile = null;

    await this.realm.mongoGetOneAsForeign(query, "hive-admin","company-profiles", "admin", companyId).then(data => {

        if(data != undefined) {
          companyProfile = data.data;
        }

      }
    );

    return companyProfile;
  }

  public async getCompanyProfile(companyId: string): Promise<CompanyProfile> {
    let query: any = {
      "data.id": companyId,
      role: "admin"
    };

    // if(companyId != undefined && companyId != null && companyId.length > 0) {
    //   query["data.id"] = companyId;
    // }

    let companyProfile: CompanyProfile = null;

    await this.realm.mongoGetOne(query, "hive-admin","company-profiles", "admin", this.getCompanyId()).then(data => {

        if(data != undefined) {
          companyProfile = data.data;

          if(this.profile.company_id == companyProfile.id) {
            this.setCompanyName(data.data.company_name);
            this.setCompanyId(data.company_id);
            this.setLinkedAccounts(data.linked_accounts);
            this.setCompanyLogo(companyProfile.image);
          }

          console.log("Company Profile: ", companyProfile);
        }
      }
    );

    return companyProfile;
  }

  public async updateCompanyProfile(companyProfile: CompanyProfile): Promise<boolean> {
    let status: boolean = false;

    let query: any = {
      "data.id": this.getCompanyId(),
      role: "admin"
    };

    this.setCompanyName(companyProfile.company_name);

    await this.realm.mongoUpsertOne(query, "hive-admin", "company-profiles", companyProfile, "admin", this.getCompanyId(), false).then( (completionStatus: boolean) => {

      status = completionStatus;

      if(status) {

        if(companyProfile.id == this.getCompanyId()) {
          this.setCompanyLogo(companyProfile.image);
        }

      }

    });

    return status;
  }


// ============== End PROFILE ==============

// ===========================================
// GROUP PERMISSIONS
// ===========================================

public getPagePermissionsTemplate(): Array<Page> {
  return this.pagePermissionsTemplate;
}

public getUserPagePermissions(): Array<Page> {
  return this.userPagePermissions;
}

public getUserPageFilteredPermissions(pageId: number): Page {
  let pagePermissions: Page = null;
  let pageIndex =  this.userPagePermissions.findIndex( (element: Page) => { return element.id == pageId } );

  if(pageIndex > -1) {
    pagePermissions = this.userPagePermissions[pageIndex];
  }

  return pagePermissions;
}

public setUserPagePermissions(pagePermissions: Array<Page>): void {
  this.userPagePermissions = pagePermissions;
}

public async updateUserPagePermissions(): Promise<void> {
  let loggedInUserId: string = this.getProfileId();

  let pagePermissions: Array<Page> = [];

  await this.getAsyncEmployeePagesPermissions(loggedInUserId).then( (permissions: Array<Page>) => {
    pagePermissions = permissions;

    this.setUserPagePermissions(pagePermissions);
  });

}

public async getEmployeePermissions(employee: string): Promise<any> {
  this.getAsyncEmployeeGroupPermissions(employee).then( (groups: Array<GroupPermissions>) => {

  });
}

public async getGroupPermission(id: string): Promise<GroupPermissions> {

  let group: GroupPermissions = null;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-admin", "groups", "admin", this.getCompanyId()).then(groups => {
    console.log("Successfully found Group Permission! ", groups);

    if(groups != undefined) {

      group = groups.data.find( (team: GroupPermissions) => {return team.id == id } );

    }

  });

  return group;
}

public async getGroupPermissionAsForeign(id: string, companyId: string): Promise<GroupPermissions> {

  let group: GroupPermissions = null;

  let query: any = {
    company_id: companyId,
    role: "admin"
  };

  await this.realm.mongoGetOneAsForeign(query, "hive-admin", "groups", "admin", companyId).then(groups => {
    console.log("Successfully found Group Permission! ", groups);

    if(groups != undefined) {

      group = groups.data.find( (team: GroupPermissions) => {return team.id == id } );

    }

  });

  return group;
}

public getGroupPermissions(): Observable<GroupPermissions[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  this.realm.mongoGet(query, "hive-admin", "groups", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Group Permissions! ", data);

    if(data != undefined) {
      let returnData: Array<any> = [];

      for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
        returnData.push(data[curserIndex].data);
      }

      if(returnData.length == 0) {
        let groupPermissions: Array<GroupPermissions> = this.syncPagePermissionsToTemplate(returnData);
        this.groupPermissionSubject.next(groupPermissions);
      } else {
        let groupPermissions: Array<GroupPermissions> = this.syncPagePermissionsToTemplate(returnData[0]);
        this.groupPermissionSubject.next(groupPermissions);
      }
    }
  }
);

  return this.groupPermissionSubject.asObservable();
}

public syncPagePermissionsToTemplate(groupPermissions: Array<GroupPermissions>): Array<GroupPermissions> {
  let pagePermissionsTemplate: Array<Page> = this.getPagePermissionsTemplate();

  let pageIndex: number = -1;
  let sectionIndex: number = -1;

  for(let group of groupPermissions) {

    // Checking to see if all template pages exist in saved page permissions
    for(let pageTemplate of pagePermissionsTemplate) {

      pageIndex = group.pages.findIndex( (element: Page) => { return element.id == pageTemplate.id });

      if(pageIndex < 0) {
        group.pages.push(pageTemplate);
      }

    }

    for(let page of group.pages) {

      pageIndex = pagePermissionsTemplate.findIndex( (element: Page) => { return element.id == page.id });
      
      // Checking to see if all template pages exist in saved page permissions
      for(let sectionPageTemplate of pagePermissionsTemplate[pageIndex].permission.sections) {

        sectionIndex = page.permission.sections.findIndex( (element: PageSection) => { return element.id == sectionPageTemplate.id });

        if(sectionIndex < 0) {
          page.permission.sections.push(sectionPageTemplate);
        }

        // for(let templatePageSection of pageTemplate.permission.sections) {
        //   sectionIndex = page.permission.sections.findIndex( (element: PageSection) => { return element.id == templatePageSection.id });

        //   if(sectionIndex < 0) {
        //     page.permission.sections.push(templatePageSection);
        //   }
        // }

      }

    }

  }

  return groupPermissions;
}

public async getAsyncGroupPermissions(): Promise<GroupPermissions[]> {
  let permissions: Array<GroupPermissions> = [];

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-admin", "groups", "admin", this.getCompanyId()).then(data => {
    console.log("Successfully found Group Permissions! ", data);

    if(data != undefined) {

      let groups: Array<GroupPermissions> = [];
      
      for(let group of data.data) {
        groups.push(group);
      }
      
      permissions = this.syncPagePermissionsToTemplate(groups);

    }
  });

  return permissions;
}

public async getAsyncGroups(): Promise<Array<GroupPermissions>> {
  let permissions: Array<GroupPermissions> = [];

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-admin", "groups", "admin", this.getCompanyId()).then(data => {
    console.log("Successfully found Group Permissions! ", data);

    if(data != undefined) {
      let groups: Array<GroupPermissions> = [];
      
      for(let group of data.data) {
        groups.push(group);
      }

       permissions = this.syncPagePermissionsToTemplate(groups);

    }
  });

  return permissions;
}

// Returns all of the groups the employee is in
public async getAsyncEmployeeGroupPermissions(employeeId: string): Promise<GroupPermissions[]> {
  let permissions: Array<GroupPermissions> = [];

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "hive-admin", "groups", "admin", this.getCompanyId(), false).then( data => {
    console.log("Successfully found Group Permissions! ", data);

    if(data != undefined && data.length > 0) {
      let groups: Array<GroupPermissions> = this.syncPagePermissionsToTemplate(data[0].data);
      
      for(let group of groups) {

        let employeeIdIndex: number = group.employeeIds.findIndex( element => { return element == employeeId } );

        if(employeeIdIndex > -1) {
          permissions.push(group);
        }
        
      }

    }

  });

  return permissions;
}

// Returns an Array of Page objects of all permissions the employee has with no relavance to a specific group
public async getAsyncEmployeePagesPermissions(employeeId: string): Promise<Page[]> {
  let permissions: Array<Page> = JSON.parse( JSON.stringify(this.getPagePermissionsTemplate()) );

  let pageIndex: number = 0;
  let sectionIndex: number = 0;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "hive-admin", "groups", "admin", this.getCompanyId(), false).then( data => {
    console.log("Successfully found Group Permissions! ", data);

    if(data != undefined && data.length > 0) {
      let groups: Array<GroupPermissions> = this.syncPagePermissionsToTemplate(data[0].data);
      
      for(let group of groups) {

        let employeeIdIndex: number = group.employeeIds.findIndex( element => { return element == employeeId } );

        if(employeeIdIndex > -1 || group.id == 'locked-all') {
          
          for(let page of group.pages) {
            pageIndex = permissions.findIndex( (element: Page) => { return element.id == page.id });

            if(page.permission.pageAccess) {
              permissions[pageIndex].permission.pageAccess = page.permission.pageAccess;
            }

            for(let section of page.permission.sections) {

              if(section.id == undefined || section.id == null) {
                continue;
              }

              sectionIndex = permissions[pageIndex].permission.sections.findIndex( (element: PageSection) => { 

                if(element.id == undefined || element.id == null) {
                  return;
                }

                return element.id == section.id 
              });

              if(section.permissions.create) {
                permissions[pageIndex].permission.sections[sectionIndex].permissions.create = section.permissions.create;
              }

              if(section.permissions.read) {
                permissions[pageIndex].permission.sections[sectionIndex].permissions.read = section.permissions.read;
              }

              if(section.permissions.update) {
                permissions[pageIndex].permission.sections[sectionIndex].permissions.update = section.permissions.update;
              }

              if(section.permissions.delete) {
                permissions[pageIndex].permission.sections[sectionIndex].permissions.delete = section.permissions.delete;
              }

              
            }

          }

        }

        if(employeeIdIndex > -1 && group.id == 'locked-all') {
          group.employeeIds.push(employeeId);
        }
        
      }

    }

  });

  this.setUserPagePermissions(permissions);

  return permissions;
}

// Returns a single Page object of all permissions the employee has with no relavance to a specific group
public async getAsyncEmployeePagePermissions(employeeId: string, pageId: number): Promise<Page[]> {
  let permissions: Array<Page> = JSON.parse( JSON.stringify(this.getPagePermissionsTemplate()) );

  let page: Page = null;
  let employeeIdIndex: number = 0;
  let pageIndex: number = 0;
  let sectionIndex: number = 0;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "hive-admin", "groups", "admin", this.getCompanyId(), false).then( data => {
    console.log("Successfully found Group Permissions! ", data);

    if(data != undefined && data.length > 0) {
      let groups: Array<GroupPermissions> = this.syncPagePermissionsToTemplate(data[0].data);
      
      for(let group of groups) {
        pageIndex = group.pages.findIndex( (element: Page) => { return element.id == pageId } );

        employeeIdIndex = group.employeeIds.findIndex( element => { return element == employeeId } );

        if(employeeIdIndex > -1 && pageIndex > -1) {

          page = group.pages[pageIndex];
          
          if(page != null) {

            if(page.permission.pageAccess) {
              permissions[pageIndex].permission.pageAccess = page.permission.pageAccess;
            }

            for(let section of page.permission.sections) {
              sectionIndex = permissions[pageIndex].permission.sections.findIndex( (element: PageSection) => { return element.id == section.id });

              if(section.permissions.create) {
                permissions[pageIndex].permission.sections[sectionIndex].permissions.create = section.permissions.create;
              }

              if(section.permissions.read) {
                permissions[pageIndex].permission.sections[sectionIndex].permissions.read = section.permissions.read;
              }

              if(section.permissions.update) {
                permissions[pageIndex].permission.sections[sectionIndex].permissions.update = section.permissions.update;
              }

              if(section.permissions.delete) {
                permissions[pageIndex].permission.sections[sectionIndex].permissions.delete = section.permissions.delete;
              }

            }

          }

        }
        
        
      }

    }

  });

  return permissions;
}


public async updateGroupPermissions(groupPermissions: Array<GroupPermissions>): Promise<boolean> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  let completionStatus: boolean = false;

  await this.realm.mongoUpsertOne(query, "hive-admin", "groups", groupPermissions, "admin", this.getCompanyId(), false).then( status => {
    this.updateUserPagePermissions();

    completionStatus = status;
  });

  return completionStatus;
}

// ============== End GROUP PERMISSIONS ==============

// ===========================================
// EMPLOYEES
// ===========================================

public updateEmployee(profileData: Profile) {
  let query: any = {
    $or: [
      { "data.company_id": this.getCompanyId() },
      { "data.linked_accounts.id": this.getCompanyId() }
    ]
  };

  // If the user isn't focused on a company then display their logged in account unassociated with a company
  if(this.getCompanyId() == null) {
    query = {
      "data.id": this.getProfileId(),
      role: "admin"
    };
  };

  profileData.last_update = new Date();

  if(this.profileId == profileData.id)  {
    this.realm.mongoUpsertOne(query, "hive-admin","employees", profileData, "admin", this.getCompanyId(), false);
  } else {
    console.error("Incoming Profile ID does not match outgoing Profile ID!");
  }
}

public async getEmployee(profileId: string): Promise<Profile> {
  let returnedData: Profile = null;
  let query: any = {
    "data.id": profileId,
    $or: [
      { "data.company_id": this.getCompanyId() },
      { "data.linked_accounts.id": this.getCompanyId() }
    ],
    role: "admin"
  };

  // If the user isn't focused on a company then display their logged in account unassociated with a company
  if(this.getCompanyId() == null) {
    query = {
      "data.id": this.getProfileId(),
      role: "admin"
    };
  }

  await this.realm.mongoGetOne(query, "hive-admin","employees", "admin", this.getCompanyId()).then(data => {
      console.log("Successfully found Employee Profile! ", data);

      if(data != null && data != undefined) {
        returnedData = data.data;
      }
    }
  );

  return returnedData;
}

public async getAsyncEmployeeById(profileId: string): Promise<Profile> {
  let profile: Profile = null;
  let query: any = {
    "data.id": profileId,
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-admin","employees", "admin", this.getCompanyId()).then(data => {
      console.log("Successfully found Profile! ", data);

      if(data != null && data != undefined) {
        profile = data.data;
      }
    }
  );

  return profile;
}

public async getAsyncEmployeeByIdAsForeign(profileId: string, companyId: string): Promise<Profile> {
  let profile: Profile = null;
  let query: any = {
    "data.id": profileId,
    role: "admin"
  };

  await this.realm.mongoGetOneAsForeign(query, "hive-admin","employees", "admin", companyId).then(data => {
      console.log("Successfully found Profile! ", data);

      if(data != null && data != undefined) {
        profile = data.data;
      }
    }
  );

  return profile;
}

public async getAsyncEmployeeByEmail(email: string): Promise<Profile> {
  let profile: Profile = null;
  let query: any = {
    "data.email": email,
    role: "admin"
  };

  await this.realm.mongoGetOne(query, "hive-admin","employees", "admin", this.getCompanyId()).then(data => {
      console.log("Successfully found Profile! ", data);

      if(data != null && data != undefined) {
        profile = data.data;
      }

    }
  );

  return profile;
}

public async getAsyncEmployeeByEmailAsForiegn(email: string): Promise<Profile> {
  let profile: Profile = null;
  let query: any = {
    "data.email": email,
    role: "admin"
  };

  await this.realm.mongoGetOneAsForeign(query, "hive-admin","employees").then(data => {
      console.log("Successfully found Profile! ", data);

      if(data != null && data != undefined) {
        profile = data.data;
      }
      
    }
  );

  return profile;
}

// Testing - Needs to be wired correctly to make it dynamic
public getQueriedEmployees(): Observable<Profile> {
  let query: any = {
    "data.id": this.getProfileId(),
    $or: [
      { "data.company_id": this.getCompanyId() },
      { "data.linked_accounts.id": this.getCompanyId() }
    ],
    role: "admin",
    name: "josh"
  };

  let cursor = this.realm.mongoGetOne(query, "hive-admin","employees", "admin", this.getCompanyId()).then(data => {
      console.log("Successfully found Profile! ", data);

      if(data != undefined) {
        this.setCompanyId(data.company_id);
        this.setLinkedAccounts(data.linked_accounts);
        this.setProfileId(data.data.id);
        // this.profileSubject.next(data.data);
      }
    }
  );

  return this.profileSubject.asObservable();;
}

public async getAsyncAllEmployees(): Promise<Profile[]> {
  let returnData: Array<any> = [];

  let query: any = {
    $or: [
      { "data.company_id": this.getCompanyId() },
      { "data.linked_accounts.id": this.getCompanyId() }
    ],
    role: "admin"
  };

  await this.realm.mongoGet(query, "hive-admin","employees", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully found Profiles! ", data);

      if(data != undefined) {
        

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

      }
    }
  );

  return returnData;
}

public getAllEmployees(): Observable<Profile[]> {
  let query: any = {
    $or: [
        {"data.company_id": this.getCompanyId()},

        {
          $and: [
            {"data.linked_accounts": {$exists: true} },
            {"data.linked_accounts.id": this.getCompanyId()}
          ]
        }
    ],
    role: "admin"
  };

  let cursor = this.realm.mongoGet(query, "hive-admin","employees", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully found Profiles! ", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

        this.profilesSubject.next(returnData);
      }
    }
  );

  return this.profilesSubject.asObservable();
}

// ============== End EMPLOYEES ==============

// ===========================================
// KEY
// ===========================================

private generateNewKey(keyLength: number = 12): string {
  let maxKeyLength: number = 8;

  let min = Math.pow(16, Math.min(keyLength, maxKeyLength) - 1);
  let max = Math.pow(16, Math.min(keyLength, maxKeyLength)) - 1;
  let n   = Math.floor(Math.random() * (max-min + 1) ) + min;
  let key   = n.toString(16);

  while (key.length < keyLength) {
    key = key + this.generateNewKey( keyLength - maxKeyLength );
  }

  return key;
}

private generateNewNumericalKey(keyLength: number = 12): string {
  let maxKeyLength: number = 8;

  let min = Math.pow(9, Math.min(keyLength, maxKeyLength) - 1);
  let max = Math.pow(9, Math.min(keyLength, maxKeyLength)) - 1;
  let n   = Math.floor(Math.random() * (max-min + 1) ) + min;
  let key   = n.toString(9);

  while (key.length < keyLength) {
    key = key + this.generateNewNumericalKey( keyLength - maxKeyLength );
  }

  return key;
}

private duplicateKeyChecker(key: string, keyArray: Array<any>): boolean {
  let object: any = 
  
  keyArray.find(function(foundKey: string) {
    return foundKey === key;
  });
  
  if(object) { return true }

  return false;
}

public generateKey(keyType: number = 999): string {
  let newKey: string = "";

  switch(keyType) {
    case this.keyTypes.COMPANY_ID:
      newKey = this.generateNewKey(36);

      // This isn't possible due to security issues. Checker needs to be put on the server
      // while(this.duplicateKeyChecker(newKey, this.companyIDKeys)) {
      //   console.log("Found Duplicate!");
      //   newKey = this.generateNewKey(24);
      // }

      // if(newKey.length > 0) {
      //   this.companyIDKeys.push(newKey);
      // }
      break;

    case this.keyTypes.PROFILE_ID:
      newKey = this.generateNewKey(24);
      break;

    case this.keyTypes.FORM_ID:
      newKey = this.generateNewKey(6);
      break;

    case this.keyTypes.WORK_ORDER_ID:
      newKey = this.generateNewKey(36);
      break;

    case this.keyTypes.SHIFT_ID:
      newKey = this.generateNewKey(12);
      break;

    case this.keyTypes.FIELD_ID:
      newKey = this.generateNewKey(12);
      break;    
    
    case this.keyTypes.SHIFT_SWAP_REQUEST_ID:
      newKey = this.generateNewKey(12);
      break;   
    
    case this.keyTypes.MESSAGE_ID:
      newKey = this.generateNewKey(24);
      break;
    
    case this.keyTypes.IMAGE_ID:
      newKey = this.generateNewKey(24);
      break;

    case this.keyTypes.AVATAR_ID:
      newKey = this.generateNewKey(24);
      break;  

    case this.keyTypes.ASSET_ID:
      newKey = this.generateNewKey(24);

      break;  

    case this.keyTypes.INVENTORY_ID:
      newKey = this.generateNewKey(24);
      break;  

    case this.keyTypes.BARCODE_UPC_A:
      newKey = this.generateNewNumericalKey(12);
      break;  

    case this.keyTypes.BARCODE_UPC_E:
      newKey = this.generateNewNumericalKey(6);
      break;  

    case this.keyTypes.GROUP_ID:
      newKey = this.generateNewKey(6);
      break; 

    case this.keyTypes.CMMS_LOCATION_ID:
      newKey = this.generateNewKey(6);
      break; 

    case this.keyTypes.CMMS_RESERVATION_ID:
      newKey = this.generateNewKey(24);
      break; 

    case this.keyTypes.INVOICE_CUSTOM_CHARGE_FIELD:
      newKey = this.generateNewKey(4);
      break; 

    case this.keyTypes.NOTIFICATION_ID:
      newKey = this.generateNewKey(6);
      break; 

    case this.keyTypes.PURCHASE_ORDER_ID:
      newKey = this.generateNewKey(24);
      break; 

    case this.keyTypes.PURCHASE_ORDER_NEW_PART_ID:
      newKey = this.generateNewKey(24);
      break; 

    case this.keyTypes.LINKED_ACCOUNT_REQUEST_ID:
      newKey = this.generateNewKey(12);
      break;

    case this.keyTypes.AUDIT_LOG_ID:
      newKey = this.generateNewKey(5);
      break;

    case this.keyTypes.TASK_LIST:
      newKey = this.generateNewKey(6);
      break;

    case this.keyTypes.AUTOMATION:
      newKey = this.generateNewKey(6);
      break;

    case this.keyTypes.TIME_CHART:
      newKey = this.generateNewKey(36);
      break;

    case this.keyTypes.CONTACT:
      newKey = this.generateNewKey(24);
      break;

    case this.keyTypes.CRM_PIPELINE:
      newKey = this.generateNewKey(5);
      break;

    case this.keyTypes.CRM_PIPELINE:
      newKey = this.generateNewKey(5);
      break;

    case this.keyTypes.SIGNATURE_SECURITY_CODE:
      newKey = this.generateNewKey(7);
      break;

    case this.keyTypes.WORK_ORDER_ACCESS_CODE:
      newKey = this.generateNewKey(7);
      break;

    case this.keyTypes.COMPANY_THEME_ID:
      newKey = this.generateNewKey(7);
      break;

    default:
      newKey = this.generateNewKey(36);
      break;
  }

  return newKey;
}

// ============== End KEY ==============

// ===========================================
// Weather
// ===========================================

public getCurrentWeather(): Observable<any> {
  let self = this;

  navigator.geolocation.getCurrentPosition( function( response ) { 
    self.translateLocation(response, self) 
  } );
  
  return this.weatherSubject.asObservable();
}

private translateLocation(response, self): void {
  let latLng: LatLng = {
    lat: response.coords.latitude,
    lng: response.coords.longitude
  };

  if(latLng) {
    response = this.http.get<any> (
      'https://api.openweathermap.org/data/2.5/onecall?lat=' + latLng.lat + '&lon=' + latLng.lng + '&exclude=minutely,hourly,daily,alerts&units=imperial&appid=' + self.weatherApiKey
      ).subscribe(data => {

        if(data != null && data != undefined) {
          this.weatherSubject.next(data);
          console.log("Weather: ", data);
        }
    });
  }
}

// ============== End Weather ==============

// ===========================================
// GEO
// ===========================================
private getCloudflareIPInfo(): Promise<any> {
  return this.http.get('https://www.cloudflare.com/cdn-cgi/trace', {responseType: 'text'}).toPromise();
}

public async getIP(): Promise<void> {
  let IP_data = null;

  await this.getCloudflareIPInfo().then( (data: any) => {

    if(data != undefined && data != null) {
      // Convert key-value pairs to JSON
      // https://stackoverflow.com/a/39284735/452587
      data = data.trim().split('\n').reduce(function(obj, pair) {
        pair = pair.split('=');
        return obj[pair[0]] = pair[1], obj;
      }, {});

      IP_data = data;
      console.log("IP After Data (Timesheet): ", data);
    }

  });

  return IP_data;
}

public getAccurateLocation(): void {
  if (navigator.geolocation) {
    let self = this;

    navigator.geolocation.getCurrentPosition( function(position) {

      let latLng = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
    
      self.profile.geoLocation = latLng;

      console.log("Accurate GEO: ", self.profile);
      self.updateProfile(self.profile);
    });

  }
}

public testAccuracy(position): void {
  console.log("GEO: ", this.profile);

  this.updateProfile(this.profile);
}

public async getGeoLocationAsForeign(): Promise<LatLng> {
  let latLng: LatLng = {
    lat: null,
    lng: null
  };

  const pos: any = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: false });
  });

  latLng = {
    lat: pos.coords.latitude,
    lng: pos.coords.longitude
  };

  console.log("GEO: ", this.profile);
  return latLng;
}

public async getGeoLocation(): Promise<LatLng> {
  let latLng: LatLng = {
    lat: null,
    lng: null
  };

  const pos: any = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: false });
  });

  latLng = {
    lat: pos.coords.latitude,
    lng: pos.coords.longitude
  };

  this.profile.geoLocation = latLng;

  console.log("GEO: ", this.profile);

  this.updateProfile(this.profile);

  return latLng;
}

public async asyncAddressToLatLong(address: Address): Promise<LatLng> {
  let latLng: LatLng = null;
  let response: any =  null;

  if(address) {
    // Address needs to be parsed and turned into a chained string with no spaces
    let uriAddress: string = address.street + "," + address.city + "," + address.state + "," + address.zip;
    let encodedAddress: string = uriAddress.split(' ').join('+');

    response = await this.http.get<any> (
      'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodedAddress + ',+CA&key=' + this.geoApiKey
      ).toPromise();

      if(response != null && response != undefined) {

        if(response.results.length > 0) {
          latLng = {
            lat: response.results[0].geometry.location.lat,
            lng: response.results[0].geometry.location.lng
          }
        }
      }
    }

    console.log("Address: ", address);
    console.log("Lat & Lng: ", latLng);
    
    return latLng;
}

public addressToLatLong(address: Address): Observable<LatLng> {

  if(address) {
    // Address needs to be parsed and turned into a chained string with no spaces
    let uriAddress: string = address.street + "," + address.city + "," + address.state + "," + address.zip;
    let encodedAddress: string = uriAddress.split(' ').join('+');

    this.http.get<any>(
      'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodedAddress + ',+CA&key=' + this.geoApiKey
      ).subscribe(data => {

        if(data) {
          let latLng: LatLng = {
            lat: data.results[0].geometry.location.lat,
            lng: data.results[0].geometry.location.lng
          }

          this.addressToLatLngSubject.next(latLng);
        }
    });
  }

  return this.addressToLatLngSubject.asObservable();
}

public updateServiceArea(serviceArea: Array<LatLng>): void {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  this.realm.mongoUpsertOne(query, "hive-admin", "service-area", serviceArea, "admin", this.getCompanyId(), false);
}

public getServiceArea(): Observable<LatLng[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  let cursor = this.realm.mongoGetWorkOrders(query, "hive-admin","service-area", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Service Area! ", data);

    if(data != undefined && data.length > 0) {
      let returnData: Array<any> = [];

      // for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
      //   returnData.push(data[curserIndex].data);
      // }

      returnData = data[0].data;

      this.serviceAreaSubject.next(data[0].data);
    }
  }
);

  return this.serviceAreaSubject.asObservable();
}

// ============== End GEO ==============


// ===========================================
// Company Timesheets
// ===========================================

public async getAsyncCompanyTimeChart(date_start: Date, date_end: Date, page: number, limit: number): Promise<CompanyTimeChart> {
  let companyTimesheets: CompanyTimeChart = null;

  let sortQuery = "data.date"

  // date_start.setDate(date_start.getDate() - 1);
  // date_end.setDate(date_end.getDate() + 1);

  // Resetting Time
  date_start.setHours(0, 0, 0, 0);
  date_end.setHours(24, 0, 0, 0);

  console.log("Start Date: ", date_start);
  console.log("Start Date: ", date_end);

  let matchQuery = 
  {
    $match: {
      $and: [
        { "data.date": { $gte: date_start } },
        { "data.date": { $lte: date_end } },
        { "data.company_id": this.getCompanyId() }
      ],
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "employee-time-tracker", "company-timesheets", "admin", this.getCompanyId(), page, limit).then( (foundTimesheet) => {
    console.log("Successfully found Timesheets! ", foundTimesheet);

    if(foundTimesheet != undefined) {

        companyTimesheets = foundTimesheet.data;
    
    }

  });


  return companyTimesheets;
}

public async getAsyncDailyCompanyTimeChart(): Promise<CompanyTimeChart> {
  let companyTimesheets: CompanyTimeChart = null;


  let date_start: Date = new Date();
  let date_end: Date = new Date();

  let page = 0;
  let limit = 100000;

  let sortQuery = "data.date"

  // date_start.setDate(date_start.getDate() - 1);
  // date_end.setDate(date_end.getDate() + 1);

  // Resetting Time
  date_start.setHours(0, 0, 0, 0);
  date_end.setHours(24, 0, 0, 0);

  console.log("Start Date: ", date_start);
  console.log("Start Date: ", date_end);

  let matchQuery = 
  {
    $match: {
      $and: [
        { "data.date": { $gte: date_start } },
        { "data.date": { $lte: date_end } },
        { "data.company_id": this.getCompanyId() }
      ],
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "employee-time-tracker", "company-timesheets", "admin", this.getCompanyId(), page, limit).then( (foundTimesheet) => {
    console.log("Successfully found Timesheets! ", foundTimesheet);

    if(foundTimesheet != undefined && foundTimesheet.length > 0) {

        companyTimesheets = foundTimesheet[0].data;
    
    }

  });


  return companyTimesheets;
}

public async upsertDailyCompanyTimeChart(companyTimeChart: CompanyTimeChart): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    "data.chart_id": companyTimeChart.chart_id,
    "data.company_id": this.getCompanyId()
  };

  await this.realm.mongoUpsertOne(query, "employee-time-tracker", "company-timesheets", companyTimeChart, "admin", this.getCompanyId(), false).then( (completionStatus: boolean) => {
    
    status = completionStatus;

  });

  return status;
}

// ===========================================
// CMMS Part Orders
// ===========================================

public getPagedDateRangeSearchOpenPartOrders(startDate: Date, endDate: Date, searchFilter: string, pageNumber: number, limit: number): Observable<WorkOrder[]> {

  let matchQuery: any;
  let query: Array<any> = [];
  let employeeQuery: Array<any> = [];
  let sortQuery: string = "data.origination_date"

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let partRequest: CMMSPartRequest = {
    id: null,
    due_date: null,
    origination_date: null,
    requisitioner: null,
    active: null,
    status: null,
    approval_status: null,

    vendor_id: null,
    company_title: null,
    parts: [
      {
        id: null,
        isNew: null,
        title: null,
        price: null,
        unit: null,
        model: null,
        desc: null,
        quantity: null,
        links: null
      }
    ],
    work_order: null,
    additional_details: null,
    subtotal: null,
    sales_tax: null,
    shipping_cost: null,
    other_cost: null,
    total: null,
    priority: null,

    street: null,
    city: null,
    state: null,
    zip: null,

    billing_street: null,
    billing_city: null,
    billing_state: null,
    billing_zip: null
  }

  // Top Level
  Object.keys(partRequest).forEach(key => {
    let createdKey: string = "data." + key;

    query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  });

  // Part Level
  Object.keys(partRequest.parts).forEach(key => {
    let createdKey: string = "data." + key;

    query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  });

  //Custom Fields
  // Object.keys(customFields).forEach(key => {
  //   let createdKey: string = "data.custom_fields." + key;

  //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  // });

  if(searchFilter.length > 0) {
    matchQuery = 
    {
      $match : {
        company_id: this.getCompanyId(),
        role: "admin",
        "data.active": true,
        $and: [
          {
            $or: [
              {
                $and: [
                  {"data.origination_date": { $gte: startDate } },
                  {"data.origination_date": { $lte: endDate } }
                ]
              },
              {
                $and: [
                  {"data.due_date": { $gte: startDate } },
                  {"data.due_date": { $lte: endDate } }
                ]
              }
            ]
          }
        ],
        $or: query
      }
    };
  } else {
    matchQuery = 
    {
      $match : {
        company_id: this.getCompanyId(),
        role: "admin",
        "data.active": true,
        $and: [
          {
            $or: [
              {
                $and: [
                  {"data.origination_date": { $gte: startDate } },
                  {"data.origination_date": { $lte: endDate } }
                ]
              },
              {
                $and: [
                  {"data.due_date": { $gte: startDate } },
                  {"data.due_date": { $lte: endDate } }
                ]
              }
            ]
          }
        ]
      }
    };
  }

  console.log("Search Query: ", query);    

  let cursor = this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-cmms","part-requests", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
      console.log("Successfully pulled filtered part requests", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        // this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

        this.filteredPagedOpenPartRequestsSubject.next(returnData);
      }
    }
  );

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return this.filteredPagedOpenPartRequestsSubject.asObservable();
}

public getPagedDateRangeSearchClosedPartOrders(startDate: Date, endDate: Date, searchFilter: string, pageNumber: number, limit: number): Observable<WorkOrder[]> {

  let matchQuery: any;
  let query: Array<any> = [];
  let employeeQuery: Array<any> = [];
  let sortQuery: string = "data.origination_date"

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let partRequest: CMMSPartRequest = {
    id: null,
    due_date: null,
    origination_date: null,
    requisitioner: null,
    active: null,
    status: null,
    approval_status: null,

    vendor_id: null,
    company_title: null,
    parts: [
      {
        id: null,
        isNew: null,
        title: null,
        price: null,
        unit: null,
        model: null,
        desc: null,
        quantity: null,
        links: null
      }
    ],
    work_order: null,
    additional_details: null,
    subtotal: null,
    sales_tax: null,
    shipping_cost: null,
    other_cost: null,
    total: null,
    priority: null,

    street: null,
    city: null,
    state: null,
    zip: null,

    billing_street: null,
    billing_city: null,
    billing_state: null,
    billing_zip: null
  }

  // Top Level
  Object.keys(partRequest).forEach(key => {
    let createdKey: string = "data." + key;

    query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  });

  // Part Level
  Object.keys(partRequest.parts).forEach(key => {
    let createdKey: string = "data." + key;

    query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  });

  //Custom Fields
  // Object.keys(customFields).forEach(key => {
  //   let createdKey: string = "data.custom_fields." + key;

  //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  // });

  if(searchFilter.length > 0) {
    matchQuery = 
    {
      $match : {
        company_id: this.getCompanyId(),
        role: "admin",
        "data.active": false,
        $and: [
          {
            $or: [
              {
                $and: [
                  {"data.origination_date": { $gte: startDate } },
                  {"data.origination_date": { $lte: endDate } }
                ]
              },
              {
                $and: [
                  {"data.due_date": { $gte: startDate } },
                  {"data.due_date": { $lte: endDate } }
                ]
              }
            ]
          }
        ],
        $or: query
      }
    };
  } else {
    matchQuery = 
    {
      $match : {
        company_id: this.getCompanyId(),
        role: "admin",
        "data.active": false,
        $and: [
          {
            $or: [
              {
                $and: [
                  {"data.origination_date": { $gte: startDate } },
                  {"data.origination_date": { $lte: endDate } }
                ]
              },
              {
                $and: [
                  {"data.due_date": { $gte: startDate } },
                  {"data.due_date": { $lte: endDate } }
                ]
              }
            ]
          }
        ]
      }
    };
  }

  console.log("Search Query: ", query);    

  let cursor = this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-cmms","part-requests", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
      console.log("Successfully pulled filtered part requests", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        // this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

        this.filteredPagedClosedPartRequestsSubject.next(returnData);
      }
    }
  );

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return this.filteredPagedClosedPartRequestsSubject.asObservable();
}

public async getAsyncPagedDateRangeSearchOpenPartOrders(startDate: Date, endDate: Date, searchFilter: string, pageNumber: number, limit: number): Promise<CMMSPartRequest[]> {
  let returnData: Array<any> = [];

  let matchQuery: any;
  let query: Array<any> = [];
  let employeeQuery: Array<any> = [];
  let sortQuery: string = "data.origination_date"

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let partRequest: CMMSPartRequest = {
    id: null,
    due_date: null,
    origination_date: null,
    requisitioner: null,
    active: null,
    status: null,
    approval_status: null,

    vendor_id: null,
    company_title: null,
    parts: [
      {
        id: null,
        isNew: null,
        title: null,
        price: null,
        unit: null,
        model: null,
        desc: null,
        quantity: null,
        links: null
      }
    ],
    work_order: null,
    additional_details: null,
    subtotal: null,
    sales_tax: null,
    shipping_cost: null,
    other_cost: null,
    total: null,
    priority: null,

    street: null,
    city: null,
    state: null,
    zip: null,

    billing_street: null,
    billing_city: null,
    billing_state: null,
    billing_zip: null
  }

  // Top Level
  Object.keys(partRequest).forEach(key => {
    let createdKey: string = "data." + key;

    query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  });

  // Part Level
  Object.keys(partRequest.parts).forEach(key => {
    let createdKey: string = "data." + key;

    query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  });

  //Custom Fields
  // Object.keys(customFields).forEach(key => {
  //   let createdKey: string = "data.custom_fields." + key;

  //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  // });

  if(searchFilter.length > 0) {
    matchQuery = 
    {
      $match : {
        company_id: this.getCompanyId(),
        role: "admin",
        "data.active": true,
        $and: [
          {
            $or: [
              {
                $and: [
                  {"data.origination_date": { $gte: startDate } },
                  {"data.origination_date": { $lte: endDate } }
                ]
              },
              {
                $and: [
                  {"data.due_date": { $gte: startDate } },
                  {"data.due_date": { $lte: endDate } }
                ]
              }
            ]
          }
        ],
        $or: query
      }
    };
  } else {
    matchQuery = 
    {
      $match : {
        company_id: this.getCompanyId(),
        role: "admin",
        "data.active": true,
        $and: [
          {
            $or: [
              {
                $and: [
                  {"data.origination_date": { $gte: startDate } },
                  {"data.origination_date": { $lte: endDate } }
                ]
              },
              {
                $and: [
                  {"data.due_date": { $gte: startDate } },
                  {"data.due_date": { $lte: endDate } }
                ]
              }
            ]
          }
        ]
      }
    };
  }

  console.log("Search Query: ", query);    

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-cmms","part-requests", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
      console.log("Successfully pulled filtered part requests", data);

      if(data != undefined) {

        // this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

      }
    }
  );

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return returnData;
}

public async getAsyncPagedDateRangeSearchClosedPartOrders(startDate: Date, endDate: Date, searchFilter: string, pageNumber: number, limit: number): Promise<CMMSPartRequest[]> {
  let returnData: Array<any> = [];

  let matchQuery: any;
  let query: Array<any> = [];
  let employeeQuery: Array<any> = [];
  let sortQuery: string = "data.origination_date"

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let partRequest: CMMSPartRequest = {
    id: null,
    due_date: null,
    origination_date: null,
    requisitioner: null,
    active: null,
    status: null,
    approval_status: null,

    vendor_id: null,
    company_title: null,
    parts: [
      {
        id: null,
        isNew: null,
        title: null,
        price: null,
        unit: null,
        model: null,
        desc: null,
        quantity: null,
        links: null
      }
    ],
    work_order: null,
    additional_details: null,
    subtotal: null,
    sales_tax: null,
    shipping_cost: null,
    other_cost: null,
    total: null,
    priority: null,

    street: null,
    city: null,
    state: null,
    zip: null,

    billing_street: null,
    billing_city: null,
    billing_state: null,
    billing_zip: null
  }

  // Top Level
  Object.keys(partRequest).forEach(key => {
    let createdKey: string = "data." + key;

    query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  });

  // Part Level
  Object.keys(partRequest.parts).forEach(key => {
    let createdKey: string = "data." + key;

    query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  });

  //Custom Fields
  // Object.keys(customFields).forEach(key => {
  //   let createdKey: string = "data.custom_fields." + key;

  //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
  // });

  if(searchFilter.length > 0) {
    matchQuery = 
    {
      $match : {
        company_id: this.getCompanyId(),
        role: "admin",
        "data.active": false,
        $and: [
          {
            $or: [
              {
                $and: [
                  {"data.origination_date": { $gte: startDate } },
                  {"data.origination_date": { $lte: endDate } }
                ]
              },
              {
                $and: [
                  {"data.due_date": { $gte: startDate } },
                  {"data.due_date": { $lte: endDate } }
                ]
              }
            ]
          }
        ],
        $or: query
      }
    };
  } else {
    matchQuery = 
    {
      $match : {
        company_id: this.getCompanyId(),
        role: "admin",
        "data.active": false,
        $and: [
          {
            $or: [
              {
                $and: [
                  {"data.origination_date": { $gte: startDate } },
                  {"data.origination_date": { $lte: endDate } }
                ]
              },
              {
                $and: [
                  {"data.due_date": { $gte: startDate } },
                  {"data.due_date": { $lte: endDate } }
                ]
              }
            ]
          }
        ]
      }
    };
  }

  console.log("Search Query: ", query);    

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-cmms","part-requests", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
      console.log("Successfully pulled filtered part requests", data);

      if(data != undefined) {

        // this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

      }
    }
  );

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return returnData;
}

public async upsertCMMSVendor(cmmsVendor: CMMSVendor) {
  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": cmmsVendor.id
  };

  await this.realm.mongoUpsertOne(query, "hive-cmms","vendors", cmmsVendor, "admin", this.getCompanyId(), false).then(data => {
    
  });
}

public async upsertCMMSPartRequest(CMMSPartRequest: CMMSPartRequest) {
  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": CMMSPartRequest.id
  };

  await this.realm.mongoUpsertOne(query, "hive-cmms","part-requests", CMMSPartRequest, "admin", this.getCompanyId(), false).then(data => {
    
  });
}


// ===========================================
// Work Order
// ===========================================

public async getWorkOrdersAsForeign(companyId: string, workOrderId: string): Promise<Array<WorkOrder>> {
  let returnedData: Array<WorkOrder> = []; 

  let query: any = {
    company_id: companyId,
    "data.workOrderId": workOrderId
  };

  await this.realm.mongoGetWorkOrdersAsForeign(query, "hive-work-order","work-orders", "admin", companyId, false).then( workOrders => {
    console.log("Successfully found WorkOrders! ", workOrders);

    if(workOrders != undefined && workOrders != null) {

      for(let workOrder of workOrders) {
        returnedData.push(workOrder.data);
      }
    }

  });

  return returnedData;
}

public async getWorkOrderAsForeign(companyId: string, workOrderId: string): Promise<WorkOrder> {
  let returnedData: WorkOrder = null; 

  let query: any = {
    company_id: companyId,
    "data.workOrderId": workOrderId
  };

  await this.realm.mongoGetOneAsForeign(query, "hive-work-order","work-orders", "admin", companyId).then( workOrder => {
    console.log("Successfully found Foreign WorkOrder! ", workOrder);

    if(workOrder != undefined && workOrder != null) {
        returnedData = workOrder.data;
    }

  });

  return returnedData;
}

public async getWorkOrder(workOrderId: string): Promise<WorkOrder> {
  let returnedData = null; 

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.workOrderId": workOrderId
  };

  await this.realm.mongoGetWorkOrders(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found WorkOrders! ", data);

    if(data != undefined && data.length > 0) {
      returnedData = data[0].data ;
    }
  });

  return returnedData;
}

public getOpenWorkOrders(): Observable<WorkOrder[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false
  };

  let cursor = this.realm.mongoGetWorkOrders(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found WorkOrders! ", data);

    if(data != undefined) {
      this.workOrderSubject.next(data);
    }
  });

  return this.workOrderSubject.asObservable();
}

public async getAsyncOpenWorkOrders(): Promise<WorkOrder[]> {
  let workOrders: Array<WorkOrder> = [];

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false
  };

  await this.realm.mongoGetWorkOrders(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found WorkOrders! ", data);

    if(data != undefined) {

      for(let workOrder of data) {
        workOrders.push(workOrder.data);
      }
    }
  });

  return workOrders;
}

public async getAsyncTechWorkOrders(techId: string): Promise<WorkOrder[]> {
  let workOrders: Array<WorkOrder> = [];

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.technicianId": techId,
    "data.isCompleted": false
  };

  await this.realm.mongoGetWorkOrders(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found WorkOrders! ", data);

    if(data != undefined) {

      for(let workOrder of data) {
        workOrders.push(workOrder.data);
      }
    }
  });

  return workOrders;
}

public async getAsyncDailyTechWorkOrders(techId: string): Promise<WorkOrder[]> {
  let workOrders: Array<WorkOrder> = [];


  let date_start: Date = new Date();
  let date_end: Date = new Date();

  let page = 0;
  let limit = 100000;

  let sortQuery = "data.dateScheduled"

  // date_start.setDate(date_start.getDate() - 1);
  // date_end.setDate(date_end.getDate() + 1);

  // Resetting Time
  date_start.setHours(0, 0, 0, 0);
  date_end.setHours(24, 0, 0, 0);

  console.log("Start Date: ", date_start);
  console.log("Start Date: ", date_end);

  let matchQuery = 
  {
    $match: {
      $and: [
        { "data.dateScheduled": { $gte: date_start } },
        { "data.dateScheduled": { $lte: date_end } },
        { "data.companyId": this.getCompanyId() },
        { "data.technicianId.value": techId }
      ],
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-work-order", "work-orders", "admin", this.getCompanyId(), page, limit).then( (foundWorkOrders) => {
    console.log("Successfully found technician WorkOrders! ", foundWorkOrders);

    if(foundWorkOrders != undefined) {

      for(let workOrder of foundWorkOrders) {
        workOrders.push(workOrder.data);
      }
    
    }

  });


  return workOrders;
}

public async getAsyncDailyOpenTechWorkOrders(techId: string): Promise<WorkOrder[]> {
  let workOrders: Array<WorkOrder> = [];


  let date_start: Date = new Date();
  let date_end: Date = new Date();

  let page = 0;
  let limit = 100000;

  let sortQuery = "data.dateScheduled"

  // date_start.setDate(date_start.getDate() - 1);
  // date_end.setDate(date_end.getDate() + 1);

  // Resetting Time
  date_start.setHours(0, 0, 0, 0);
  date_end.setHours(24, 0, 0, 0);

  console.log("Start Date: ", date_start);
  console.log("Start Date: ", date_end);

  let matchQuery = 
  {
    $match: {
      $and: [
        { "data.dateScheduled": { $gte: date_start } },
        { "data.dateScheduled": { $lte: date_end } },
        { "data.companyId": this.getCompanyId() },
        { "data.technicianId": techId },
        { "data.isCompleted": false }
      ],
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-work-order", "work-orders", "admin", this.getCompanyId(), page, limit).then( (foundWorkOrders) => {
    console.log("Successfully found technician WorkOrders! ", foundWorkOrders);

    if(foundWorkOrders != undefined) {

      for(let workOrder of foundWorkOrders) {
        workOrders.push(workOrder.data);
      }
    
    }

  });


  return workOrders;
}

public async getAsyncDailyClosedTechWorkOrders(techId: string): Promise<WorkOrder[]> {
  let workOrders: Array<WorkOrder> = [];


  let date_start: Date = new Date();
  let date_end: Date = new Date();

  let page = 0;
  let limit = 100000;

  let sortQuery = "data.dateScheduled"

  // date_start.setDate(date_start.getDate() - 1);
  // date_end.setDate(date_end.getDate() + 1);

  // Resetting Time
  date_start.setHours(0, 0, 0, 0);
  date_end.setHours(24, 0, 0, 0);

  console.log("Start Date: ", date_start);
  console.log("Start Date: ", date_end);

  let matchQuery = 
  {
    $match: {
      $and: [
        { "data.dateScheduled": { $gte: date_start } },
        { "data.dateScheduled": { $lte: date_end } },
        { "data.companyId": this.getCompanyId() },
        { "data.technicianId": techId },
        { "data.isCompleted": true }
      ],
    }
  };

  await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-work-order", "work-orders", "admin", this.getCompanyId(), page, limit).then( (foundWorkOrders) => {
    console.log("Successfully found technician WorkOrders! ", foundWorkOrders);

    if(foundWorkOrders != undefined) {

      for(let workOrder of foundWorkOrders) {
        workOrders.push(workOrder.data);
      }
    
    }

  });


  return workOrders;
}

public getClosedWorkOrders(): Observable<WorkOrder[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": true
  };

  let cursor = this.realm.mongoGetWorkOrders(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found WorkOrders! ", data);

    if(data != undefined) {
      this.workOrderSubject.next(data);
    }
  });

  return this.workOrderSubject.asObservable();
}

public getPagedSearchOpenWorkOrders(searchFilter: string, pageNumber: number, limit: number): Observable<WorkOrder[]> {

  this.getAsyncCustomWorkOrderCustomFields().then( (customFields: Array<WorkOrderCustomField>) => {

    let matchQuery: any;
    let query: Array<any> = [];
    let sortQuery: string = "data.originationDate"

    let workOrder: WorkOrder = {
      technicianId: null,
      originatorId: null,
      workOrderId: null,
      companyId: null,

      originationDate: null,
      custPocName: null,
      custPocPhone: null,
      custPocEmail: null,
      custFirstName: null,
      custMiddleName: null,
      custLastName: null,
      custEmail: null,
      custPhone: null,
      custAddress: null,
      custBillingAddress: null,
      latLng: null,
      custBillAmount: null,
      description: null,
      dateContactLog: null,
      dateInitialContact: null,
      dateScheduled: null,
      timeStretch: null,
      priority: null,
      visibleShift: null,
      parts: null,
      attachedParts: null,

      skillLvl: null,
      specialNotes: null,
      status: null,
      foreign_work_orders: null,
      isAutoGenerated: null,
      isCompleted: null,
      isPaid: null,

      // This is for custom user defined properties
      customFields: customFields
    }

    //Top Level
    Object.keys(workOrder).forEach(key => {
      let createdKey: string = "data." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Custom Fields
    Object.keys(customFields).forEach(key => {
      let createdKey: string = "data.custom_fields." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Locked Fields
    // Object.keys(workOrder.lockedFields).forEach(key => {
    //   let createdKey: string = "data.lockedFields." + key;

    //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    // });

    if(searchFilter.length > 0) {
      matchQuery = 
      {
        $match : {
          company_id: this.getCompanyId(),
          role: "admin",
          "data.isCompleted": false,
          $or: query
        }
      };
    } else {
      matchQuery = 
      {
        $match : {
          company_id: this.getCompanyId(),
          role: "admin",
          "data.isCompleted": false
        }
      };
    }

    console.log("Search Query: ", query);    

    let cursor = this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-work-order","work-orders", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered work orders", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          // this.quantityChecker(data);
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }
  
          this.filteredPagedOpenWorkOrderSubject.next(returnData);
        }
      }
    );
  });

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return this.filteredPagedOpenWorkOrderSubject.asObservable();
}

public getPagedDateRangeSearchOpenWorkOrders(startDate: Date, endDate: Date, searchFilter: string, pageNumber: number, limit: number, dealId?: string): Observable<WorkOrder[]> {

  this.getAsyncCustomWorkOrderCustomFields().then( (customFields: Array<WorkOrderCustomField>) => {
    let matchQuery: any = null;
    let query: Array<any> = [];
    let sortQuery: string = "data.originationDate"

    // Resetting Time
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(24, 0, 0, 0);

    let workOrder: WorkOrder = {
      technicianId: null,
      originatorId: null,
      workOrderId: null,
      companyId: null,

      originationDate: null,
      custPocName: null,
      custPocPhone: null,
      custPocEmail: null,
      custFirstName: null,
      custMiddleName: null,
      custLastName: null,
      custEmail: null,
      custPhone: null,
      custAddress: null,
      custBillingAddress: null,
      latLng: null,
      custBillAmount: null,
      description: null,
      dateContactLog: null,
      dateInitialContact: null,
      dateScheduled: null,
      timeStretch: null,
      priority: null,
      visibleShift: null,
      parts: null,
      attachedParts: null,

      skillLvl: null,
      specialNotes: null,
      status: null,
      foreign_work_orders: null,
      isAutoGenerated: null,
      isCompleted: null,
      isPaid: null,

      // This is for custom user defined properties
      customFields: customFields
    }

    //Top Level
    Object.keys(workOrder).forEach(key => {
      let createdKey: string = "data." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Custom Fields
    Object.keys(customFields).forEach(key => {
      let createdKey: string = "data.custom_fields." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Locked Fields
    // Object.keys(workOrder.lockedFields).forEach(key => {
    //   let createdKey: string = "data.lockedFields." + key;

    //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    // });

    if(dealId != undefined && dealId != null) {

      if(searchFilter.length > 0) {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": false,
            "data.dealId": dealId,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: startDate } },
                      {"data.originationDate": { $lte: endDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: startDate } },
                      {"data.dateScheduled": { $lte: endDate } }
                    ]
                  }
                ]
              }
            ],
            $or: query
          }
        };
      } else {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": false,
            "data.dealId": dealId,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: startDate } },
                      {"data.originationDate": { $lte: endDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: startDate } },
                      {"data.dateScheduled": { $lte: endDate } }
                    ]
                  }
                ]
              }
            ]
          }
        };
      }

    } else {

      if(searchFilter.length > 0) {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": false,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: startDate } },
                      {"data.originationDate": { $lte: endDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: startDate } },
                      {"data.dateScheduled": { $lte: endDate } }
                    ]
                  }
                ]
              }
            ],
            $or: query
          }
        };
      } else {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": false,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: startDate } },
                      {"data.originationDate": { $lte: endDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: startDate } },
                      {"data.dateScheduled": { $lte: endDate } }
                    ]
                  }
                ]
              }
            ]
          }
        };
      }

    }

    console.log("Search Query: ", query);    

    let cursor = this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-work-order","work-orders", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered work orders", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          // this.quantityChecker(data);
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }
  
          this.filteredPagedOpenWorkOrderSubject.next(returnData);
        }
      }
    );
  });

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return this.filteredPagedOpenWorkOrderSubject.asObservable();
}

public async getAsyncPagedDateRangeSearchOpenWorkOrders(startDate: Date, endDate: Date, searchFilter: string, pageNumber: number, limit: number, dealId?: string): Promise<WorkOrder[]> {
  let workOrders: Array<WorkOrder> = [];
  let localStartDate: Date = new Date(startDate);
  let localEndDate: Date = new Date(endDate);

  await this.getAsyncCustomWorkOrderCustomFields().then(async (customFields: Array<WorkOrderCustomField>) => {
    let matchQuery: any = null;
    let query: Array<any> = [];
    let sortQuery: string = "data.originationDate"
    

    // Resetting Time
    localStartDate.setHours(0, 0, 0, 0);
    localEndDate.setHours(24, 0, 0, 0);

    let workOrder: WorkOrder = {
      technicianId: null,
      originatorId: null,
      workOrderId: null,
      companyId: null,

      originationDate: null,
      custPocName: null,
      custPocPhone: null,
      custPocEmail: null,
      custFirstName: null,
      custMiddleName: null,
      custLastName: null,
      custEmail: null,
      custPhone: null,
      custAddress: null,
      custBillingAddress: null,
      latLng: null,
      custBillAmount: null,
      description: null,
      dateContactLog: null,
      dateInitialContact: null,
      dateScheduled: null,
      timeStretch: null,
      priority: null,
      visibleShift: null,
      parts: null,
      attachedParts: null,

      skillLvl: null,
      specialNotes: null,
      status: null,
      foreign_work_orders: null,
      isAutoGenerated: null,
      isCompleted: null,
      isPaid: null,

      // This is for custom user defined properties
      customFields: customFields
    }

    //Top Level
    Object.keys(workOrder).forEach(key => {
      let createdKey: string = "data." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Custom Fields
    Object.keys(customFields).forEach(key => {
      let createdKey: string = "data.custom_fields." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Locked Fields
    // Object.keys(workOrder.lockedFields).forEach(key => {
    //   let createdKey: string = "data.lockedFields." + key;

    //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    // });

    if(dealId != undefined && dealId != null) {

      if(searchFilter.length > 0) {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": false,
            "data.dealId": dealId,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: localStartDate } },
                      {"data.originationDate": { $lte: localEndDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: localStartDate } },
                      {"data.dateScheduled": { $lte: localEndDate } }
                    ]
                  }
                ]
              }
            ],
            $or: query
          }
        };
      } else {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": false,
            "data.dealId": dealId,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: localStartDate } },
                      {"data.originationDate": { $lte: localEndDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: localStartDate } },
                      {"data.dateScheduled": { $lte: localEndDate } }
                    ]
                  }
                ]
              }
            ]
          }
        };
      }

    } else {

      if(searchFilter.length > 0) {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": false,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: localStartDate } },
                      {"data.originationDate": { $lte: localEndDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: localStartDate } },
                      {"data.dateScheduled": { $lte: localEndDate } }
                    ]
                  }
                ]
              }
            ],
            $or: query
          }
        };
      } else {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": false,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: localStartDate } },
                      {"data.originationDate": { $lte: localEndDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: localStartDate } },
                      {"data.dateScheduled": { $lte: localEndDate } }
                    ]
                  }
                ]
              }
            ]
          }
        };
      }

    }  

    await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-work-order","work-orders", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered work orders", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          // this.quantityChecker(data);
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }
  
          workOrders = returnData;
        }
      }
    );
  });

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return workOrders;
}

public getPagedSearchClosedWorkOrders(searchFilter: string, pageNumber: number, limit: number): Observable<WorkOrder[]> {

  this.getAsyncCustomWorkOrderCustomFields().then( (customFields: Array<WorkOrderCustomField>) => {
    let matchQuery: any;
    let query: Array<any> = [];
    let sortQuery: string = "data.originationDate"

    let workOrder: WorkOrder = {
      technicianId: null,
      originatorId: null,
      workOrderId: null,
      companyId: null,

      originationDate: null,
      custPocName: null,
      custPocPhone: null,
      custPocEmail: null,
      custFirstName: null,
      custMiddleName: null,
      custLastName: null,
      custEmail: null,
      custPhone: null,
      custAddress: null,
      custBillingAddress: null,
      latLng: null,
      custBillAmount: null,
      description: null,
      dateContactLog: null,
      dateInitialContact: null,
      dateScheduled: null,
      timeStretch: null,
      priority: null,
      visibleShift: null,
      parts: null,
      attachedParts: null,

      skillLvl: null,
      specialNotes: null,
      status: null,
      foreign_work_orders: null,
      isAutoGenerated: null,
      isCompleted: null,
      isPaid: null,

      // This is for custom user defined properties
      customFields: customFields
    }

    //Top Level
    Object.keys(workOrder).forEach(key => {
      let createdKey: string = "data." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Custom Fields
    Object.keys(customFields).forEach(key => {
      let createdKey: string = "data.custom_fields." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Locked Fields
    // Object.keys(workOrder.lockedFields).forEach(key => {
    //   let createdKey: string = "data.lockedFields." + key;

    //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    // });

    if(searchFilter.length > 0) {
      matchQuery = 
      {
        $match : {
          company_id: this.getCompanyId(),
          role: "admin",
          "data.isCompleted": true,
          $or: query
        }
      };
    } else {
      matchQuery = 
      {
        $match : {
          company_id: this.getCompanyId(),
          role: "admin",
          "data.isCompleted": true
        }
      };
    }
    
    console.log("Search Query: ", query);    

    let cursor = this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-work-order","work-orders", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered work orders", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          // this.quantityChecker(data);
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }
  
          this.filteredPagedClosedWorkOrderSubject.next(returnData);
        }
      }
    );
  });

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return this.filteredPagedClosedWorkOrderSubject.asObservable();
}

public async getAsyncPagedDateRangeSearchClosedWorkOrders(startDate: Date, endDate: Date, searchFilter: string, pageNumber: number, limit: number, dealId?: string): Promise<WorkOrder[]> {
  let workOrders: Array<WorkOrder> = [];
  let localStartDate: Date = new Date(startDate);
  let localEndDate: Date = new Date(endDate);

  await this.getAsyncCustomWorkOrderCustomFields().then(async (customFields: Array<WorkOrderCustomField>) => {
    let matchQuery: any = null;
    let query: Array<any> = [];
    let sortQuery: string = "data.originationDate"
    

    // Resetting Time
    localStartDate.setHours(0, 0, 0, 0);
    localEndDate.setHours(24, 0, 0, 0);

    let workOrder: WorkOrder = {
      technicianId: null,
      originatorId: null,
      workOrderId: null,
      companyId: null,

      originationDate: null,
      custPocName: null,
      custPocPhone: null,
      custPocEmail: null,
      custFirstName: null,
      custMiddleName: null,
      custLastName: null,
      custEmail: null,
      custPhone: null,
      custAddress: null,
      custBillingAddress: null,
      latLng: null,
      custBillAmount: null,
      description: null,
      dateContactLog: null,
      dateInitialContact: null,
      dateScheduled: null,
      timeStretch: null,
      priority: null,
      visibleShift: null,
      parts: null,
      attachedParts: null,

      skillLvl: null,
      specialNotes: null,
      status: null,
      foreign_work_orders: null,
      isAutoGenerated: null,
      isCompleted: null,
      isPaid: null,

      // This is for custom user defined properties
      customFields: customFields
    }

    //Top Level
    Object.keys(workOrder).forEach(key => {
      let createdKey: string = "data." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Custom Fields
    Object.keys(customFields).forEach(key => {
      let createdKey: string = "data.custom_fields." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Locked Fields
    // Object.keys(workOrder.lockedFields).forEach(key => {
    //   let createdKey: string = "data.lockedFields." + key;

    //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    // });

    if(dealId != undefined && dealId != null) {

      if(searchFilter.length > 0) {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": true,
            "data.dealId": dealId,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: localStartDate } },
                      {"data.originationDate": { $lte: localEndDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: localStartDate } },
                      {"data.dateScheduled": { $lte: localEndDate } }
                    ]
                  }
                ]
              }
            ],
            $or: query
          }
        };
      } else {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": true,
            "data.dealId": dealId,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: localStartDate } },
                      {"data.originationDate": { $lte: localEndDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: localStartDate } },
                      {"data.dateScheduled": { $lte: localEndDate } }
                    ]
                  }
                ]
              }
            ]
          }
        };
      }

    } else {

      if(searchFilter.length > 0) {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": true,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: localStartDate } },
                      {"data.originationDate": { $lte: localEndDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: localStartDate } },
                      {"data.dateScheduled": { $lte: localEndDate } }
                    ]
                  }
                ]
              }
            ],
            $or: query
          }
        };
      } else {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": true,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: localStartDate } },
                      {"data.originationDate": { $lte: localEndDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: localStartDate } },
                      {"data.dateScheduled": { $lte: localEndDate } }
                    ]
                  }
                ]
              }
            ]
          }
        };
      }

    } 

    await this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-work-order","work-orders", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered work orders", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          // this.quantityChecker(data);
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }
  
          workOrders = returnData;
        }
      }
    );
  });

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return workOrders;
}

public getPagedDateRangeSearchClosedWorkOrders(startDate: Date, endDate: Date, searchFilter: string, pageNumber: number, limit: number, dealId?: string): Observable<WorkOrder[]> {

  this.getAsyncCustomWorkOrderCustomFields().then( (customFields: Array<WorkOrderCustomField>) => {
    let matchQuery: any;
    let query: Array<any> = [];
    let sortQuery: string = "data.originationDate"

    // Resetting Time
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(24, 0, 0, 0);

    let workOrder: WorkOrder = {
      technicianId: null,
      originatorId: null,
      workOrderId: null,
      companyId: null,

      originationDate: null,
      custPocName: null,
      custPocPhone: null,
      custPocEmail: null,
      custFirstName: null,
      custMiddleName: null,
      custLastName: null,
      custEmail: null,
      custPhone: null,
      custAddress: null,
      custBillingAddress: null,
      latLng: null,
      custBillAmount: null,
      description: null,
      dateContactLog: null,
      dateInitialContact: null,
      dateScheduled: null,
      timeStretch: null,
      priority: null,
      visibleShift: null,
      parts: null,
      attachedParts: null,

      skillLvl: null,
      specialNotes: null,
      status: null,
      foreign_work_orders: null,
      isAutoGenerated: null,
      isCompleted: null,
      isPaid: null,

      // This is for custom user defined properties
      customFields: customFields
    }

    //Top Level
    Object.keys(workOrder).forEach(key => {
      let createdKey: string = "data." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Custom Fields
    Object.keys(customFields).forEach(key => {
      let createdKey: string = "data.custom_fields." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Locked Fields
    // Object.keys(workOrder.lockedFields).forEach(key => {
    //   let createdKey: string = "data.lockedFields." + key;

    //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    // });

    if(dealId != undefined && dealId != null) {

      if(searchFilter.length > 0) {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": true,
            "data.dealId": dealId,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: startDate } },
                      {"data.originationDate": { $lte: endDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: startDate } },
                      {"data.dateScheduled": { $lte: endDate } }
                    ]
                  }
                ]
              }
            ],
            $or: query
          }
        };
      } else {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": true,
            "data.dealId": dealId,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: startDate } },
                      {"data.originationDate": { $lte: endDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: startDate } },
                      {"data.dateScheduled": { $lte: endDate } }
                    ]
                  }
                ]
              }
            ]
          }
        };
      }

    } else {

      if(searchFilter.length > 0) {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": true,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: startDate } },
                      {"data.originationDate": { $lte: endDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: startDate } },
                      {"data.dateScheduled": { $lte: endDate } }
                    ]
                  }
                ]
              }
            ],
            $or: query
          }
        };
      } else {
        matchQuery = 
        {
          $match : {
            company_id: this.getCompanyId(),
            role: "admin",
            "data.isCompleted": true,
            $and: [
              {
                $or: [
                  {
                    $and: [
                      {"data.originationDate": { $gte: startDate } },
                      {"data.originationDate": { $lte: endDate } }
                    ]
                  },
                  {
                    $and: [
                      {"data.dateScheduled": { $gte: startDate } },
                      {"data.dateScheduled": { $lte: endDate } }
                    ]
                  }
                ]
              }
            ]
          }
        };
      }

    }

    console.log("Search Query: ", query);    

    let cursor = this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-work-order","work-orders", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered work orders", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          // this.quantityChecker(data);
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }
  
          this.filteredPagedClosedWorkOrderSubject.next(returnData);
        }
      }
    );
  });

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return this.filteredPagedClosedWorkOrderSubject.asObservable();
}

public getEmployeeFilteredPagedDateRangeSearchOpenWorkOrders(startDate: Date, endDate: Date, searchFilter: string, employeeIdFilter: Array<string>, pageNumber: number, limit: number): Observable<WorkOrder[]> {

  console.log("getEmployeeFilteredPagedDateRangeSearchOpenWorkOrders: ", employeeIdFilter);

  this.getAsyncCustomWorkOrderCustomFields().then( (customFields: Array<WorkOrderCustomField>) => {
    let matchQuery: any;
    let query: Array<any> = [];
    let employeeQuery: Array<any> = [];
    let sortQuery: string = "data.originationDate"

    // Resetting Time
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(24, 0, 0, 0);

    let workOrder: WorkOrder = {
      technicianId: null,
      originatorId: null,
      workOrderId: null,
      companyId: null,

      assignedStaff: [],
      assignedTeams: [],

      originationDate: null,
      custPocName: null,
      custPocPhone: null,
      custPocEmail: null,
      custFirstName: null,
      custMiddleName: null,
      custLastName: null,
      custEmail: null,
      custPhone: null,
      custAddress: null,
      custBillingAddress: null,
      latLng: null,
      custBillAmount: null,
      description: null,
      dateContactLog: null,
      dateInitialContact: null,
      dateScheduled: null,
      timeStretch: null,
      priority: null,
      visibleShift: null,
      parts: null,
      attachedParts: null,

      skillLvl: null,
      specialNotes: null,
      status: null,
      foreign_work_orders: null,
      isAutoGenerated: null,
      isCompleted: null,
      isPaid: null,

      // This is for custom user defined properties
      customFields: customFields
    }

    if(employeeIdFilter.length > 0) {
      for(let employee of employeeIdFilter) {
        employeeQuery.push({ "data.technicianId": { '$regex': employee, '$options': 'i' }} );
        employeeQuery.push({ "data.assignedStaff": { '$regex': employee, '$options': 'i' }} );        

      }
    } else {
      employeeQuery.push({ "data.technicianId": { '$regex': "", '$options': 'i' }} );
      employeeQuery.push({ "data.assignedStaff": { '$regex': "", '$options': 'i' }} ); 
    }

    //Top Level
    Object.keys(workOrder).forEach(key => {
      let createdKey: string = "data." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Custom Fields
    Object.keys(customFields).forEach(key => {
      let createdKey: string = "data.custom_fields." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Locked Fields
    // Object.keys(workOrder.lockedFields).forEach(key => {
    //   let createdKey: string = "data.lockedFields." + key;

    //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    // });

    if(searchFilter.length > 0) {
      matchQuery = 
      {
        $match : {
          company_id: this.getCompanyId(),
          role: "admin",
          "data.isCompleted": false,
          $and: [
            {
              $or: employeeQuery
            },
            {
              $or: [
                {
                  $and: [
                    {"data.originationDate": { $gte: startDate } },
                    {"data.originationDate": { $lte: endDate } }
                  ]
                },
                {
                  $and: [
                    {"data.dateScheduled": { $gte: startDate } },
                    {"data.dateScheduled": { $lte: endDate } }
                  ]
                }
              ]
            }
          ],
          $or: query
        }
      };
    } else {
      matchQuery = 
      {
        $match : {
          company_id: this.getCompanyId(),
          role: "admin",
          "data.isCompleted": false,
          $and: [
            {
              $or: employeeQuery
            },
            {
              $or: [
                {
                  $and: [
                    {"data.originationDate": { $gte: startDate } },
                    {"data.originationDate": { $lte: endDate } }
                  ]
                },
                {
                  $and: [
                    {"data.dateScheduled": { $gte: startDate } },
                    {"data.dateScheduled": { $lte: endDate } }
                  ]
                }
              ]
            }
          ]
        }
      };
    }

    console.log("Search Query: ", query);    

    let cursor = this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-work-order","work-orders", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered work orders", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          // this.quantityChecker(data);
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }
  
          this.filteredPagedClosedWorkOrderSubject.next(returnData);
        }
      }
    );
  });

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return this.filteredPagedClosedWorkOrderSubject.asObservable();
}

public getEmployeeFilteredPagedDateRangeSearchClosedWorkOrders(startDate: Date, endDate: Date, searchFilter: string, employeeIdFilter: Array<string>, pageNumber: number, limit: number): Observable<WorkOrder[]> {

  console.log("getEmployeeFilteredPagedDateRangeSearchOpenWorkOrders: ", employeeIdFilter);

  this.getAsyncCustomWorkOrderCustomFields().then( (customFields: Array<WorkOrderCustomField>) => {
    let matchQuery: any;
    let query: Array<any> = [];
    let employeeQuery: Array<any> = [];
    let sortQuery: string = "data.originationDate"

    // Resetting Time
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(24, 0, 0, 0);

    let workOrder: WorkOrder = {
      technicianId: null,
      originatorId: null,
      workOrderId: null,
      companyId: null,

      originationDate: null,
      custPocName: null,
      custPocPhone: null,
      custPocEmail: null,
      custFirstName: null,
      custMiddleName: null,
      custLastName: null,
      custEmail: null,
      custPhone: null,
      custAddress: null,
      custBillingAddress: null,
      latLng: null,
      custBillAmount: null,
      description: null,
      dateContactLog: null,
      dateInitialContact: null,
      dateScheduled: null,
      timeStretch: null,
      priority: null,
      visibleShift: null,
      parts: null,
      attachedParts: null,

      skillLvl: null,
      specialNotes: null,
      status: null,
      foreign_work_orders: null,
      isAutoGenerated: null,
      isCompleted: null,
      isPaid: null,

      // This is for custom user defined properties
      customFields: customFields
    }

    if(employeeIdFilter.length > 0) {
      for(let employee of employeeIdFilter) {
        employeeQuery.push({ "data.technicianId": { '$regex': employee, '$options': 'i' }} )
      }
    } else {
      employeeQuery.push({ "data.technicianId": { '$regex': "", '$options': 'i' }} )
    }

    //Top Level
    Object.keys(workOrder).forEach(key => {
      let createdKey: string = "data." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Custom Fields
    Object.keys(customFields).forEach(key => {
      let createdKey: string = "data.custom_fields." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Locked Fields
    // Object.keys(workOrder.lockedFields).forEach(key => {
    //   let createdKey: string = "data.lockedFields." + key;

    //   query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    // });

    if(searchFilter.length > 0) {
      matchQuery = 
      {
        $match : {
          company_id: this.getCompanyId(),
          role: "admin",
          "data.isCompleted": true,
          $and: [
            {
              $or: employeeQuery
            },
            {
              $or: [
                {
                  $and: [
                    {"data.originationDate": { $gte: startDate } },
                    {"data.originationDate": { $lte: endDate } }
                  ]
                },
                {
                  $and: [
                    {"data.dateScheduled": { $gte: startDate } },
                    {"data.dateScheduled": { $lte: endDate } }
                  ]
                }
              ]
            }
          ],
          $or: query
        }
      };
    } else {
      matchQuery = 
      {
        $match : {
          company_id: this.getCompanyId(),
          role: "admin",
          "data.isCompleted": true,
          $and: [
            {
              $or: employeeQuery
            },
            {
              $or: [
                {
                  $and: [
                    {"data.originationDate": { $gte: startDate } },
                    {"data.originationDate": { $lte: endDate } }
                  ]
                },
                {
                  $and: [
                    {"data.dateScheduled": { $gte: startDate } },
                    {"data.dateScheduled": { $lte: endDate } }
                  ]
                }
              ]
            }
          ]
        }
      };
    }

    console.log("Search Query: ", query);    

    let cursor = this.realm.mongoPagedFilteredGet(matchQuery, sortQuery, "hive-work-order","work-orders", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered work orders", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          // this.quantityChecker(data);
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data);
          }
  
          this.filteredPagedClosedWorkOrderSubject.next(returnData);
        }
      }
    );
  });

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return this.filteredPagedClosedWorkOrderSubject.asObservable();
}

public getWeekOfWorkOrders(startDate: Date): Observable<WorkOrder[]> {
  let localStartDate: Date = new Date(startDate);
  let endDate: Date = new Date(localStartDate);
  endDate.setDate(startDate.getDate() + 7);

  // Resetting Time
  localStartDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false,
    $and: [
      {"data.dateScheduled": { $gte: localStartDate } },
      {"data.dateScheduled": { $lt: endDate } }
    ]
  };

  let cursor = this.realm.mongoGetWorkOrders(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Work Orders! ", data);

    if(data != undefined) {
      let returnData: Array<any> = [];

      for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
        returnData.push(data[curserIndex].data);
      }

      this.weekWorkOrderSubject.next(returnData);
    }
  }
);

  return this.weekWorkOrderSubject.asObservable();
}

public getDualMonthOfWorkOrders(startDate: Date): Observable<WorkOrder[]> {
  let startMonthDate: Date = new Date(startDate)
  startMonthDate.setDate(1);

  let endDate: Date = new Date(startDate);
  endDate.setMonth(startMonthDate.getMonth() + 2);
  endDate.setDate(0);

  // Resetting Time
  startMonthDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false,
    $and: [
      {"data.dateScheduled": { $gte: startMonthDate } },
      {"data.dateScheduled": { $lt: endDate } }
    ]
  };

  let cursor = this.realm.mongoGetWorkOrders(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Profile! ", data);

    if(data != undefined) {
      let returnData: Array<any> = [];

      for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
        returnData.push(data[curserIndex].data);
      }

      this.monthWorkOrderSubject.next(returnData);
    }
  }
);

  return this.monthWorkOrderSubject.asObservable();
}

public async getAsyncDualMonthOfWorkOrders(startDate: Date): Promise<WorkOrder[]> {
  let workOrders: Array<WorkOrder> = [];

  let startMonthDate: Date = new Date(startDate)
  startMonthDate.setDate(1);

  let endDate: Date = new Date(startDate);
  endDate.setMonth(startMonthDate.getMonth() + 2);
  endDate.setDate(0);

  // Resetting Time
  startMonthDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false,
    $and: [
      {"data.dateScheduled": { $gte: startMonthDate } },
      {"data.dateScheduled": { $lt: endDate } }
    ]
  };

  await this.realm.mongoGetWorkOrders(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then( data => {
    console.log("Successfully found Work Orders! ", data);

    if(data != undefined) {

      for(let workOrder of data) {
        workOrders.push(workOrder.data);
      }

    }

  }
);

  return workOrders;
}

public getMonthOfWorkOrders(startDate: Date): Observable<WorkOrder[]> {
  let startMonthDate: Date = new Date(startDate)
  startMonthDate.setDate(1);

  let endDate: Date = new Date(startDate);
  endDate.setMonth(startMonthDate.getMonth() + 1);
  endDate.setDate(0);

  // Resetting Time
  startMonthDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false,
    $and: [
      {"data.dateScheduled": { $gte: startMonthDate } },
      {"data.dateScheduled": { $lt: endDate } }
    ]
  };

  let cursor = this.realm.mongoGetWorkOrders(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Profile! ", data);

    if(data != undefined) {
      let returnData: Array<any> = [];

      for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
        returnData.push(data[curserIndex].data);
      }

      this.monthWorkOrderSubject.next(returnData);
    }
  }
);

  return this.monthWorkOrderSubject.asObservable();
}

public getMonthOfClosedWorkOrders(startDate: Date): Observable<WorkOrder[]> {
  let startMonthDate: Date = new Date(startDate)
  startMonthDate.setDate(1);

  let endDate: Date = new Date(startDate);
  endDate.setMonth(startMonthDate.getMonth() + 1);
  endDate.setDate(0);

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": true,
    $and: [
      {"data.dateScheduled": { $gte: startMonthDate } },
      {"data.dateScheduled": { $lt: endDate } }
    ]
  };

  let cursor = this.realm.mongoGetWorkOrders(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Work Orders! ", data);

    if(data != undefined) {
      let returnData: Array<any> = [];

      for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
        returnData.push(data[curserIndex].data);
      }

      this.monthWorkOrderSubject.next(returnData);
    }
  }
);

  return this.monthWorkOrderSubject.asObservable();
}

public getDateRangeClosedWorkOrders(startDate: Date, endDate: Date): Observable<WorkOrder[]> {

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);
  
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": true,
    $and: [
      {"data.dateScheduled": { $gte: startDate } },
      {"data.dateScheduled": { $lte: endDate } }
    ]
  };

  let cursor = this.realm.mongoGet(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Work Orders! ", data);

    if(data != undefined) {
      let returnData: Array<any> = [];

      for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
        returnData.push(data[curserIndex].data);
      }

      this.rangeClosedWorkOrderSubject.next(returnData);
    }
  });

  return this.rangeClosedWorkOrderSubject.asObservable();
}

public getDateRangeOpenWorkOrders(startDate: Date, endDate: Date): Observable<WorkOrder[]> {

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false,
    $and: [
      {"data.dateScheduled": { $gte: startDate } },
      {"data.dateScheduled": { $lte: endDate } }
    ]
  };

  let cursor = this.realm.mongoGet(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Work Orders! ", data);

    if(data != undefined) {
      let returnData: Array<any> = [];

      for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
        returnData.push(data[curserIndex].data);
      }

      this.rangeOpenWorkOrderSubject.next(returnData);
    }
  });

  return this.rangeOpenWorkOrderSubject.asObservable();
}

public getDateRangeWorkOrders(startDate: Date, endDate: Date): Observable<WorkOrder[]> {

  // Resetting Time
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(24, 0, 0, 0);

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    $and: [
      {"data.dateScheduled": { $gte: startDate } },
      {"data.dateScheduled": { $lte: endDate } }
    ]
  };

  let cursor = this.realm.mongoGet(query, "hive-work-order","work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Work Orders! ", data);

    if(data != undefined) {
      let returnData: Array<any> = [];

      for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
        returnData.push(data[curserIndex].data);
      }

      this.rangeWorkOrderSubject.next(returnData);
    }
  });

  return this.rangeWorkOrderSubject.asObservable();
}

public getWorkOrderFieldKeys(): any {
  return this.workOrderFieldKeys;
}

public addWorkOrderFieldKeys(key: string): any {
  this.workOrderFieldKeys.push(key);

  return this.workOrderFieldKeys;
}

public removeWorkOrderFieldKeys(keyIndex: number): any {
  this.workOrderFieldKeys.splice(keyIndex, 1);

  return this.workOrderFieldKeys;
}

public getWorkOrderBuilderElements(): any {
  return this.workOrderElements;
}

public async getAsyncCustomWorkOrderCustomFields(): Promise<WorkOrderCustomField[]> {
  let returnData: Array<WorkOrderCustomField> = [];

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGetWorkOrders(query, "hive-work-order", "custom-fields", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Custom Fields! ", data);

    if(data != undefined) {
      

      for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
        returnData.push(data[curserIndex].data);
      }

    }
  });

  return returnData;
}

public async getAsyncCustomWorkOrderCustomFieldsAsForeign(companyId: string): Promise<WorkOrderCustomField[]> {
  let returnData: Array<WorkOrderCustomField> = [];

  let query: any = {
    company_id: companyId,
    role: "admin"
  };

  await this.realm.mongoGetAsForeign(query, "hive-work-order", "custom-fields", "admin", companyId).then(data => {
    console.log("Successfully found Custom Fields! ", data);

    if(data != undefined) {
      

      for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
        returnData.push(data[curserIndex].data);
      }

    }
  });

  return returnData;
}

public getCustomWorkOrderCustomFields(): Observable<WorkOrderCustomField[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  let cursor = this.realm.mongoGetWorkOrders(query, "hive-work-order", "custom-fields", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Custom Fields! ", data);

    if(data != undefined) {
      let returnData: Array<any> = [];

      for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
        returnData.push(data[curserIndex].data);
      }

      this.workOrderCustomFieldsSubject.next(returnData);
    }
  });

  return this.workOrderCustomFieldsSubject.asObservable();
}

public updateWorkOrderCustomField(customField: WorkOrderCustomField): void {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.key": customField.key
  };

  this.realm.mongoUpsertOne(query, "hive-work-order", "custom-fields", customField, "admin", this.getCompanyId(), false);
}

public getWorkOrderTemplate(): Observable<WorkOrderTemplate> {
  

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  let cursor = this.realm.mongoGet(query, "hive-work-order","work-order-template-publish", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Work Order Template! ", data);

    if(data != undefined && data.length > 0) {

      // This is only set up to return 1 template. This must be changed if we allow for multiple saved templates
      this.workOrderTemplateSubject.next(data[0].data);
    } else {
      this.http.get<WorkOrderTemplate>(this.url + 'workOrderTemplate.json').subscribe( data => {

        if(data != undefined) {
          let localTemplate = data
          this.workOrderTemplateSubject.next(localTemplate);
        }
      });
    }
  });

  return this.workOrderTemplateSubject.asObservable();
}

public getLocalWorkOrderTemplate(): WorkOrderTemplate {
  return this.workOrderTemplate;
}

public updatePreviewWorkOrderTemplate(template: WorkOrderTemplate): void {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  this.realm.mongoUpsertOne(query, "hive-work-order", "work-order-template-preview", template, "admin", this.getCompanyId(), false);
}

public updateWorkOrderTemplate(template: WorkOrderTemplate): void {
  this.workOrderTemplate = template;
  this.workOrderTemplateSubject.next(this.workOrderTemplate);

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  this.realm.mongoUpsertOne(query, "hive-work-order", "work-order-template-publish", template, "admin", this.getCompanyId(), false);
}

public getWorkOrderTemplateSubject(): Observable<WorkOrderTemplate> {
  return this.workOrderTemplateSubject.asObservable();
}

public addWorkOrderTemplateRow(tabId: number, row: WorkOrderRow): void {
  this.workOrderTemplate.tabs[tabId].rows.push(row);

  this.workOrderTemplateSubject.next(this.workOrderTemplate);
}

public deleteWorkOrderTemplateRow(tabId: number, index): void {
  this.workOrderTemplate.tabs[tabId].rows.splice(index, 1);

  this.workOrderTemplateSubject.next(this.workOrderTemplate);
}

// Needs Adjustment
public addWorkOrder(workOrderData: WorkOrder) {
  
  this.realm.mongoUpsertOne({"work-order-id": workOrderData.workOrderId}, "hive-work-order","work-orders", workOrderData, "admin", this.getCompanyId(), false);
}

public async updateWorkOrderAsForeign(updateWorkOrderData: WorkOrder): Promise<boolean> {
  let completedStatus: boolean = false;
  let jobAuditKeyTypes: any = this.getJobAuditTypes();
  let jobType: any;

  await this.getWorkOrderAsForeign(updateWorkOrderData.workOrderId, updateWorkOrderData.companyId).then( async (workOrder: WorkOrder) => {

    if(workOrder != undefined && workOrder != null) {
      if(workOrder.dateScheduled.toLocaleString() != updateWorkOrderData.dateScheduled.toLocaleString()) {

        if(workOrder.dateScheduled == undefined || workOrder.dateScheduled == null) {
          jobType = jobAuditKeyTypes.SCHEDULED;
        } else {
          jobType = jobAuditKeyTypes.RESCHEDULED;
        }

        if(updateWorkOrderData.auditTrail == undefined || updateWorkOrderData.auditTrail == null) {
          updateWorkOrderData.auditTrail = [];
        }

        updateWorkOrderData.auditTrail.unshift({
          id: this.generateKey(this.keyTypes.AUDIT_LOG_ID),
          type: jobType,
          data: {
            date: new Date(),
            scheduledDate: updateWorkOrderData.dateScheduled,
            status: this.translateOption(updateWorkOrderData.status, this.statusOptions)
          }
        });
      }

      if(workOrder.technicianId != updateWorkOrderData.technicianId) {
        if(workOrder.technicianId == undefined || workOrder.technicianId == null) {
          jobType = jobAuditKeyTypes.ASSIGNED;
        } else {
          jobType = jobAuditKeyTypes.REASSIGNED;
        }

        updateWorkOrderData.auditTrail.unshift({
          id: this.generateKey(this.keyTypes.AUDIT_LOG_ID),
          type: jobType,
          data: {
            date: new Date(),
            scheduledDate: updateWorkOrderData.dateScheduled,
            status: this.translateOption(updateWorkOrderData.status, this.statusOptions)
          }
        });
      }

      if(workOrder.status != updateWorkOrderData.status) {
        if(workOrder.status == undefined || workOrder.status == null) {
          // DO NOTHING
        } else {

          switch(updateWorkOrderData.status) {

            case this.jobStatusTypes.COMPLETED:
              jobType = jobAuditKeyTypes.COMPLETED;
              break;

            case this.jobStatusTypes.CLOSED:
              jobType = jobAuditKeyTypes.CLOSED;
              break;

            case this.jobStatusTypes.CANCELED:
              jobType = jobAuditKeyTypes.CANCELED;
              break;

            default:
              jobType = jobAuditKeyTypes.WORK_STATUS_CHANGE;
              break;
          }

          updateWorkOrderData.auditTrail.unshift({
            id: this.generateKey(this.keyTypes.AUDIT_LOG_ID),
            type: jobType,
            data: {
              date: new Date(),
              scheduledDate: updateWorkOrderData.dateScheduled,
              status: this.translateOption(updateWorkOrderData.status, this.statusOptions)
            }
          });

        }

      }
    }

    if(updateWorkOrderData.status == this.jobStatusTypes.CLOSED || updateWorkOrderData.status == this.jobStatusTypes.CANCELED) {
      updateWorkOrderData.isCompleted = true;
    } else {
      updateWorkOrderData.isCompleted = false;
    }

    updateWorkOrderData.dateLastUpdated = new Date();

    await this.realm.mongoUpdateAsForeign({ "data.workOrderId": updateWorkOrderData.workOrderId }, "hive-work-order", "work-orders", updateWorkOrderData, "admin", updateWorkOrderData.companyId).then( (status: boolean) => {

      completedStatus = status;
  
    });

  });

  
  return completedStatus;
}

private async sendTextMessage(message: string, phone?: string): Promise<void> {

  let rootURL: string = '/twilio/api';

  if(phone == null) {
      this.snackBar.open('Phone number is invalid', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
      return;
  } else {
      this.formatToSafePhoneFormat(phone);
  }

  this.http.post(rootURL + '/sendTextMessage', { messageDetails: { message: message, phone: phone } } ).subscribe( (data: any) => {
      console.log("Twilio Server Return", data );

      if(data != null && data != undefined) {
          // this.http.get(data.url);
          // window.open(data.url);
      }

  });

}

private sendAutomatedStatusText(workOrder: WorkOrder): void {

  if(workOrder.allowSMS != undefined && workOrder.allowSMS) {

    this.getAsyncAutomationTag(workOrder.statusTag.tag).then( (tag: AutomationTag) => {


      if(tag != undefined && tag != null) {

        if(tag.extNotificationAutomation.allowSms != undefined && tag.extNotificationAutomation.allowSms) {

          let url: string = "https://www.swarmoperative.com/foreignviewer/viewer/customer/job/" + this.getCompanyId() + "/" + workOrder.workOrderId;

          let message: string = tag.extNotificationAutomation.smsMsg + " " + url;
          let phone: string = workOrder.custPhone;

          console.log("Send Status Text", message);
          this.sendTextMessage(message, phone);
    
        }

      }

    });

  }

}

private async sendWorkOrderStatusEmail(email: string, message: string, statusTitle: string, url: string): Promise<boolean> {

  let status: boolean = false;
  let emailRootURL: string = 'twilio-sendgrid/api';

  let details: any = {
      emailDetails: {
          companyName: this.getCompanyName(), 
          companyId: this.getCompanyId(),
          email: email,
          status: statusTitle,
          message: message,
          url: url
      }
  }

  await this.http.post(emailRootURL + '/sendPlatformWorkOrderStatusEmail', details ).toPromise().then( (completed: any) => {
      console.log("SendGrid Work Order Status Email: ", completed );

      status = completed;
  });

  return status;
}


private sendAutomatedStatusEmail(workOrder: WorkOrder): void {

  if(workOrder.allowEmail != undefined && workOrder.allowEmail) {

    this.getAsyncAutomationTag(workOrder.statusTag.tag).then( (tag: AutomationTag) => {


      if(tag != undefined && tag != null) {

        if(tag.extNotificationAutomation.allowEmail != undefined && tag.extNotificationAutomation.allowEmail) {

          let message: string = tag.extNotificationAutomation.emailMsg;
          let url: string = "https://www.swarmoperative.com/foreignviewer/viewer/customer/job/" + this.getCompanyId() + "/" + workOrder.workOrderId;

          if(workOrder?.custEmail) {
            this.sendWorkOrderStatusEmail(workOrder.custEmail, message, tag.title, url);
          }

        }

      }

    });

  }

}


private formatToSafePhoneFormat(phone: string): string {

  if(phone.lastIndexOf("(") > -1) {
      phone = phone.replace("(", "");
      phone = phone.replace(")", "");
      phone = phone.replace("-", "");
      phone = phone.replace(" ", "");
  } 

  return phone;
}

public async updateWorkOrder(updateWorkOrderData: WorkOrder): Promise<boolean> {
  let completedStatus: boolean = false;
  let jobAuditKeyTypes: any = this.getJobAuditTypes();
  let jobType: any;

  await this.getWorkOrder(updateWorkOrderData.workOrderId).then( async (workOrder: WorkOrder) => {

    if(workOrder != undefined && workOrder != null) {
      if(workOrder.dateScheduled.toLocaleString() != updateWorkOrderData.dateScheduled.toLocaleString()) {

        if(workOrder.dateScheduled == undefined || workOrder.dateScheduled == null) {
          jobType = jobAuditKeyTypes.SCHEDULED;
        } else {
          jobType = jobAuditKeyTypes.RESCHEDULED;
        }

        if(updateWorkOrderData.auditTrail == undefined || updateWorkOrderData.auditTrail == null) {
          updateWorkOrderData.auditTrail = [];
        }

        updateWorkOrderData.auditTrail.unshift({
          id: this.generateKey(this.keyTypes.AUDIT_LOG_ID),
          type: jobType,
          data: {
            date: new Date(),
            scheduledDate: updateWorkOrderData.dateScheduled,
            status: this.translateOption(updateWorkOrderData.status, this.statusOptions)
          }
        });
      }

      // NEEDS UPDATING TO BE IN SYNC WITH NEW SYSTEM
      // Technician Change 
      if(workOrder.technicianId != updateWorkOrderData.technicianId) {

        if(workOrder.technicianId == undefined || workOrder.technicianId == null) {
          jobType = jobAuditKeyTypes.ASSIGNED;
        } else {
          jobType = jobAuditKeyTypes.REASSIGNED;
        }

        if(updateWorkOrderData.auditTrail == undefined || updateWorkOrderData.auditTrail == null) {
          updateWorkOrderData.auditTrail = [ ];
        }

        updateWorkOrderData.auditTrail.unshift({
          id: this.generateKey(this.keyTypes.AUDIT_LOG_ID),
          type: jobType,
          data: {
            date: new Date(),
            scheduledDate: updateWorkOrderData.dateScheduled,
            status: this.translateOption(updateWorkOrderData.status, this.statusOptions)
          }
        });
      }

      // Custom Status Change
      if(
        workOrder.statusTag != undefined && workOrder.statusTag != null &&
        updateWorkOrderData.statusTag != undefined && updateWorkOrderData.statusTag != null
        ) {

          if(workOrder.statusTag.tag != updateWorkOrderData.statusTag.tag) {

            await this.getAsyncAutomationTag(updateWorkOrderData.statusTag.tag).then( (tag: AutomationTag) => {


              if(tag != undefined && tag != null) {
        
                if(tag.baseType != undefined && tag.baseType != null) {
                  updateWorkOrderData.status = tag.baseType
                }
        
              }
        
            });

            this.sendAutomatedStatusText(updateWorkOrderData);
            this.sendAutomatedStatusEmail(updateWorkOrderData);

          }

      }

      if(workOrder.status != updateWorkOrderData.status) {
        if(workOrder.status == undefined || workOrder.status == null) {
          // DO NOTHING
        } else {

          switch(updateWorkOrderData.status) {

            case this.jobStatusTypes.COMPLETED:
              jobType = jobAuditKeyTypes.COMPLETED;
              break;

            case this.jobStatusTypes.CLOSED:
              jobType = jobAuditKeyTypes.CLOSED;
              break;

            case this.jobStatusTypes.CANCELED:
              jobType = jobAuditKeyTypes.CANCELED;
              break;

            default:
              jobType = jobAuditKeyTypes.WORK_STATUS_CHANGE;
              break;
          }

          updateWorkOrderData.auditTrail.unshift({
            id: this.generateKey(this.keyTypes.AUDIT_LOG_ID),
            type: jobType,
            data: {
              date: new Date(),
              scheduledDate: updateWorkOrderData.dateScheduled,
              status: this.translateOption(updateWorkOrderData.status, this.statusOptions)
            }
          });

        }

      }

    }

    if(updateWorkOrderData.status == this.jobStatusTypes.CLOSED || updateWorkOrderData.status == this.jobStatusTypes.CANCELED) {
      updateWorkOrderData.isCompleted = true;
    } else {
      updateWorkOrderData.isCompleted = false;
    }

    updateWorkOrderData.dateLastUpdated = new Date();

    await this.realm.mongoUpsertOne({ "data.workOrderId": updateWorkOrderData.workOrderId }, "hive-work-order", "work-orders", updateWorkOrderData, "admin", this.getCompanyId(), false).then( (status: boolean) => {

      completedStatus = status;
  
    });

  });

  
  return completedStatus;
}

// public async addWorkOrderInvoice(updateWorkOrderData: WorkOrder, childInvoice?: WOInvoice): Promise<boolean> {
//   let completedStatus: boolean = false;

//   if(updateWorkOrderData.parent_work_order != undefined && updateWorkOrderData.parent_work_order != null) {

//     await this.getWorkOrder(updateWorkOrderData.workOrderId).then( async (workOrder: WorkOrder) => {

//       if(workOrder != undefined && workOrder != null) {
//         if(workOrder.invoices == undefined || workOrder.invoices == null) {
//           workOrder.invoices = [];
//         }

//         let invoiceIDIndex: number = -1;

//         if(childInvoice != undefined && childInvoice != null) {
//           invoiceIDIndex = workOrder.invoices.findIndex( (invoice: WOInvoice) => { return invoice.id == childInvoice.id });

//           if(invoiceIDIndex == -1) {
//             workOrder.invoices.push(childInvoice);
//           }

//           this.addWorkOrderInvoice(workOrder, childInvoice);
//         } else {
//           let currentInvoiceIDIndex: number = updateWorkOrderData.invoices.findIndex( (invoice: WOInvoice) => { return invoice.id == updateWorkOrderData.workOrderId });
//           invoiceIDIndex = workOrder.invoices.findIndex( (invoice: WOInvoice) => { return invoice.id == updateWorkOrderData.workOrderId });


//           if(invoiceIDIndex == -1 && currentInvoiceIDIndex > -1) {
//             workOrder.invoices.push(updateWorkOrderData.invoices[currentInvoiceIDIndex]);
//           }

//           if(currentInvoiceIDIndex > -1) {
//             this.addWorkOrderInvoice(workOrder, updateWorkOrderData.invoices[currentInvoiceIDIndex]);
//           }
//         }

//       }

//     });

//   }

//   await this.realm.mongoUpsertOne({ "data.workOrderId": updateWorkOrderData.workOrderId }, "hive-work-order", "work-orders", updateWorkOrderData, "admin", this.getCompanyId(), false).then( (status: boolean) => {

//     completedStatus = status;

//   });

//   return completedStatus;
// }

// public async deleteWorkOrderInvoice(updateWorkOrderData: WorkOrder, childInvoice?: WOInvoice): Promise<boolean> {
//   let completedStatus: boolean = false;

//   if(updateWorkOrderData.parent_work_order != undefined && updateWorkOrderData.parent_work_order != null) {

//     await this.getWorkOrder(updateWorkOrderData.workOrderId).then( async (workOrder: WorkOrder) => {

//       if(workOrder != undefined && workOrder != null) {
//         if(workOrder.invoices == undefined || workOrder.invoices == null) {
//           workOrder.invoices = [];
//         }

//         let invoiceIDIndex: number = -1;

//         if(childInvoice != undefined && childInvoice != null) {
//           invoiceIDIndex = workOrder.invoices.findIndex( (invoice: WOInvoice) => { return invoice.id == childInvoice.id });

//           if(invoiceIDIndex > -1) {
//             workOrder.invoices.splice(invoiceIDIndex, 1);
//           }

//           this.deleteWorkOrderInvoice(workOrder, childInvoice);
//         } else {
//           let currentInvoiceIDIndex: number = updateWorkOrderData.invoices.findIndex( (invoice: WOInvoice) => { return invoice.id == updateWorkOrderData.workOrderId });
//           invoiceIDIndex = workOrder.invoices.findIndex( (invoice: WOInvoice) => { return invoice.id == updateWorkOrderData.workOrderId });

//           if(invoiceIDIndex > -1) {
//             workOrder.invoices.splice(invoiceIDIndex, 1);
//           }

//           if(currentInvoiceIDIndex > -1) {
//             this.deleteWorkOrderInvoice(workOrder, updateWorkOrderData.invoices[currentInvoiceIDIndex]);
//           }
//         }

//       }

//     });

//   }

//   await this.realm.mongoUpsertOne({ "data.workOrderId": updateWorkOrderData.workOrderId }, "hive-work-order", "work-orders", updateWorkOrderData, "admin", this.getCompanyId(), false).then( (status: boolean) => {

//     completedStatus = status;

//   });

//   return completedStatus;
// }


public updateDynamicWorkOrder(workOrderData: WorkOrder, database: string, table: string) {

  this.realm.mongoUpsertOne({ "data.workOrderId": workOrderData.workOrderId }, database, table, workOrderData, "admin", this.getCompanyId(), false);
}

// Needs Adjustment
public addTechWorkOrder(workOrderData: WorkOrder) {
  
  this.realm.mongoUpsertOne({"work-order-id": workOrderData.workOrderId}, "hive-admin","work-orders", workOrderData, "admin", this.getCompanyId(), false);
}

// Needs Adjustment
public updateTechWorkOrder(workOrderData: WorkOrder) {
  let query: any = {
    $or: [
      { company_id: this.getCompanyId() },
      { "data.linked_accounts.id": this.getCompanyId() }
    ]
  }


  this.realm.mongoUpsertOne(query, "hive-work-orders","work-order", workOrderData, "admin", this.getCompanyId(), false);
}

// Needs Adjustment
public updateTechWorkOrders(profileData: WorkOrder) {
  let query: any = {
    $or: [
      { company_id: this.getCompanyId() },
      { "data.linked_accounts.id": this.getCompanyId() }
    ]
  }

  this.realm.mongoUpsertOne(query, "hive-work-orders","work-orders", profileData, "admin", this.getCompanyId(), false);
}

// Needs Adjustment
public getTechWorkOrder(): Observable<Profile> {
  let query: any = {
    "data.id": this.getProfileId(),
    $or: [
      { company_id: this.getCompanyId() },
      { "data.linked_accounts.id": this.getCompanyId() }
    ],
    role: "admin"
  };

  let cursor = this.realm.mongoGetOne(query, "hive-admin","employees", "admin", this.getCompanyId()).then(data => {
      console.log("Successfully found Profile! ", data);

      if(data != undefined) {
        this.setCompanyId(data.company_id);
        this.setLinkedAccounts(data.linked_accounts);
        this.setProfileId(data.data.id);
        this.profileSubject.next(data.data);
      }
    }
  );

  return this.profileSubject.asObservable();;
}




// ============== End Work Order ==============

// ===========================================
// CMMS Calls
// ===========================================

public getCMMSLocationTypes(): Array<CMMSLocationType> {
  return this.CMMSLocationTypes;
}

public async getAsyncAllCMMSLocations(): Promise<CMMSLocation[]> {
  let cmmsLocations: Array<CMMSLocation> = [];

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  await this.realm.mongoGet(query, "hive-cmms","locations", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled CMMS Locations", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

        cmmsLocations = returnData;
      }
    }
  );

  return cmmsLocations;
}

public getAllCMMSLocations(): Observable<CMMSLocation[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  this.realm.mongoGet(query, "hive-cmms","locations", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled CMMS Locations", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }
        
        this.cmmsLocationSubject.next(returnData);
      }
    }
  );

  return this.cmmsLocationSubject.asObservable();
}

public async upsertCMMSLocation(cmmsLocation: CMMSLocation): Promise<boolean> {
  let status: boolean = false;

  let query: any = {
    company_id: this.getCompanyId(),
    "data.id": cmmsLocation.id
  };

  await this.realm.mongoUpsertOne(query, "hive-cmms","locations", cmmsLocation, "admin", this.getCompanyId(), false).then( (success: boolean) => {
    status = success;
  });

  return status;
}

public async getAsyncInventoryCustomFields(): Promise<InventoryTrackedFields[]> {
  let returnData: Array<InventoryTrackedFields> = [];

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  // await this.realm.mongoGetWorkOrders(query, "hive-work-order", "custom-fields", "admin", this.getCompanyId(), false).then(data => {
  //   console.log("Successfully found Custom Fields! ", data);

  //   if(data != undefined) {
      

  //     for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
  //       returnData.push(data[curserIndex].data);
  //     }

  //   }
  // });

  return returnData;
}

public getInventory(): Observable<InventoryItem[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  let cursor = this.realm.mongoGet(query, "hive-cmms","inventory", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled inventory", data);

      if(data != undefined) {
        this.inventorySubject.next(data);
      }
    }
  );

  return this.inventorySubject.asObservable();
}

public getBasePersonalBenchStockInventory(): Observable<InventoryItem[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  let cursor = this.realm.mongoGet(query, "hive-cmms","inventory-base", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled base inventory", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        // this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

        this.inventorySubject.next(returnData);
      }
    }
  );

  return this.inventorySubject.asObservable();
}

public getBaseInventory(): Observable<InventoryItem[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  let cursor = this.realm.mongoGet(query, "hive-cmms","inventory-base", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled base inventory", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        // this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

        this.inventorySubject.next(returnData);
      }
    }
  );

  return this.inventorySubject.asObservable();
}

public getLocationFilteredBaseInventory(location: CMMSLocation): Observable<InventoryItem[]> {
  let query: any;

  if(location != null && location != undefined) {
    query = {
      company_id: this.getCompanyId(),
      role: "admin",
      "data.lockedFields.located_at.id": location.id
    };
  } else { 
    query = {
      company_id: this.getCompanyId(),
      role: "admin"
    };
  }

  this.realm.mongoGet(query, "hive-cmms","inventory-base", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled base inventory", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        // this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

        this.inventorySubject.next(returnData);
      }
    }
  );

  return this.inventorySubject.asObservable();
}

public async getAsyncLocationFilteredBaseInventory(location: CMMSLocation): Promise<InventoryItem[]> {
  let query: any;
  let returnData: Array<any> = [];

  if(location != null && location != undefined) {
    query = {
      company_id: this.getCompanyId(),
      role: "admin",
      "data.lockedFields.located_at.id": location.id
    };
  } else { 
    query = {
      company_id: this.getCompanyId(),
      role: "admin"
    };
  }

  await this.realm.mongoGet(query, "hive-cmms","inventory-base", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled base inventory", data);

      if(data != undefined) {

        // this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }
      }
    }
  );

  return returnData;
}

public getPagedSearchDistinctBaseInventory(searchFilter: string, locations: Array<CMMSLocation>, pageNumber: number, limit: number): Observable<InventoryItem[]> {

  this.getAsyncInventoryCustomFields().then( (customFields: Array<InventoryTrackedFields>) => {

    let query: Array<any> = [];
    let orQuery: Array<any> = [];
    let andQuery: Array<any> = [];
    let allLocationIndexChecker: number = locations.findIndex(element => {
      return element.id == "all"
    });

    let inventoryItem: InventoryItem = {
      originatorId: "",
      originationDate: null,
      companyId: "",
  
      // This is for custom user defined properties
      custom_fields: customFields,
  
      lockedFields: {
        id: null,
        title: null,
        barcode: null,
        price: null,
        unit: null,
        quantity: null,
        desc: null,
        located_at: null,
        area: null,
        gallery: null,
        dateLastChanged:null,
        model: null,
        serial_number: null,
        serial_number_tracking: null,
        isMetered: null,
        meter_recordings: null,
        operational_status: null,
        availability: null,
        reservation_system: null,
        work_orders: null,
      }
    };

    let distinctKey: string ="data.lockedFields.barcode";

    //Top Level
    Object.keys(inventoryItem).forEach(key => {
      let createdKey: string = "data." + key;

      orQuery.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Custom Fields
    Object.keys(customFields).forEach(key => {
      let createdKey: string = "data.custom_fields." + key;

      orQuery.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Locked Fields
    Object.keys(inventoryItem.lockedFields).forEach(key => {
      let createdKey: string = "data.lockedFields." + key;

      orQuery.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    if(locations != null && locations != undefined) {
      //Locations Fields
      for(let location of locations) {
        let createdKey: string = "data.lockedFields.located_at.id";

        andQuery.push({ [createdKey]: location.id });
      };
    }

    console.log("Search Query: ", query);    
    if(locations.length > 0 && allLocationIndexChecker > -1) {
      query.push({
        $or: orQuery
      });
    }

    else if(andQuery.length > 0) {
      query.push({
        $or: orQuery,
        $and: [
          {
            $or: andQuery
          }
        ]
      });
    } 
    
    else {
      query.push({
        $or: orQuery,
        $and: [
          {
            "data.lockedFields.located_at.id": "Return Nothing"
          }
        ]
      });
    }

    this.realm.mongoPagedSearchDistinct(query, "hive-cmms","inventory-base", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered inventory", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          // this.quantityChecker(data);
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data[0]);
          }
  
          this.inventorySubject.next(returnData);
        }
      }
    );

  });

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return this.inventorySubject.asObservable();
}

public getPagedSearchDistinctInventory(searchFilter: string, pageNumber: number, limit: number): Observable<InventoryItem[]> {

  this.getAsyncInventoryCustomFields().then( (customFields: Array<InventoryTrackedFields>) => {

    let query: Array<any> = [];

    let inventoryItem: InventoryItem = {
      originatorId: "",
      originationDate: null,
      companyId: "",
  
      // This is for custom user defined properties
      custom_fields: customFields,
  
      lockedFields: {
        id: null,
        title: null,
        barcode: null,
        price: null,
        unit: null,
        quantity: null,
        desc: null,
        located_at: null,
        area: null,
        gallery: null,
        dateLastChanged:null,
        model: null,
        serial_number: null,
        serial_number_tracking: null,
        isMetered: null,
        meter_recordings: null,
        operational_status: null,
        availability: null,
        reservation_system: null,
        work_orders: null,
      }
    };

    let distinctKey: string ="data.lockedFields.barcode";

    //Top Level
    Object.keys(inventoryItem).forEach(key => {
      let createdKey: string = "data." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Custom Fields
    Object.keys(customFields).forEach(key => {
      let createdKey: string = "data.custom_fields." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Locked Fields
    Object.keys(inventoryItem.lockedFields).forEach(key => {
      let createdKey: string = "data.lockedFields." + key;

      query.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    console.log("Search Query: ", query);    

    let cursor = this.realm.mongoPagedSearchDistinct(query, "hive-cmms","inventory", "admin", this.getCompanyId(), pageNumber, limit).then(data => {
        console.log("Successfully pulled filtered inventory", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          // this.quantityChecker(data);
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data[0]);
          }
  
          this.inventoryDistictSubject.next(returnData);
        }
      }
    );
  });

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return this.inventoryDistictSubject.asObservable();
}

public getDistinctInventory(pageNumber: number, limit: number): Observable<InventoryItem[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  let distinctKey ="data.lockedFields.barcode";

  let cursor = this.realm.mongoGetDistinct(query, distinctKey, "hive-cmms","inventory", "admin", this.getCompanyId(), pageNumber, limit, false).then(data => {
      console.log("Successfully pulled inventory", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        // this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data[0]);
        }

        this.inventoryDistictSubject.next(returnData);
      }
    }
  );

  return this.inventoryDistictSubject.asObservable();
}

public getDistinctMeteredInventory(): Observable<InventoryItem[]> {
  // Needs to be dynamic and not static. Come back and fix!
  let pageNumber: number = 0;
  let limit: number = 1000;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.lockedFields.isMetered": true
  };

  let distinctKey ="data.lockedFields.barcode";

  let cursor = this.realm.mongoGetDistinct(query, distinctKey, "hive-cmms","inventory", "admin", this.getCompanyId(), pageNumber, limit, false).then(data => {
      console.log("Successfully pulled Inventory", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data[0]);
        }

        this.inventoryMeteredSubject.next(returnData);
      }
    }
  );

  return this.inventoryMeteredSubject.asObservable();
}

public async getAsyncSelectBaseInventory(barcode: string): Promise<any> {
  let returnData: Array<any> = [];
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.lockedFields.barcode": barcode
  };

  await this.realm.mongoGet(query, "hive-cmms","inventory-base", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled inventory", data);

      if(data != undefined) {
        

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

      }
    }
  );

  console.log("(O) Async Select Inventory Results: ", returnData);

  return returnData;
}

public async getAsyncSelectInventory(barcode: string): Promise<InventoryItem[]> {
  let returnData: Array<InventoryItem> = [];
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.lockedFields.barcode": barcode
  };

  await this.realm.mongoGet(query, "hive-cmms","inventory", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled inventory", data);

      if(data != undefined) {
        

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

      }
    }
  );

  console.log("(O) Async Select Inventory Results: ", returnData);

  return returnData;
}

public getSelectInventory(barcode: string): Observable<InventoryItem[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.lockedFields.barcode": barcode
  };

  let cursor = this.realm.mongoGet(query, "hive-cmms","inventory", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled inventory", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

        this.inventorySelectSubject.next(returnData);
      }
    }
  );

  return this.inventorySelectSubject.asObservable();
}

public async upsertInventory(assetData: InventoryItem) {
  let query: any = {
    company_id: this.getCompanyId(),
    "data.lockedFields.id": assetData.lockedFields.id
  };

  await this.realm.mongoUpsertOne(query, "hive-cmms","inventory", assetData, "admin", this.getCompanyId(), false).then(data => {
    
  });
}

public async upsertInventoryBase(assetData: InventoryItem) {
  let query: any = {
    company_id: this.getCompanyId(),
    "data.lockedFields.id": assetData.lockedFields.id
  };

  await this.realm.mongoUpsertOne(query, "hive-cmms","inventory-base", assetData, "admin", this.getCompanyId(), false).then(data => {
    
  });
}

public async deleteSelectInventory(id: string): Promise<void> {
  let assetQuery: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.lockedFields.id": id
  };

  let assetWorkOrderQuery: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.id": id
  };

  await this.realm.mongoDeleteMany(assetQuery, "hive-cmms","inventory", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully deleted inventory item", data);
  });

  await this.realm.mongoDeleteMany(assetWorkOrderQuery, "hive-cmms","inventory-work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully deleted inventory item work orders", data);
  });
}

// The quantity field can become out of sync when new items are added or deleted. This function ensures of the correct count.
private quantityChecker(assets: Array<any>): void {
  let quantity: number = 1;

  for(let assetTopLevelIndex: number = 0; assetTopLevelIndex < assets.length; assetTopLevelIndex++) {

    for(let assetBottomLevelIndex: number = 0; assetBottomLevelIndex < assets[assetTopLevelIndex].data.length; assetBottomLevelIndex++) {
      quantity = assets[assetTopLevelIndex].data.length;

      assets[assetTopLevelIndex].data[assetBottomLevelIndex].lockedFields.quantity = quantity;
    }
  }
}


public getPagedSearchDistinctAssets(searchFilter: string, locations: Array<CMMSLocation>, pageNumber: number, limit: number): Observable<AssetItem[]> {

  this.getAsyncAssetCustomFields().then( (customFields: Array<AssetTrackedFields>) => {

    let query: Array<any> = [];
    let orQuery: Array<any> = [];
    let andQuery: Array<any> = [];
    let distinctKey: string ="data.lockedFields.barcode";
    let allLocationIndexChecker: number = locations.findIndex(element => { return element.id == 'all'});

    let inventoryItem: AssetItem = {
      originatorId: "",
      originationDate: null,
      companyId: "",
  
      // This is for custom user defined properties
      custom_fields: customFields,

      lockedFields: {
        id: null,
        title: null,
        barcode: null,
        price: null,
        quantity: null,
        desc: null,
        located_at: null,
        area: null,
        gallery: null,
        dateLastChanged: null,
        model: null,
        serial_number: null,
        isMetered: null,
        meter_recordings: null,
        operational_status: null,
        availability: null,
        reservation_system: null,
        work_orders: null,
      }
    };

    //Top Level
    Object.keys(inventoryItem).forEach(key => {
      let createdKey: string = "data." + key;

      orQuery.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Custom Fields
    Object.keys(customFields).forEach(key => {
      let createdKey: string = "data.custom_fields." + key;

      orQuery.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    //Locked Fields
    Object.keys(inventoryItem.lockedFields).forEach(key => {
      let createdKey: string = "data.lockedFields." + key;

      orQuery.push({[createdKey]: { '$regex': searchFilter, '$options': 'i' }});
    });

    if(locations != null && locations != undefined) {
      //Locations Fields
      for(let location of locations) {
        let createdKey: string = "data.lockedFields.located_at.id";

        andQuery.push({ [createdKey]: location.id });
      };
    }

    console.log("Search Query: ", query);    
    
    if(locations.length > 0 && allLocationIndexChecker > -1) {
      query.push({
        $or: orQuery
      });
    }

    else if(andQuery.length > 0) {
      query.push({
        $or: orQuery,
        $and: [
          {
            $or: andQuery
          }
        ]
      });
    } 
    
    else {
      query.push({
        $or: orQuery,
        $and: [
          {
            "data.lockedFields.located_at.id": "Return Nothing"
          }
        ]
      });
    }

    this.realm.mongoGetDistinct(query, distinctKey, "hive-cmms","assets", "admin", this.getCompanyId(), pageNumber, limit, false).then(data => {
        console.log("Successfully pulled filtered assets", data);
  
        if(data != undefined) {
          let returnData: Array<any> = [];
  
          this.quantityChecker(data);
  
          for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
            returnData.push(data[curserIndex].data[0]);
          }
  
          this.assetSubject.next(returnData);
        }
      }
    );

  });

  //Using the same Subject since this will be applied to searching and allowing auto updating
  return this.assetSubject.asObservable();
}

public getDistinctAssets(pageNumber: number, limit: number): Observable<AssetItem[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  let distinctKey ="data.lockedFields.barcode";

  let cursor = this.realm.mongoGetDistinct(query, distinctKey, "hive-cmms","assets", "admin", this.getCompanyId(), pageNumber, limit, false).then(data => {
      console.log("Successfully pulled assets", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data[0]);
        }

        this.assetDistictSubject.next(returnData);
      }
    }
  );

  return this.assetDistictSubject.asObservable();
}

public getDistinctMeteredAssets(): Observable<AssetItem[]> {
  // Needs to be dynamic and not static. Come back and fix!
  let pageNumber: number = 0;
  let limit: number = 1000;

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.lockedFields.isMetered": true
  };

  let distinctKey ="data.lockedFields.barcode";

  let cursor = this.realm.mongoGetDistinct([query], distinctKey, "hive-cmms","assets", "admin", this.getCompanyId(), pageNumber, limit, false).then(data => {
      console.log("Successfully pulled assets", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        this.quantityChecker(data);

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data[0]);
        }

        this.assetMeteredSubject.next(returnData);
      }
    }
  );

  return this.assetMeteredSubject.asObservable();
}

public getSelectAssets(barcode: string): Observable<AssetItem[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.lockedFields.barcode": barcode
  };

  let cursor = this.realm.mongoGet(query, "hive-cmms","assets", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled assets", data);

      if(data != undefined) {
        let returnData: Array<any> = [];

        for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
          returnData.push(data[curserIndex].data);
        }

        this.assetSelectSubject.next(returnData);
      }
    }
  );

  return this.assetSelectSubject.asObservable();
}

// Should return a boolean to represent Failed or Successful deletion
public async deleteSelectAssets(id: string): Promise<void> {
  let assetQuery: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.lockedFields.id": id
  };

  let assetWorkOrderQuery: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.id": id
  };

  await this.realm.mongoDeleteMany(assetQuery, "hive-cmms","assets", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully deleted asset", data);
    }
  );

  await this.realm.mongoDeleteMany(assetWorkOrderQuery, "hive-cmms","asset-work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully deleted asset work orders", data);
  });
}

public getAssets(): Observable<AssetItem[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  let cursor = this.realm.mongoGet(query, "hive-cmms","assets", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully pulled assets", data);

      if(data != undefined) {
        this.assetSubject.next(data);
      }
    }
  );

  return this.assetSubject.asObservable();
}

public async upsertAsset(assetData: AssetItem) {
  let query: any = {
    company_id: this.getCompanyId(),
    "data.lockedFields.id": assetData.lockedFields.id
  };

  await this.realm.mongoUpsertOne(query, "hive-cmms","assets", assetData, "admin", this.getCompanyId(), false).then(data => {
    
  });
}

public async getAsyncAssetCustomFields(): Promise<InventoryTrackedFields[]> {
  let returnData: Array<AssetTrackedFields> = [];

  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  // await this.realm.mongoGetWorkOrders(query, "hive-work-order", "custom-fields", "admin", this.getCompanyId(), false).then(data => {
  //   console.log("Successfully found Custom Fields! ", data);

  //   if(data != undefined) {
      

  //     for(let curserIndex: number = 0; curserIndex < data.length; curserIndex++) {
  //       returnData.push(data[curserIndex].data);
  //     }

  //   }
  // });

  return returnData;
}

// CMMS Dead Code that may be used in parts again
// public getTrackedFields(): Observable<TrackedFields[]> {
//   return this.http.get<TrackedFields[]>(this.url + 'fields.json');
// }

// public getLocalTrackedFields(): Array<TrackedFields> {
//   let trackedFields: Array<TrackedFields> = [];

//   for(let fieldIndex: number = 0; fieldIndex < this.inventory[0].properties.length; fieldIndex++) {
//     trackedFields.push(this.inventory[0].properties[fieldIndex]);
//   }

//   return trackedFields;
// }

// public getLocalTrackedInventory(): Array<InventoryItem> {
//   return this.inventory;
// }

// public getInitialInventory(): Observable<InventoryItem[]> {
//   let testDataSize = 100;

//   this.http.get<InventoryItem[]>(this.url + 'inventory.json').subscribe(inventoryData => {
//     if(inventoryData) {
//       this.inventory = inventoryData;


//       this.getTrackedFields().subscribe(fieldData => {
//         if(fieldData) {

//           for(let inventoryIndex = 0; inventoryIndex < this.inventory.length; inventoryIndex++) {
//             this.inventory[inventoryIndex].properties = JSON.parse(JSON.stringify(fieldData));
//           }

//           this.autoPopulateInventory(testDataSize);
//           this.inventorySubject.next(this.inventory);
//         }
//       });

//     };
//   });

//   return this.inventorySubject.asObservable();
// }

// public getInitialAssets(): Observable<AssetItem[]> {
//   let testDataSize = 20;

//   this.http.get<AssetItem[]>(this.url + 'assets.json').subscribe(inventoryData => {
//     if(inventoryData) {
//       this.assets = inventoryData;


//       this.getTrackedFields().subscribe(fieldData => {
//         if(fieldData) {

//           for(let inventoryIndex = 0; inventoryIndex < this.assets.length; inventoryIndex++) {
//             this.assets[inventoryIndex].custom_fields = JSON.parse(JSON.stringify(fieldData));
//           }

//           this.autoPopulateAssets(testDataSize);
//           this.assetSubject.next(this.assets);
//         }
//       });

//     };
//   });

//   return this.assetSubject.asObservable();
// }

// // Used to fill the database with test data
// public autoPopulateInventory(testDataSize: number): void {

//   // Prepopulate test data - DELETE FOR PRODUCTION
//   for(let populateIndex = 0; populateIndex < testDataSize; populateIndex++) {
//     if(this.inventory.length < testDataSize) {

//       // Cycle through default data add items one at a time until the size has been reached
//       for(let inventoryIndex = 0; inventoryIndex < this.inventory.length; inventoryIndex++) {
//         if(this.inventory.length < testDataSize) {
//           this.addLocalInventoryItem(this.inventory[inventoryIndex]);
//         } else {
//           break;
//         }
//       }

//     } else {
//       break;
//     }
//   }

//   this.inventorySubject.next(this.inventory);
// }

// // Used to fill the database with test data
// public autoPopulateAssets(testDataSize: number): void {

//   // Prepopulate test data - DELETE FOR PRODUCTION
//   for(let populateIndex = 0; populateIndex < testDataSize; populateIndex++) {
//     if(this.assets.length < testDataSize) {

//       // Cycle through default data add items one at a time until the size has been reached
//       for(let assetIndex = 0; assetIndex < this.assets.length; assetIndex++) {
//         if(this.assets.length < testDataSize) {
//           this.addLocalAsset(this.assets[assetIndex]);
//         } else {
//           break;
//         }
//       }

//     } else {
//       break;
//     }
//   }

//   this.assetSubject.next(this.assets);
// }

// public addLocalInventoryItem(item: InventoryItem): void {

//   item.id = this.inventory.length + 1;
//   this.inventory.push(JSON.parse(JSON.stringify(item)));

//   this.inventorySubject.next(this.inventory);
// }

// public addLocalAsset(item: AssetItem): void {

//   item.lockedFields.id = this.assets.length + 1 + "";
//   this.assets.push(JSON.parse(JSON.stringify(item)));

//   this.assetSubject.next(this.assets);
// }

// public deleteInventoryItem(itemId: number): void {
//   this.inventory.splice(itemId, 1);

//   this.inventorySubject.next(this.inventory);
// }

// public addTrackedField(field: TrackedFields): void {
//   for(let inventoryIndex = 0; inventoryIndex < this.inventory.length; inventoryIndex++) {
//     this.inventory[inventoryIndex].properties.push(field);
//   }

//   this.inventorySubject.next(this.inventory);
// }

// public secureDeleteTrackedField(id: number, field: TrackedFields): void {
//   for(let inventoryIndex = 0; inventoryIndex < this.inventory.length; inventoryIndex++) {
//     this.inventory[inventoryIndex].properties.splice(id, 1);
//   }

//   this.inventorySubject.next(this.inventory);
// }

// public softDeleteTrackedField(fieldId: number): void {
//   for(let inventoryIndex = 0; inventoryIndex < this.inventory.length; inventoryIndex++) {
//     this.inventory[inventoryIndex].properties[fieldId].isDisplayed = false;
//   }

//   this.inventorySubject.next(this.inventory);
// }

// public undoSoftDeleteTrackedField(id: number, field: TrackedFields): void {
//   for(let inventoryIndex = 0; inventoryIndex < this.inventory.length; inventoryIndex++) {
//     this.inventory[inventoryIndex].properties[id].isDisplayed = true;
//   }

//   this.inventorySubject.next(this.inventory);
// }

// public editTrackedFieldTitle(id: number, title: string): void {
//   for(let inventoryIndex = 0; inventoryIndex < this.inventory.length; inventoryIndex++) {
//     this.inventory[inventoryIndex].properties[id].title = title;
//   }

//   this.inventorySubject.next(this.inventory);
// }

// public editAllTrackedFieldTitles(titles: Array<string>): void {
//   if(this.inventory[0].properties.length == titles.length) {
//     for(let inventoryIndex = 0; inventoryIndex < this.inventory.length; inventoryIndex++) {
//       for(let propertyIndex = 0; propertyIndex < this.inventory[inventoryIndex].properties.length; propertyIndex++) {
//         this.inventory[inventoryIndex].properties[propertyIndex].title = titles[propertyIndex];
//       }
//     }
//   } 

//   this.inventorySubject.next(this.inventory);
// }

// public editTrackedFieldValue(itemId: number, propertyId: number, value: any): void {
//   this.inventory[itemId].properties[propertyId].value = value;

//   this.inventorySubject.next(this.inventory);
// }

// public editLockedPriceValue(itemId: number, value: any): void {
//   this.inventory[itemId].lockedFields.price = value;

//   this.inventorySubject.next(this.inventory);
// }

// public editLockedQuantityValue(itemId: number, value: any): void {
//   this.inventory[itemId].lockedFields.quantity = value;

//   this.inventorySubject.next(this.inventory);
// }

// public editLockedDescValue(itemId: number, value: any): void {
//   this.inventory[itemId].lockedFields.desc = value;

//   this.inventorySubject.next(this.inventory);
// }

// public editLockedLocatedAtValue(itemId: number, value: any): void {
//   this.inventory[itemId].lockedFields.located_at = value;

//   this.inventorySubject.next(this.inventory);
// }

// ===========================================
// CMMS Work Orders Calls
// ===========================================

public getFilteredAssetOpenWorkOrders(id: string): Observable<AssetWorkOrder[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false,
    "data.id": id
  };

  if(id) {
    let cursor = this.realm.mongoGetWorkOrders(query, "hive-cmms","asset-work-orders", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully found Asset Work Orders! ", data);

      if(data != undefined) {

        let workOrders: Array<AssetWorkOrder> = [];

        for(let workOrderIndex: number = 0; workOrderIndex < data.length; workOrderIndex++) {
          workOrders.push(data[workOrderIndex].data);
        }

        this.filteredAssetWorkOrderSubject.next(workOrders);
      }
    });
  }

  return this.filteredAssetWorkOrderSubject.asObservable();
}

public getFilteredInventoryOpenWorkOrders(id: string): Observable<AssetWorkOrder[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false,
    "data.id": id
  };

  if(id) {
    let cursor = this.realm.mongoGetWorkOrders(query, "hive-cmms","inventory-work-orders", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully found Inventory Work Orders! ", data);

      if(data != undefined) {

        let workOrders: Array<InventoryWorkOrder> = [];

        for(let workOrderIndex: number = 0; workOrderIndex < data.length; workOrderIndex++) {
          workOrders.push(data[workOrderIndex].data);
        }

        this.filteredInventoryWorkOrderSubject.next(workOrders);
      }
    });
  }

  return this.filteredInventoryWorkOrderSubject.asObservable();
}

public getAssetOpenWorkOrders(): Observable<AssetWorkOrder[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false
  };

  let cursor = this.realm.mongoGetWorkOrders(query, "hive-cmms","asset-work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Asset Work Orders! ", data);

    if(data != undefined) {

      let workOrders: Array<AssetWorkOrder> = [];

      for(let workOrderIndex: number = 0; workOrderIndex < data.length; workOrderIndex++) {
        workOrders.push(data[workOrderIndex].data);
      }

      this.assetWorkOrderSubject.next(workOrders);
    }
  }
);

  return this.assetWorkOrderSubject.asObservable();
}

public getInventoryOpenWorkOrders(): Observable<AssetWorkOrder[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false
  };

  let cursor = this.realm.mongoGetWorkOrders(query, "hive-cmms","inventory-work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Inventory Work Orders! ", data);

    if(data != undefined) {

      let workOrders: Array<AssetWorkOrder> = [];

      for(let workOrderIndex: number = 0; workOrderIndex < data.length; workOrderIndex++) {
        workOrders.push(data[workOrderIndex].data);
      }

      this.inventoryWorkOrderSubject.next(workOrders);
    }
  }
);

  return this.inventoryWorkOrderSubject.asObservable();
}

public getFacilityOpenWorkOrders(): Observable<AssetWorkOrder[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin",
    "data.isCompleted": false
  };

  let cursor = this.realm.mongoGetWorkOrders(query, "hive-cmms","facility-work-orders", "admin", this.getCompanyId(), false).then(data => {
    console.log("Successfully found Asset Work Orders! ", data);

    if(data != undefined) {
      this.facilityWorkOrderSubject.next(data);
    }
  }
);

  return this.facilityWorkOrderSubject.asObservable();
}

public upsertAssetWorkOrder(workOrderData: AssetWorkOrder) {
  let query: any = {
    company_id: this.getCompanyId(),
    "data.workOrderId": workOrderData.workOrderId
  };

  this.realm.mongoUpsertOne(query, "hive-cmms","asset-work-orders", workOrderData, "admin", this.getCompanyId(), false);
}

public upsertFacilityWorkOrder(workOrderData: AssetWorkOrder) {
  let query: any = {
    company_id: this.getCompanyId(),
    "data.workOrderId": workOrderData.workOrderId
  };

  this.realm.mongoUpsertOne(query, "hive-cmms","facility-work-orders", workOrderData, "admin", this.getCompanyId(), false);
}

// ============== Gallery Functions ==============

public editLockedGalleryValue(itemId: number, value: any): void {
  this.inventory[itemId].lockedFields.gallery = value;

  this.inventorySubject.next(this.inventory);
}

public addLockedGalleryValue(itemId: number, value: any): void {
  this.inventory[itemId].lockedFields.gallery.push(value);

  this.inventorySubject.next(this.inventory);
}

public deleteLockedGalleryValue(itemId: number, imageID: number): void {
  this.inventory[itemId].lockedFields.gallery.splice(imageID, 1);

  this.inventorySubject.next(this.inventory);
}

// ==============================================

public editLockedDateLastChangedValue(itemId: number, value: any): void {
  this.inventory[itemId].lockedFields.dateLastChanged = value;

  this.inventorySubject.next(this.inventory);
}

public setTrackedFields(trackedFields: Array<InventoryTrackedFields>): void {
  this.inventoryTrackedFields = trackedFields;
}

public filterData(data, params: any, sort?, page?, perPage?) {
  this.sortInventoryData(sort, data);
  return this.paginator(data, page, perPage);
}

public sortInventoryData(sort, data){

  // Temporarily disabled due to horrible performance
  if(false){
  // if(sort){
    switch (sort) {
      case 'Newest':
        data = data.sort((a, b) => {return <any>new Date(b.lockedFields.dateLastChanged) - <any>new Date(a.lockedFields.dateLastChanged)});           
        break;
      case 'Oldest':
        data = data.sort((a, b) => {return <any>new Date(a.lockedFields.dateLastChanged) - <any>new Date(b.lockedFields.dateLastChanged)});           
        break;
      case 'Popular':
        // data = data.sort((a, b) => { 
        //   if(a.ratingsValue/a.ratingsCount < b.ratingsValue/b.ratingsCount){
        //     return 1;
        //   }
        //   if(a.ratingsValue/a.ratingsCount > b.ratingsValue/b.ratingsCount){
        //     return -1;
        //   }
        //   return 0; 
        // });
        break;
      case 'Price (Low to High)':
        // if(this.appSettings.settings.currency == 'USD'){
          data = data.sort((a: InventoryItem, b: InventoryItem) => {
            if(a.lockedFields.price > b.lockedFields.price) {
              return 1;
            }

            else if(a.lockedFields.price < b.lockedFields.price) {
              return -1;
            }

            return 0;  
          }) 
        // }
        // if(this.appSettings.settings.currency == 'EUR'){
          // data = data.sort((a,b) => {
          //   if((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.v.rent)){
          //     return 1;
          //   }
          //   if((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.priceEuro.rent)){
          //     return -1;
          //   }
          //   return 0;  
          // }) 
        // }
        break;
      case 'Price (High to Low)':
        // if(this.appSettings.settings.currency == 'USD'){
          data = data.sort((a,b) => {
            if(a.price < b.lockedFields.price) {
              return 1;
            }
            if(a.price > b.lockedFields.price) {
              return -1;
            }
            return 0;  
          }) 
        // }
        // if(this.appSettings.settings.currency == 'EUR'){
          // data = data.sort((a,b) => {
          //   if((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.v.rent)){
          //     return 1;
          //   }
          //   if((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.priceEuro.rent)){
          //     return -1;
          //   }
          //   return 0;  
          // }) 
        // }
        break;
      default:
        break;
    }
  }
  return data;
}

// ========================= End Inventory Calls ================================




// ===========================================
// Shift Scheduling Calls
// ===========================================

public getShiftTemplates(): Observable<Shift[]> {
  let query: any = {
    company_id: this.getCompanyId(),
    role: "admin"
  };

  let cursor = this.realm.mongoGet(query, "employee-time-tracker","shift-templates", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully found Profile! ", data);

      if(data != undefined) {
        this.shiftSubject.next(data);
      }
    }
  );

  return this.shiftSubject.asObservable();
}

public getShiftSchedules(): Observable<EmployeeSchedule[]> {
  let query: any = {
    company_id: this.getCompanyId()
  };

  let cursor = this.realm.mongoGet(query, "employee-time-tracker","work-schedule", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully found employee's schedule! ", data);

      if(data != undefined) {
        this.allShiftSheduleSubject.next(data);
      }
    }
  );

  return this.allShiftSheduleSubject.asObservable();
}

public getEmployeeShiftSchedule(employeeId: string): Observable<EmployeeSchedule> {
  let query: any = {
    "data.company_id": this.getCompanyId(),
    "data.employeeId": employeeId
  };

  let cursor = this.realm.mongoGet(query, "employee-time-tracker","work-schedule", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully found employee's schedule! ", data);

      if(data != undefined) {
        this.employeeShiftSheduleSubject.next(data);
      }
    }
  );

  return this.employeeShiftSheduleSubject.asObservable();
}

public updateShiftTemplate(shiftData: Shift) {

  this.realm.mongoUpsertOne({ "data.id": shiftData.id }, "employee-time-tracker", "shift-templates", shiftData, "admin", this.getCompanyId(), false).then(data => {
    this.getShiftTemplates();
  });
}

public updateEmployeeShiftSchedule(employeeSchedule: EmployeeSchedule) {
  let query: any = {
    "data.company_id": this.getCompanyId(),
    "data.employeeId": employeeSchedule.employeeId,
    role: "admin"
  };

  this.realm.mongoUpsertOne(query, "employee-time-tracker", "work-schedule", employeeSchedule, "admin", this.getCompanyId(), false);
}

public updateSwapRequest(swapRequest: ScheduleSwap) {
  let query: any = {
    "data.company_id": this.getCompanyId(),
    "data.id": swapRequest.id,
    role: "admin"
  };

  this.realm.mongoUpsertOne(query, "employee-time-tracker", "shift-swap-requests", swapRequest, "admin", this.getCompanyId(), false);
}

public getShiftSwapRequests(): Observable<ScheduleSwap[]> {
  let query: any = {
    "data.company_id": this.getCompanyId()
  };

  let cursor = this.realm.mongoGet(query, "employee-time-tracker","shift-swap-requests", "admin", this.getCompanyId(), false).then(data => {
      console.log("Successfully found employee's schedule! ", data);

      if(data != undefined) {
        this.shiftSwapRequestsSubject.next(data);
      }
    }
  );

  return this.shiftSwapRequestsSubject.asObservable();
}



  // public getGroups(): Observable<GroupCategories[]> {
  //   return this.http.get<GroupCategories[]>(this.url + 'groups.json');
  // }

  // public getShiftTemplates(): Observable<TimeShiftTemplate[]> {
  //   return this.http.get<TimeShiftTemplate[]>(this.url + 'shiftTemplates.json');
  // }

  // public getLocalTimeKeeper(): TimeKeeper[] {
  //   return this.timeKeeper;
  // }

  // public getTimeKeeper(): Observable<TimeKeeper[]> {
  //   return this.timeKeeperSubject.asObservable();
  // }

  // public getNewTimeKeeper(): Observable<TimeKeeper[]> {
  //   let timeKeeper: Array<TimeKeeper> = [];
  //   let groups: Array<GroupCategories> = [];
  //   let dayKeeper: Array<TimeDayKeeper> = [];
  //   let shiftKeeper: Array<TimeShiftKeeper> = [];
  //   let shiftTemplates: Array<TimeShiftTemplate> = [];
  //   const DAYS_IN_WEEK: number = 7;
    
  //   this.getGroups().subscribe(groupData => {
  //     if(groupData) {
  //       groups = groupData;
  //     }

  //     this.getShiftTemplates().subscribe(data => {
  //       if(data) {
  //         this.shiftTemplates = data;

  //         for(let shiftTemplateIndex: number = 0; shiftTemplateIndex < this.shiftTemplates.length; shiftTemplateIndex++) {
  //           shiftKeeper.push({
  //             shift: this.shiftTemplates[shiftTemplateIndex],
  //             count: 0,
  //             requiredCount: 0
  //           });
  //         }

  //         for(let timeDayIndex: number = 0; timeDayIndex < DAYS_IN_WEEK; timeDayIndex++) {
  //           dayKeeper.push({
  //             day: timeDayIndex,
  //             shiftKeeper: JSON.parse(JSON.stringify(shiftKeeper))
  //           });
  //         }
  //       }

  //       for(let groupIndex: number = 0; groupIndex < groups.length; groupIndex++) {
  //         this.timeKeeper.push({
  //           group: groups[groupIndex],
  //           dayKeeper: JSON.parse(JSON.stringify(dayKeeper))
  //         });
  //       }

  //       this.timeKeeperSubject.next(this.timeKeeper);
  //     });
  //   });

  //   return this.timeKeeperSubject.asObservable();
  // }

  // public addCount(): TimeKeeper[] {
  //   return this.timeKeeper;
  // }

  // public subtractCount(dayKeeperIndex: number): TimeKeeper[] {
  //   this.timeKeeper[0].dayKeeper[ dayKeeperIndex ].shiftKeeper[0].count = this.timeKeeper[0].dayKeeper[ dayKeeperIndex ].shiftKeeper[0].count - 1;
  //   return this.timeKeeper;
  // }

  // public addRequiredCount(groupdIndex: number, dayIndex: number, shiftIndex: number): TimeKeeper[] {
  //   let requiredCount = this.timeKeeper[ groupdIndex ].dayKeeper[ dayIndex ].shiftKeeper[shiftIndex].requiredCount;
  //   let count = this.timeKeeper[ groupdIndex ].dayKeeper[ dayIndex ].shiftKeeper[shiftIndex].count;

  //   // if(count > requiredCount) {
  //     this.timeKeeper[ groupdIndex ].dayKeeper[ dayIndex ].shiftKeeper[shiftIndex].requiredCount++;
  //   // }

  //   return this.timeKeeper;
  // }

  // public subtractRequiredCount(groupdIndex: number, dayIndex: number, shiftIndex: number): TimeKeeper[] {
  //   let requiredCount = this.timeKeeper[ groupdIndex ].dayKeeper[ dayIndex ].shiftKeeper[shiftIndex].requiredCount;
  //   // let count = this.timeKeeper[ groupdIndex ].dayKeeper[ dayIndex ].shiftKeeper[shiftIndex].count;

  //   // if(requiredCount > 0) {
  //     this.timeKeeper[ groupdIndex ].dayKeeper[ dayIndex ].shiftKeeper[shiftIndex].requiredCount--;
  //   // }

  //   return this.timeKeeper;
  // }

  // public getNewTimeShift(): Observable<TimeShiftTemplateSubject> {
  //   return this.newTimeShiftSubject.asObservable();
  // }

  // public addNewGroup(group: GroupCategories): void {

  // }

  // public addNewTimeShift(timeShift: TimeShiftTemplate): void {
  //   timeShift.id = this.shiftTemplates.length;
  //   this.shiftTemplates.push(timeShift);

  //   for(let dayKeeperIndex = 0; dayKeeperIndex < this.timeKeeper.length; dayKeeperIndex++) {
  //     for(let shiftKeeperIndex = 0; shiftKeeperIndex < this.timeKeeper[dayKeeperIndex].dayKeeper.length; shiftKeeperIndex++) {
  //       this.timeKeeper[dayKeeperIndex].dayKeeper[shiftKeeperIndex].shiftKeeper.push({
  //         shift: timeShift,
  //         count: 0,
  //         requiredCount: 0
  //       })
  //     }
  //   }
      
  //   this.newTimeShiftSubject.next({ timeShift });
  //   this.timeKeeperSubject.next(this.timeKeeper);
  // }

  // private addGroupToTimeKeeper(): void {

  //   // INCORRECT SUBJECT
  //   this.newTimeShiftSubject.subscribe(groupData => {
  //   });
  // }

  // public setTimeKeeper(timeKeeper: TimeKeeper[]): void {
  //   this.timeKeeper = timeKeeper;
  //   this.timeKeeperSubject.next(timeKeeper);
  // }

// ========================= End Shift Scheduling Calls ================================
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { CompanyProfile, ColorTheme } from 'src/app/data-models/models';
import { ImageHandlerService } from './image-handler.service';
import { keyTypesEnum, NavigatorService } from './navigator.service';



@Injectable({
    providedIn: 'root'
})

export class RebrandService {
    private companyProfile: CompanyProfile = null;

    private keyTypes: any = keyTypesEnum;

    private imgSubscription: Subscription;
    public imgId: string = null;
    public image: any;
    public imageDeleteAction: boolean = false;

    public unlockedTheming: boolean = true;

    public themeMenuExpanded: boolean = false;

    public defaultTheme: ColorTheme = null;
    public companyTheme: ColorTheme = {
        id: null,
        title: null,
        primary: null,
        primaryText: null,
        secondary: null,
        secondaryText: null,
        complimentaryBase: null,
        complimentaryBaseText: null,
        complimentaryFocus: null,
        complimentaryFocusText: null
    };

    public defaultThemes: Array<ColorTheme> = [

    //   --custom-primary: linear-gradient(60deg, rgb(44 44 44) 0%, #403f3f 100%);
    // --custom-secondary: linear-gradient(90deg, #2492dd 0%, #0047AB 100%);
    // --custom-complimentary-base: #1e83d3;
    // --custom-complimentary-focus: linear-gradient(90deg, #dd2624 0%, #ff5300 100%);

    // --custom-primary-text: #ffffff;
    // --custom-secondary-text: #ffffff;
    // --custom-complimentary-base-text: #ffffff;
    // --custom-complimentary-focus-text: #ffffff;

        {
          id: "default_1",
          title: "Slate",
          primary: "darkslategrey",
          primaryText: null,
          secondary: null,
          secondaryText: null,
          complimentaryBase: null,
          complimentaryBaseText: null,
          complimentaryFocus: null,
          complimentaryFocusText: null
        },
        {
          id: "default_2",
          title: "McDonalds",
          primary: "yellow",
          primaryText: "black",
          secondary: "red",
          secondaryText: null,
          complimentaryBase: null,
          complimentaryBaseText: null,
          complimentaryFocus: null,
          complimentaryFocusText: null
        }
    ];

    public customThemes: Array<ColorTheme> = [];

    constructor(private navigatorService: NavigatorService, private imageHandler: ImageHandlerService, private snackBar: MatSnackBar) { 

    }

    private async getCompanyProfile(): Promise<void> {
        await this.navigatorService.getAsyncCompanyProfile().then(data => { 

          if(data == null) {
            return false;
          } 
    
          this.companyProfile = data;
    
          this.imgId = this.companyProfile.image;
          this.image = null;

        });
    }

// ==================================
//           Image / Logo
// ==================================

    public imgSubscriber(): void {

    this.imgSubscription = this.imageHandler.getFile(this.imgId).subscribe(img => {

        if(img) {
        this.image = img;

        console.log("Image: ", this.image);
        }

    });
    
    }

    public getImg(): void {
    if(this.imgId != undefined && this.imgId.length > 0) {
        this.imageHandler.getFile(this.imgId);
    }
    }

    public uploadImage(image: any): string {
    let imgId: any = "";

    if(image != null && image != undefined && image.length > 0) {
        let file: File = image[0].file;
        let imgIdUnique: string = this.navigatorService.generateKey(this.keyTypes.IMAGE_ID);

        this.imageHandler.uploadFile(file, imgIdUnique).then(data => {
            this.imgId = imgIdUnique;
            this.getImg();
        })

        imgId = imgIdUnique;
    }

    return imgId
    }

    public deleteImg(): void {
        this.image = null;
        this.imageDeleteAction = true;
    }
   
// ==================================
//             Theming
// ==================================

  public initTheming(): void {

    this.getCompanyProfile().then( () => {

      if(this.companyProfile.license == undefined || this.companyProfile.license == null) {
        this.companyProfile.license = "unlicensed";

        this.navigatorService.updateCompanyProfile(this.companyProfile).then(data => {
          this.getCompanyProfile();
        });

      }

      this.getImg();

      this.getCompanyCustomThemes().then( () => {

        this.initTheme();
        this.setDefaultThemeVars();

      });

    });

  }

  private setDefaultThemeVars(): void {
    let rootThemeVars: any = document.querySelector(':root');

    let primary: string = rootThemeVars?.style.getPropertyValue('--custom-primary');
    let secondary: string = rootThemeVars?.style.getPropertyValue('--custom-secondary');
    let compBase: string = rootThemeVars?.style.getPropertyValue('--custom-complimentary-base');
    let compFocus: string = rootThemeVars?.style.getPropertyValue('--custom-complimentary-focus');

    let primaryText: string = rootThemeVars?.style.getPropertyValue('--custom-primary-text');
    let secondaryText: string = rootThemeVars?.style.getPropertyValue('--custom-secondary-text');
    let compBaseText: string = rootThemeVars?.style.getPropertyValue('--custom-complimentary-base-text');
    let compFocusText: string = rootThemeVars?.style.getPropertyValue('--custom-complimentary-focus-text');

    if(this.companyTheme != undefined && this.companyTheme != null) {

      this.companyTheme.primary = primary;
      this.companyTheme.secondary = secondary;
      this.companyTheme.complimentaryBase = compBase;
      this.companyTheme.complimentaryFocus = compFocus;

      this.companyTheme.primaryText = primaryText;
      this.companyTheme.secondaryText = secondaryText;
      this.companyTheme.complimentaryBaseText = compBaseText;
      this.companyTheme.complimentaryFocusText = compFocusText;

    }

  }

  private async getCompanyCustomThemes(): Promise<void> {

    await this.navigatorService.getAsyncCompanyThemes().then( (themes: Array<ColorTheme>) => {

      this.customThemes = themes;

    });

  }

  public colorChange(): void {
    this.changeCustomTheme();
  }

  public changeThemeTitle(title: string): void {
    this.companyTheme.title = title;
  }

  public changeTheme(theme: ColorTheme): void {
    this.companyTheme = theme;
    this.changeCustomTheme();
  }

  private initTheme(): void {

    let activeTheme: string = this.companyProfile.theme;

    if(activeTheme == undefined || activeTheme.includes("default")) {

      this.initDefaultTheme();

    } else {

      this.initCustomTheme();

    }

    this.changeCustomTheme();

  }

  private initDefaultTheme(): void {

    let activeTheme: string = this.companyProfile.theme;

    if(activeTheme == undefined || activeTheme == null) {

      // this.companyTheme = this.defaultTheme;
      // Do Nothing

    } else {

      let theme: ColorTheme = this.defaultThemes.find( (defaultTheme: ColorTheme) => { return defaultTheme.id == activeTheme });

      if(theme != undefined && theme != null) {
        this.companyTheme = theme;
      } else {

        // this.companyTheme = this.defaultTheme;
        // Do Nothing

      }

    }

  }

  private initCustomTheme(): void {

    let activeTheme: string = this.companyProfile.theme;

    let theme: ColorTheme = this.customThemes.find( (customTheme: ColorTheme) => { return customTheme.id == activeTheme });

    if(theme != undefined && theme != null) {
      this.companyTheme = theme;
    } else {

      this.companyTheme = this.defaultTheme;

    }

  }

  private changeCustomTheme(): void {
    let rootThemeVars: any = document.querySelector(':root');

    rootThemeVars?.style.setProperty('--custom-primary', this.companyTheme?.primary);
    rootThemeVars?.style.setProperty('--custom-secondary', this.companyTheme?.secondary);
    rootThemeVars?.style.setProperty('--custom-complimentary-base', this.companyTheme?.complimentaryBase);
    rootThemeVars?.style.setProperty('--custom-complimentary-focus', this.companyTheme?.complimentaryFocus);

    rootThemeVars?.style.setProperty('--custom-primary-text', this.companyTheme?.primaryText);
    rootThemeVars?.style.setProperty('--custom-secondary-text', this.companyTheme?.secondaryText);
    rootThemeVars?.style.setProperty('--custom-complimentary-base-text', this.companyTheme?.complimentaryBaseText);
    rootThemeVars?.style.setProperty('--custom-complimentary-focus-text', this.companyTheme?.complimentaryFocusText);

  }

  public expandThemeMenu(): void {
    this.themeMenuExpanded = true;
  }

  public collapseThemeMenu(): void {
    this.themeMenuExpanded = false;
  }

  public createNewTheme(): void {

    if(this.defaultThemes.length > 0) {
      let defaultTheme: ColorTheme = {...this.defaultTheme};

      defaultTheme.id = this.navigatorService.generateKey(this.keyTypes.COMPANY_THEME_ID);
      defaultTheme.title = "Theme " + (this.customThemes.length + 1);

      this.customThemes.push(defaultTheme);
    } else {
      this.snackBar.open('There was an issue while trying to create a new theme. Please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

  }

  public deleteTheme(theme: ColorTheme): void {

    this.navigatorService.deleteCompanyTheme(theme).then( (status: boolean) => {

      if(status) {
        this.snackBar.open('Theme deleted successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      } else {
        this.snackBar.open('There was an issue while trying to delete theme. Please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }

    });

  }

  public saveTheme(): void {

    if(this.companyTheme.id.includes("default")) {
      this.companyTheme.id = this.navigatorService.generateKey(this.keyTypes.COMPANY_THEME_ID);
    }

    this.navigatorService.updateCompanyTheme(this.companyTheme).then( (status: boolean) => {

      if(status) {
        this.snackBar.open('Theme updated successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      } else {
        this.snackBar.open('There was an issue while trying to save theme. Please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }

    });

  }

  public setDefaultTheme(): void {

    this.companyProfile.theme = this.companyTheme.id;

    this.navigatorService.updateCompanyProfile(this.companyProfile).then( (status: boolean) => {

      if(status) {
        this.snackBar.open('Theme default set successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      } else {
        this.snackBar.open('There was an issue while trying to set the default theme. Please try again later!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }

    });

  }

}
import { Component, OnInit, Input, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CRMDeal, InvoiceSettings, PaymentSettings, Revenue, RevInvoice, RevSubscription, StripePaymentDetails, StripePaymentLineItems, WOInvoice, WorkOrder, WorkOrderService } from 'src/app/data-models/models';
import { NavigatorService } from 'src/app/services/vendor/navigator.service';
import { PaymentHandlerService } from 'src/app/services/vendor/payment-handler.service';
import { emailValidator } from 'src/app/theme/utils/app-validators';

import pdfMake from "pdfmake/build/pdfmake";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VendorInvoiceGeneratorDialogComponent } from '../../professional/vendor-invoice-generator-dialog/vendor-invoice-generator-dialog.component';

class Product {
  name: string;
  price: number;
  qty: number;

  constructor() {

  }
}

class Invoice {
  customerName: string;
  address: string;
  contactNo: number;
  email: string;
  
  services: WorkOrderService[] = [];
  products: Product[] = [];
  additionalDetails: string;

  constructor() {
    // Initially one empty product row we will show 
    // this.products.push(new Product());
  }
}

@Component({
  selector: 'app-vendor-revenue-direct',
  templateUrl: 'vendor-revenue-direct.component.html',
  styleUrls: ['vendor-revenue-direct.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorRevenueDirectComponent implements OnInit, OnChanges {
  @Input() workOrders: Array<WorkOrder> = [];
  @Input() workOrder: WorkOrder = null;
  @Input() deal: CRMDeal = null;
  @Input() customerViewer: boolean = true;

  public subscriptionInvoices: Array<RevSubscription> = [];
  public directInvoices: Array<RevInvoice> = [];

  private invoiceDialogRef: MatDialogRef<VendorInvoiceGeneratorDialogComponent>;
  public companyTitle: string = this.navigatorService.getCompanyName();
  
  invoiceSettings: InvoiceSettings;
  // invoice = new Invoice(); 
  public isStripeChargesEnabled: boolean = false;
  public stripeAccountID: string = null;

  constructor(
    private navigatorService: NavigatorService, 
    private paymentHandler: PaymentHandlerService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { 

  }

  ngOnInit() { 
    console.log("Revenue Display Work Order: ", this.workOrder);
    console.log("Revenue Display Deal: ", this.deal);

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateDirectInvoices()
  }

  private updateDirectInvoices(): void {

    this.directInvoices = [];

    if(this.workOrders != undefined && this.workOrders != null) {
    
      for(let workOrder of this.workOrders) {

        if(workOrder.invoices != undefined && workOrder.invoices != null) {

          console.log("Revenue: ", workOrder);
          this.getRevenue(workOrder.invoices);
        
        }
      
      }

    }

    if(this.workOrder != undefined && this.workOrder != null && 
      this.workOrder.invoices != undefined && this.workOrder.invoices != null) {
      
      // for(let invoice of this.workOrder.invoices) {
      //   this.getRevenue(invoice);
      // }

      this.getRevenue(this.workOrder.invoices);

    }

    if(this.deal != undefined && this.deal != null) {

      // for(let invoice of this.deal.invoices) {
      //   this.getRevenue(invoice);
      // }

      this.getRevenue(this.deal.invoices);

    }

  }

  private getWorkOrder(id: string): void {

    this.navigatorService.getWorkOrder(id).then( (workOrder: WorkOrder) => {

      if(workOrder != undefined && workOrder != null) {
        this.workOrder = workOrder;
      }

    });

  }

  private getRevenue(id: string): void {

    this.navigatorService.getAsyncRevenueInvoice(id).then( (revenue: Revenue) => {

      if(revenue != undefined && revenue != null) {

        for(let invoice of revenue.directPayments) {
          this.directInvoices.push(invoice);
        }

        for(let subscription of revenue.subscriptions) {
          this.subscriptionInvoices.push(subscription);
        }

      }

    });

  }

  public openInvoice(invoice: WOInvoice): void {
    pdfMake.createPdf(invoice.invoice).open();
  }
  
  public makePayment(paymentDetails: StripePaymentDetails): void {

    if(paymentDetails != null && paymentDetails != undefined) {
     
      this.paymentHandler.makeStripePayment(paymentDetails);
      
    } else {
      this.snackBar.open('There was an error gathering payment details!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
    }

  }

  public makePaymentAsForeign(paymentDetails: StripePaymentDetails): void {

    if(paymentDetails != null && paymentDetails != undefined) {
     
      this.paymentHandler.makeStripePaymentAsForeign(this.navigatorService.getCompanyId(), paymentDetails);
      
    } else {
      this.snackBar.open('There was an error gathering payment details!', '×', { panelClass: 'fail', verticalPosition: 'top', duration: 3000 });
    }

  }

  public async getStripeAccount(): Promise<any> {
    let returnAccount: any = null;

    await this.navigatorService.getCompanyLevelPaymentSettings().then(async (paymentSettingData: PaymentSettings) => {

      if(paymentSettingData != null && paymentSettingData != undefined) {
        await this.paymentHandler.getStripeAccount(paymentSettingData.stripe_account_id).then( (account: any) => {

          if(account != null && account != undefined) {
            console.log("Stripe Account: ", account);

            this.isStripeChargesEnabled = account.charges_enabled;
            this.stripeAccountID = paymentSettingData.stripe_account_id;

            returnAccount = account;
          }

        });
      }

    });

    return returnAccount;
  }



  public openInvoiceGenerator(): void {
  
    this.invoiceDialogRef = this.dialog.open(
      VendorInvoiceGeneratorDialogComponent,
      { 
        data: {
          workOrder: this.workOrder?.workOrderId,
          deal: this.deal?.id
        }  
      
      }
    );

    this.invoiceDialogRef.beforeClosed().subscribe(data => {
      console.log("Before Closed: ", data);
    });
    
    this.invoiceDialogRef.afterClosed().subscribe(data => {
      console.log("Dialog Closed: ", data);


      // this.navigatorService.updateWorkOrder(data).then(incomingData => {


      // });

    });

  }

}
import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, ChangeDetectorRef, OnInit, AfterViewInit, ViewEncapsulation, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { startOfDay, endOfDay, subDays, addDays, addMonths, endOfMonth, isSameDay, isSameMonth, isSameYear, addHours, getDate, getMonth } from 'date-fns';
import { FormControl } from '@angular/forms';
import { GroupedObservable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { groups } from './groups/vendor-calendar-groups';
import { Subscription } from 'rxjs';


import { keyTypesEnum } from '../../../services/vendor/navigator.service';
import { NavigatorService } from '../../../services/vendor/navigator.service';
import { ImageHandlerService } from 'src/app/services/vendor/image-handler.service';
import { VendorAddWorkOrderDialogComponent } from '../../../shared/professional/vendor-add-work-order-dialog/vendor-add-work-order-dialog.component';
import { VendorWorkOrderDialogComponent } from '../../../shared/professional/vendor-work-order-dialog/vendor-work-order-dialog.component';
import { Color, TechWorkOrders, WorkOrder, Address, Profile, GroupPermissions, LinkedAccount } from '../../../data-models/models';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SafeUrl } from '@angular/platform-browser';
import * as exp from 'constants';
import { ganttViewTypes } from '../../professional/vendor-work-order-gantt/vendor-work-order-gantt.component';
import { VendorWorkAuditTrackerDialogComponent } from '../vendor-work-audit-tracker-dialog/vendor-work-audit-tracker-dialog.component';
// import { Address } from 'aws-sdk/clients/ses';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

const NAMES: string[] = [
  'Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack', 'Charlotte', 'Theodore', 'Isla', 'Oliver',
  'Isabella', 'Jasper', 'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'
];

let activeDate = new Date();

@Component({
  selector: 'app-vendor-work-order-scheduling-week-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'vendor-work-order-scheduling-week-view.component.html',
  styleUrls: ['vendor-work-order-scheduling-week-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorWorkOrderSchedulingWeekViewComponent implements OnInit, AfterViewInit {
  componentId = "workOrderOveriewContainer";
  
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @Output() emitWorkOrderUpdate = new EventEmitter<Array<WorkOrder>>();

  @Input() timeTemplate: any;
  @Input() incomingAddress: Address;
  @Input() filteredEmployees: Array<string> = null;

  public addWorkOrderDialogRef: MatDialogRef<VendorAddWorkOrderDialogComponent>;
  public openWorkOrderDialogRef: MatDialogRef<VendorWorkOrderDialogComponent>;
  private workAuditTrackerDialogRef: MatDialogRef<VendorWorkAuditTrackerDialogComponent>;

  groupOptionSelected: number = 0;
  selectOptions = groups;

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  showMarker: boolean = false;

  timeKeeperSubscription: Subscription;
  employeesSubscription: Subscription;
  techWorkOrderSubscription: Subscription;
  workOrdersSubscription: Subscription;
  groupPermissionSubscriber: Subscription;

  private keyTypes: any = keyTypesEnum;
  public ganttViewType: any = ganttViewTypes;

  public workOrders: Array<any> = [];
  public employees: Array<Profile> = [];
  public employeeGroup: Array<Profile> = [];
  public employeeGroupProfileImgs: Array<SafeUrl> = [];
  public imgIds: Array<string> = [];
  public groupPermissions: Array<GroupPermissions> = [];
  public ganttExpand: boolean = false;

  public employeesWorkOrderVisibility: Array<any> = [];
  public employeesShiftVisibility: Array<number> = [];
  public isEmployeeSubject: boolean = false;
  public isScheduled: boolean = false;

  public workSlots: number = 8;

  public techWorkOrders: Array<TechWorkOrders> = [];

  public weekDays: Array<number> = [0, 1, 2, 3, 4, 5, 6];

  public isTimeKeeperInitialized: boolean = false;

  public shift: number = 0;

  public weekStart: Date = new Date;

  public isTimeTrackerHidden: string = "visible";
  public currentDate: Date = new Date;
  public currentDayPosition: string = "0%";
  public currentOvertimePosition: string = "0%";
  public currentNightPosition: string = "0%";

  public groupFocusIndex = 0;
  public groupOptions: Array<any> = [];

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: Array<CalendarEvent[]> = [];

  activeDayIsOpen: boolean = false;

  constructor(
    private modal: NgbModal, 
    private changeDetectorRef: ChangeDetectorRef, 
    private navigatorService: NavigatorService, 
    private imageHandler: ImageHandlerService,
    public dialog: MatDialog,
    private datePipe: DatePipe) { 

    for(let index = 0; index < groups.length; index++) {
      this.events.push([]);

      for(let availIndex = 0; availIndex < groups[index].availability.length; availIndex++) {
        this.events[this.groupOptionSelected].push({
          title: groups[index].title,
          color: groups[index].color,
          start: groups[index].availability[availIndex].date,

          actions: [
            {
              label: '<i class="actionLayer"></i>',
              onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((iEvent) => iEvent !== event);
                console.log('Event deleted', event);
              }
            }
          ]
        })
      }
    }
  }

  ngOnInit(): void {
    this.weekStart.setDate(this.weekStart.getDate() - this.weekStart.getDay());
    this.timeTrackerPosition();
    this.timeTrackerPositionLoop();
    
    this.subscribeToEmployees();
    this.subscribeToGroupPermissions();
    this.subscribeToWorkOrders();
  }

  ngAfterViewInit(): void {
    // document.querySelector(".calendarSidebarMenu .mat-form-field-underline").classList.add("groupColor");
    // document.querySelector(".calendarSidebarMenu .mat-form-field-ripple").classList.add("groupColor");
    // document.querySelector(".calendarSidebarMenu .mat-select-arrow").classList.add("groupColorArrow");
  }

  ngOnDestroy() {
    if(this.timeKeeperSubscription) {
      this.timeKeeperSubscription.unsubscribe();
    }

    if(this.employeesSubscription) {
      this.employeesSubscription.unsubscribe();
    }

    if(this.techWorkOrderSubscription) {
      this.techWorkOrderSubscription.unsubscribe();
    }

    if(this.workOrdersSubscription) {
      this.workOrdersSubscription.unsubscribe();
    }

    if(this.groupPermissionSubscriber) {
      this.groupPermissionSubscriber.unsubscribe();
    }
  }  

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {  
    console.log("Work Order Dynamic Data: ", changes);

    if(changes && changes.incomingAddress) {
      this.incomingAddress = changes.incomingAddress.currentValue;
    } else {
      this.incomingAddress = {
        street: "",
        city: "",
        state: "",
        zip: null
      };
    }

    console.log("Filtered Employees: ", this.filteredEmployees);
    
    if(this.filteredEmployees != null) {
      this.updateFilteredFocusedEmployees(this.filteredEmployees);
    }
  }

  subscribeToEmployees(): void {
    console.log("Subscribe to Employee");

    this.employeesSubscription = this.navigatorService.getAllEmployees().subscribe(data => { 
      // let result = this.filterData(data); 

      console.log("Returned Employee Data");

      if(data != undefined) {
        this.employees = data;

        this.getGroupPermissions();
      }
    });
  }

  subscribeToWorkOrders(): void {             
    console.log("Subscribe to W/O's");

    this.workOrdersSubscription = this.navigatorService.getWeekOfWorkOrders(this.weekStart).subscribe(data => { 
      // let result = this.filterData(data); 
      let workOrders: any = data;
      this.workOrders = [];

      // this.buildWeekVisibleArrays();
      this.resetPlain();

      if(data != null) {
        for(let index = 0; index < data.length; index++) {
          this.workOrders.push(workOrders[index]);
        }

        this.emitWorkOrderUpdate.emit(this.workOrders);
      } 

      this.chartWorkOrders();

      this.changeDetectorRef.markForCheck();  
      this.changeDetectorRef.detectChanges();
    });
  }

  getWorkOrders(): void {
    console.log("Get W/O's");
    this.navigatorService.getWeekOfWorkOrders(this.weekStart);
  }

  public getImgs(): void {
    if(this.imgIds != undefined && this.imgIds.length > 0) {
      this.imageHandler.getAsyncFiles(this.imgIds).then(imgs => {
        this.employeeGroupProfileImgs = [];
  
        if(imgs) {
  
          for(let img of imgs) {
            this.employeeGroupProfileImgs.push(img);
          }
  
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
        }
  
      });
    }
  }

  subscribeToGroupPermissions(): void {
    console.log("Subscribe To Permissions");

    this.groupPermissionSubscriber = this.navigatorService.getGroupPermissions().subscribe(data => { 
      // let result = this.filterData(data); 
      let employees: any = data;

      if(data != null) {
        console.log("Returned Group Permissions: ", data);
        this.groupPermissions = data;

        this.updateGroupOptions();

        if(this.filteredEmployees == null) {
          this.setGroupFilter(0);
        } else {
          this.updateFilteredFocusedEmployees(this.filteredEmployees);
        }
      }
    });
  }

  getGroupPermissions(): void {
    console.log("Get Permissions");
    this.navigatorService.getGroupPermissions();
  }

  updateGroupOptions(): void {
    this.groupOptions = [];

    for(let group of this.groupPermissions) {
      this.groupOptions.push(group.group);
    }
  }

  setGroupFilter(groupIndex: number) {
    console.log("Group Filter: ", groupIndex);
    this.groupFocusIndex = groupIndex;

    this.updateGroupFocusedEmployees();
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
    
  }

  // setGroupFilter(group: number): void {
  //   this.groupOptionSelected = group;
  // }

  getProfileImage(profileIndex: number): SafeUrl {
    return this.employeeGroupProfileImgs[profileIndex];
  }

  updateGroupFocusedEmployees(): void {
    this.employeeGroup = [];
    this.imgIds = [];

    for(let employeeIndex: number = 0; employeeIndex < this.employees.length; employeeIndex++) {

      // If the focused on group contains the current employee then add it to it's array
      if(this.groupPermissions[this.groupFocusIndex].employeeIds.indexOf(this.employees[employeeIndex].id) > -1) {

        let self = this;


        let companyLink: LinkedAccount = null;
        
        if(this.employees[employeeIndex].linked_accounts != undefined && this.employees[employeeIndex].linked_accounts != null) {
          companyLink = this.employees[employeeIndex].linked_accounts.find( (link: LinkedAccount) => { return link.id == self.navigatorService.getCompanyId() });
        }

        this.employeeGroup.push(this.employees[employeeIndex]);
        this.employeeGroup[this.employeeGroup.length - 1].job_title = (companyLink != null)? companyLink?.jobTitle : "";

        if(this.employees[employeeIndex].image != undefined) {

          if(this.employees[employeeIndex].image.length > 0) {
            this.imgIds.push(this.employees[employeeIndex].image);
          } else {
            this.imgIds.push("Empty Image ID");
          }

        } else {
          this.imgIds.push("Empty Image ID");
        }
      }
    }

    this.buildWeekVisibleArrays();
    this.getImgs();

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  updateFilteredFocusedEmployees(filteredEmployees: Array<string>): void {
    this.employeeGroup = [];
    this.imgIds = [];

    for(let employeeIndex: number = 0; employeeIndex < this.employees.length; employeeIndex++) {

      // If the focused on group contains the current employee then add it to it's array
      if(filteredEmployees.indexOf(this.employees[employeeIndex].id) > -1) {

        let self = this;
        let companyLink: LinkedAccount = null;
        
        if(this.employees[employeeIndex].linked_accounts != undefined && this.employees[employeeIndex].linked_accounts != null) {
          companyLink = this.employees[employeeIndex].linked_accounts.find( (link: LinkedAccount) => { return link.id == self.navigatorService.getCompanyId() });
        }

        this.employeeGroup.push(this.employees[employeeIndex]);
        this.employeeGroup[this.employeeGroup.length - 1].job_title = (companyLink != null)? companyLink?.jobTitle : "";

        if(this.employees[employeeIndex].image != undefined) {

          if(this.employees[employeeIndex].image.length > 0) {
            this.imgIds.push(this.employees[employeeIndex].image);
          } else {
            this.imgIds.push("Empty Image ID");
          }

        } else {
          this.imgIds.push("Empty Image ID");
        }
      }

      
    }

    console.log("Employee Group: ", this.employeeGroup);

    this.buildWeekVisibleArrays();
    this.getImgs();

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  testLog(data: any) {
    console.log("Data Log: ", data);
  }

  buildWeekVisibleArrays(): void {
    this.employeesWorkOrderVisibility = [];
    this.employeesShiftVisibility = [];

    for(let index = 0; index < this.employeeGroup.length; index++) {
      this.employeesShiftVisibility.push(0);
      this.employeesWorkOrderVisibility.push( [ ] );
    }

    for(let employeeIndex = 0; employeeIndex < this.employeesWorkOrderVisibility.length; employeeIndex++) {

      for(let dayCounter = 0; dayCounter < 7; dayCounter++) {
        this.employeesWorkOrderVisibility[employeeIndex].push( [ ] );
      }

      for(let dayIndex = 0; dayIndex < this.employeesWorkOrderVisibility[employeeIndex].length; dayIndex++) {
        this.employeesWorkOrderVisibility[employeeIndex][dayIndex].push( [ ], [ ], [ ] );

        for(let shiftIndex = 0; shiftIndex < this.employeesWorkOrderVisibility[employeeIndex][dayIndex].length; shiftIndex++) {

          for(let workSlotindex = 0; workSlotindex < this.workSlots; workSlotindex++) {
            this.employeesWorkOrderVisibility[employeeIndex][dayIndex][shiftIndex].push( {
              isScheduled: false,
              tooltip: "",
              cssClass: "",
              workOrderCount: 0,
              hideTimeMarker: "",
              workOrders: []
            } );
          }

        }
      }

      this.setEmployeeWorkSlotTooltips(employeeIndex);
    }

    this.isEmployeeSubject = true;
    // this.chartWorkOrders();
    // this.autoShiftChange();

    // if(!this.workOrdersSubscription) {
    //   this.subscribeToWorkOrders();
    // } else {
      this.getWorkOrders();
    // }

    console.log("Shift Visibility Array: ", this.employeesShiftVisibility);
    console.log("Work Visibility Array: ", this.employeesWorkOrderVisibility);

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  nextMonth(): void {
    activeDate = addMonths(activeDate, 1);
  }

  previousMonth(): void {
    activeDate = addMonths(activeDate, -1);
  }

  nextWeek(): void {
    this.weekStart.setDate(this.weekStart.getDate() + 7);

    this.resetPlain();

    this.navigatorService.getWeekOfWorkOrders(this.weekStart);

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  previousWeek(): void {
    this.weekStart.setDate(this.weekStart.getDate() - 7);

    this.resetPlain();

    this.navigatorService.getWeekOfWorkOrders(this.weekStart);

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  // Time Marker
  timeTrackerPositionLoop(): void {
    let self = this;
    let currentDate: Date = new Date();
    let currentMinutes: number = currentDate.getMinutes();
    let currentHours: number = currentDate.getHours();
    let totalMinutes: number = (currentHours * 60) + currentMinutes;
    let totalDailyMinutes: number = 1440;
    let dayShiftOffsetDailyMinutes: number = 1380;
    let nightShiftOffsetDailyMinutes: number = 540;

    let shiftMinutes: number = 480;


    let nightTimeOffset: number = 0;
    let overtimeOffset: number = 0;
    let dayTimeOffset: number = 0;

    setTimeout(function(){ 

      if(currentHours < 9 && currentHours >= 1) {
        totalMinutes -= 60;
        nightTimeOffset =  (( totalMinutes / shiftMinutes ) * 100);
      }

      if(currentHours < 17 && currentHours >= 9) {
        totalMinutes -= 540;
        dayTimeOffset  = (( totalMinutes / shiftMinutes ) * 100);
      }

      if(currentHours < 1 && currentHours >= 17) {
        totalMinutes -= 540;
        dayTimeOffset  = (( totalMinutes / shiftMinutes ) * 100);
      }

      if(dayTimeOffset > 100) {
        self.currentDayPosition = "100%";
      } else {
        self.currentDayPosition = dayTimeOffset + "%";
      }

      if(overtimeOffset > 100) {
        self.currentOvertimePosition = "100%";
      } else {
        self.currentOvertimePosition = overtimeOffset + "%";
      }

      if(nightTimeOffset > 100) {
        self.currentNightPosition = "100%";
      } else {
        self.currentNightPosition = nightTimeOffset + "%";
      }

      self.timeTrackerPositionLoop();
    }, 300000);
    
  }

  // Time Marker
  timeTrackerPosition(): void {
    let currentDate: Date = new Date();
    let currentMinutes: number = currentDate.getMinutes();
    let currentHours: number = currentDate.getHours();
    let totalMinutes: number = (currentHours * 60) + currentMinutes;
    let totalDailyMinutes: number = 1440;
    let dayShiftOffsetDailyMinutes: number = 1380;
    let nightShiftOffsetDailyMinutes: number = 540;
    let nightTimeOffset: number = 0;
    let dayTimeOffset: number = 0;

    let shiftMinutes: number = 480;

    if(currentHours < 9 && currentHours >= 1) {
      totalMinutes -= 60;
      nightTimeOffset =  (( totalMinutes / shiftMinutes ) * 100);
    } 

    else if(currentHours >= 9) {
      nightTimeOffset = 100;
    }

    if(currentHours < 17 && currentHours >= 9) {
      totalMinutes -= 540;
      dayTimeOffset  = (( totalMinutes / shiftMinutes ) * 100);
    }

    else if(currentHours >= 17) {
      dayTimeOffset = 100;
    }

    if(currentHours < 1 && currentHours >= 17) {
      totalMinutes -= 540;
      dayTimeOffset  = (( totalMinutes / shiftMinutes ) * 100);
    }

    else if(currentHours >= 1) {
      dayTimeOffset = 100;
    }
    

    if(dayTimeOffset > 100) {
      this.currentDayPosition = "100%";
    } else {
      this.currentDayPosition = dayTimeOffset + "%";
    }

    if(nightTimeOffset > 100) {
      this.currentNightPosition = "100%";
    } else {
      this.currentNightPosition = nightTimeOffset + "%";
    }
    
  }

  cellEnter(employeeIndex: number, dayIndex: number): void {
    if(this.employeesWorkOrderVisibility[employeeIndex][dayIndex][0][0] != null && this.employeesWorkOrderVisibility[employeeIndex][dayIndex][0][0] != undefined) {
      this.employeesWorkOrderVisibility[employeeIndex][dayIndex][0][0].hideTimeMarker = "none";
    }
  }

  cellExit(employeeIndex: number, dayIndex: number): void {
    if(this.employeesWorkOrderVisibility[employeeIndex][dayIndex][0][0] != null && this.employeesWorkOrderVisibility[employeeIndex][dayIndex][0][0] != undefined) {
      this.employeesWorkOrderVisibility[employeeIndex][dayIndex][0][0].hideTimeMarker = "";
    }
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.setView(CalendarView.Week);
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  // addEvent(date: Date): void {
  //   let startDate = new Date(date);
  //   let endDate = new Date(date);
  //   endDate.setHours(endDate.getHours() + 1);

  //   this.events.push(
  //     {
  //       title: 'Admin',
  //       start: startDate,
  //       end: endDate,
  //       color: colors.red,
  //       draggable: true,
  //       resizable: {
  //         beforeStart: true,
  //         afterEnd: true,
  //       },
  //     },
  //   )
  // }

  // addEvent(segment, $event, segmentElement): void {
  //   console.log("Adding Event");

  //   if(this.timeTemplate) {
  //     this.events[this.groupOptionSelected].push({
  //       id: this.timeTemplate.id,
  //       start: segment.date,
  //       title: this.timeTemplate.title,
  //       color: this.timeTemplate.color,
  //       actions: [
  //         {
  //           label: '<i class="actionLayer"></i>',
  //           onClick: ({ event }: { event: CalendarEvent }): void => {

  //             this.navigatorService.subtractRequiredCount(this.groupOptionSelected, event.start.getDay(), Number(event.id));

  //             this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((iEvent) => iEvent !== event);
  //             console.log('Event deleted', event);
  //             console.log('Day: ', event.start.getDay());
  //           },
  //         }
  //       ]
  //     });
      
  //     this.navigatorService.addRequiredCount(this.groupOptionSelected, segment.date.getDay(), this.timeTemplate.id);
  //     this.refresh.next();
  //   }
  // }

  workSlotEvent(segment: any, workSlotIndex: number, workSlotData: any, dayHalf: boolean) {
    let date: Date = segment.date;
    let employeeIndex: number = segment.date.getHours();
    let isScheduled = workSlotData.isScheduled;

    if(isScheduled) {
      if(workSlotData.workOrders.length == 1) {
        let workOrderIndex: number = workSlotData.workOrders[0].workOrderIndex;
        let workOrderId: string = workSlotData.workOrders[0].workOrderId;
        let employeeId: string = this.employeeGroup[employeeIndex].id;

        this.openWorkOrderAuditDialog(workOrderId);
      } else {
        console.log("Double Booked Time Slot Event");
      }
    } else {
      this.addEvent(date, employeeIndex, dayHalf, workSlotIndex);
    }
  }

  addEvent(date: Date, employeeIndex: number, addMinutes: boolean, hour: number, isOverTime: boolean = false): void {
    let newDate: Date = new Date(JSON.parse(JSON.stringify(date)));

    if(isOverTime) {

    } else {

      if(this.employeesShiftVisibility[employeeIndex] == 0) {
        hour += 9;
      } 
      
      else if(this.employeesShiftVisibility[employeeIndex] == 1) {
        hour += 17;
      }

      else {
        hour += 1;
      }

      if(addMinutes) {
        let minutes = newDate.getMinutes();
  
        newDate.setMinutes(minutes + 30);
      } 

    }

    newDate.setHours(hour);

    this.openAddWorkOrderDialog(newDate, this.employeeGroup[employeeIndex].id, employeeIndex);
  }

  resetPlain(): void {

    for(let employeeIndex = 0; employeeIndex < this.employeesWorkOrderVisibility.length; employeeIndex++) {

      for(let dayIndex = 0; dayIndex < this.employeesWorkOrderVisibility[employeeIndex].length; dayIndex++) {

        for(let dayHalf = 0; dayHalf < this.employeesWorkOrderVisibility[employeeIndex][dayIndex].length; dayHalf++) {

          for(let workSlot = 0; workSlot < this.employeesWorkOrderVisibility[employeeIndex][dayIndex][dayHalf].length; workSlot++) {
  
            this.employeesWorkOrderVisibility[employeeIndex][dayIndex][dayHalf][0].workOrderCount = 0;
            this.employeesWorkOrderVisibility[employeeIndex][dayIndex][dayHalf][workSlot].cssClass = "";
            // this.employeesWorkOrderVisibility[employeeIndex][dayIndex][1][workSlot].cssClass = "";
            this.employeesWorkOrderVisibility[employeeIndex][dayIndex][dayHalf][workSlot].isScheduled = false;
            this.employeesWorkOrderVisibility[employeeIndex][dayIndex][dayHalf][workSlot].workOrders = [];
            // this.employeesWorkOrderVisibility[employeeIndex][dayIndex][1][workSlot].isScheduled = false;
          }
  
        }

      }

    }

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
 
  }

  resetEmployeePlain(employeeIndex: number): void {

    for(let dayIndex = 0; dayIndex < this.employeesWorkOrderVisibility[employeeIndex].length; dayIndex++) {

      for(let dayHalf = 0; dayHalf < this.employeesWorkOrderVisibility[employeeIndex][dayIndex].length; dayHalf++) {

        for(let workSlot = 0; workSlot < this.employeesWorkOrderVisibility[employeeIndex][dayIndex][dayHalf].length; workSlot++) {

          this.employeesWorkOrderVisibility[employeeIndex][dayIndex][dayHalf][0].workOrderCount = 0;
          this.employeesWorkOrderVisibility[employeeIndex][dayIndex][dayHalf][workSlot].cssClass = "";
          // this.employeesWorkOrderVisibility[employeeIndex][dayIndex][1][workSlot].cssClass = "";
          this.employeesWorkOrderVisibility[employeeIndex][dayIndex][dayHalf][workSlot].isScheduled = false;
          this.employeesWorkOrderVisibility[employeeIndex][dayIndex][dayHalf][workSlot].workOrders = [];
          // this.employeesWorkOrderVisibility[employeeIndex][dayIndex][1][workSlot].isScheduled = false;
        }

      }

    }

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
 
  }

  private async employeeAssignedWorkOrderChecker(employeeId: string, workOrder: WorkOrder): Promise<boolean> {
    let assigned: boolean = false;

    if(workOrder.technicianId != null && workOrder.technicianId == employeeId) {
      assigned = true;
      return assigned;
    }


    if(workOrder.assignedStaff == undefined) {
      workOrder.assignedStaff = [];
    }

    if(workOrder.assignedTeams == undefined) {
      workOrder.assignedTeams = [];
    }



    for(let staff of workOrder.assignedStaff) {
      if(staff == employeeId) {
        assigned = true;
        return assigned;
      }
    }

    for(let teamId of workOrder.assignedTeams) {

      await this.navigatorService.getGroupPermission(teamId).then( (team: GroupPermissions) => {

        if(team != undefined && team != null) {

          for(let teamMember of team.employeeIds) {

            if(teamMember == employeeId) {
              assigned = true;
              return assigned;
            }

          }

        }

      });

    }


    return assigned;
  }

  private adjustStartTime(
    checkDate: Date, 
    checkTime: number, 
    initialTime: number,
    adjOrderTimeStretch: number,
    initWorkOrderTimeStretch: number, 
    hourAdjuster: number,
    minuteAdjuster: number,
    woIndex: number, 
    employeeIndex: number): number {
        
    checkDate = new Date();
    checkDate.setMonth(this.workOrders[woIndex].dateScheduled.getMonth());
    checkDate.setDate(this.workOrders[woIndex].dateScheduled.getDate());
    checkDate.setHours(hourAdjuster);
    checkDate.setMinutes(0);
    checkTime = checkDate.getTime();

    let test = ((( Math.abs(initialTime - checkTime) ) / 1000) / 60) + (minuteAdjuster);

    if(this.employeesShiftVisibility[employeeIndex] == 2) {
      test += 480;
    }

    adjOrderTimeStretch = initWorkOrderTimeStretch - Math.abs(test);

    return adjOrderTimeStretch;
  }

  // private shiftMapping(
  //   workSlot: number, 
  //   isWorkSlotAdjusted: boolean, 
  //   forceShift: boolean, 
  //   workOrderStartDay: number, 
  //   initDayStart: number, 
  //   initChartingStart: number,
  //   workOrderId: string,
  //   woIndex: number): void {

  //   if(workSlot < 8 || !isWorkSlotAdjusted) {

  //     if(!isWorkSlotAdjusted) {
  //       if(forceShift != null && workOrderStartDay >= initDayStart && !initChartingStart ) {
  //         workSlot = 0;
  //       } else {
  //         workSlot -= 9;
  //       }

  //       isWorkSlotAdjusted = true;
  //     }

  //     if(workSlot >= 8) {
  //       console.log("Error", workOrderId);
  //       console.log("Error", this.workOrders[woIndex]);
  //       continue;
  //     }

  //     this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
  //       workOrderIndex: workOrderIndex,
  //       workOrderId: workOrderId
  //     });

  //     if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
  //       this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked";
  //       // this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
  //       //   workOrderIndex: workOrderIndex,
  //       //   workOrderId: workOrderId
  //       // })
  //     } else {
  //       this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;
  //       // this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
  //       //   workOrderIndex: workOrderIndex,
  //       //   workOrderId: workOrderId
  //       // })


  //       // Alternating Green Coloration
  //       if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
  //         this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
  //       }
  //     }

  //     if(workOrderTimeStretch > 0) {
  //       workOrderTimeStretch -= 30;
  //       startHalf = 1;


  //       this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
  //         workOrderIndex: workOrderIndex,
  //         workOrderId: workOrderId
  //       });

  //       if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
  //         this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked";
  //         // this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
  //         //   workOrderIndex: workOrderIndex,
  //         //   workOrderId: workOrderId
  //         // })
  //       } else {
  //         this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;
  //         // this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
  //         //   workOrderIndex: workOrderIndex,
  //         //   workOrderId: workOrderId
  //         // })
        
  //         // if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
  //           this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
  //         // }
  //       }
  //     }

  //     // "b8ba2bd777e8"
  //     workSlot += 1;

  //     if(workSlot > 7) {
  //       workSlot = 0;
  //       startHalf = 0;

  //       if(workOrderStartDay < 6) {
  //         workOrderStartDay++;

  //         if(workOrderStartDay > initDayStart) {
  //           workOrderTimeStretch -= dayTimeStretchSkip;

  //           if(workOrderTimeStretch <= 0) {
  //             break;
  //           }
  //         }
  //       } else {
  //         forceWorkOrderChartingComplete = true;
  //         break;
  //       }
  //     }
  //   }

  // }

  private async chartEmployeeWorkOrder(employeeId: string, employeeIndex: number): Promise<void> {
    console.log("Individual W/O Charting");
    this.resetEmployeePlain(employeeIndex);

    for(let index = 0; index < this.workOrders.length; index++) {
      // let employeeId: string = this.workOrders[index].technicianId;
      let assigned: boolean = false;
      let checkDate: Date = null;
      let checkTime: number = 0;
      let initialTime: number = this.workOrders[index].dateScheduled.getTime();
      let workOrderStartMinutes: number = this.workOrders[index].dateScheduled.getMinutes();
      let workOrderStartHours: number = this.workOrders[index].dateScheduled.getHours();
      let workOrderStartDay: number = this.workOrders[index].dateScheduled.getDay();
      
      let workOrderIndex: number = index;
      let workOrderId: string = this.workOrders[index].workOrderId;

      let startHalfBool: boolean = workOrderStartMinutes / 60 == 0;
      let workSlot = workOrderStartHours;
      let isWorkSlotAdjusted: boolean = false;

      let forceWorkOrderChartingComplete: boolean = false;

      let focusShift: number = this.employeesShiftVisibility[employeeIndex];
      let initChartingStart: boolean = false;
      let forceShift: number = null;
      let initDayStart: number = workOrderStartDay;
      let initStartShift: number = null;
      let workOrderTimeStretch: number = 0;
      let dayTimeStretchSkip: number = 960;
      let initWorkOrderTimeStretch: number = this.workOrders[index].timeStretch;
      let adjOrderTimeStretch: number = 0;
      // let adjOrderTimeStretch: number = initWorkOrderTimeStretch - ( (this.employeesShiftVisibility[employeeIndex] + 1) * 60) * 8;


      // Day Shift Time Adjustment
      if(workOrderStartHours >= 9 && workOrderStartHours <= 17) {
        initStartShift = 0;
        let hourAdjuster: number = 17;
        let minuteAdjuster: number = 0;

        adjOrderTimeStretch = this.adjustStartTime(checkDate, checkTime, initialTime, adjOrderTimeStretch, initWorkOrderTimeStretch, hourAdjuster, minuteAdjuster, index, employeeIndex);
      }

      // Overtime Shift Time Adjustment
      else if( (workOrderStartHours >= 18 && workOrderStartHours <= 24) || workOrderStartHours == 0) {
        initStartShift = 1;
        let hourAdjuster: number = 24;
        let minuteAdjuster: number = -60;

        adjOrderTimeStretch = this.adjustStartTime(checkDate, checkTime, initialTime, adjOrderTimeStretch, initWorkOrderTimeStretch, hourAdjuster, minuteAdjuster, index, employeeIndex);
      }

      // Night Shift Time Adjustment
      else if(workOrderStartHours >= 1 && workOrderStartHours <= 8) {
        initStartShift = 2;
        let hourAdjuster: number = 8;
        let minuteAdjuster: number = 60;

        adjOrderTimeStretch = this.adjustStartTime(checkDate, checkTime, initialTime, adjOrderTimeStretch, initWorkOrderTimeStretch, hourAdjuster, minuteAdjuster, index, employeeIndex);
      }

      if(initStartShift == this.employeesShiftVisibility[employeeIndex]) {
        initChartingStart = true;
      }


      if(adjOrderTimeStretch > 0 && !initChartingStart) {
        workOrderTimeStretch = Math.abs(adjOrderTimeStretch);
        forceShift = this.employeesShiftVisibility[employeeIndex];

        if(initStartShift == 0 && forceShift == 2) {
          workOrderStartDay++;
        }

        else if(initStartShift == 1 && forceShift != 1) {
          workOrderStartDay++;
        }

        // else if(initStartShift == 2 && forceShift > initStartShift) {
        //   workOrderStartDay++;
        // }
      } else {
        workOrderTimeStretch = initWorkOrderTimeStretch;
      }

      // Temp. conditional for bug fixing
      if(workOrderId == "b8ba2bd777e8") {
        // console.log("Problem WO: ", this.workOrders[index]);
      }


      await this.employeeAssignedWorkOrderChecker(employeeId, this.workOrders[index]).then( (isAssigned: boolean) => {
        assigned = isAssigned;
      });

      if(assigned) {

        // for(let workSlotIndex: number = 0; workSlotIndex < this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0].length; workSlotIndex++) {
          this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount++;
        // }


        // Is the start position on the first row or second row
        if(startHalfBool) {

          let startHalf = 0;
          let cssClass = "";

          if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
            cssClass = "altScheduled";
          }

          while(workOrderTimeStretch > 0) {
            
            workOrderTimeStretch -= 30;
            startHalf = 0;

            // Day Shift
            if(this.employeesShiftVisibility[employeeIndex] == 0 && (workOrderStartHours >= 9 && workOrderStartHours <= 17 || forceShift == 0) ) {

              if(workSlot < 8 || !isWorkSlotAdjusted) {

                if(!isWorkSlotAdjusted) {
                  if(forceShift != null && workOrderStartDay >= initDayStart && !initChartingStart ) {
                    workSlot = 0;
                  } else {
                    workSlot -= 9;
                  }

                  isWorkSlotAdjusted = true;
                }

                if(workSlot >= 8) {
                  console.log("Error", workOrderId);
                  console.log("Error", this.workOrders[index]);
                  continue;
                }

                this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                  workOrderIndex: workOrderIndex,
                  workOrderId: workOrderId
                });

                if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked";
                  // this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                  //   workOrderIndex: workOrderIndex,
                  //   workOrderId: workOrderId
                  // })
                } else {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;
                  // this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                  //   workOrderIndex: workOrderIndex,
                  //   workOrderId: workOrderId
                  // })


                  // Alternating Green Coloration
                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                  }
                }

                if(workOrderTimeStretch > 0) {
                  workOrderTimeStretch -= 30;
                  startHalf = 1;


                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                    workOrderIndex: workOrderIndex,
                    workOrderId: workOrderId
                  });

                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked";
                    // this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                    //   workOrderIndex: workOrderIndex,
                    //   workOrderId: workOrderId
                    // })
                  } else {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;
                    // this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                    //   workOrderIndex: workOrderIndex,
                    //   workOrderId: workOrderId
                    // })
                  
                    // if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                      this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                    // }
                  }
                }

                // "b8ba2bd777e8"
                workSlot += 1;

                if(workSlot > 7) {
                  workSlot = 0;
                  startHalf = 0;

                  if(workOrderStartDay < 6) {
                    workOrderStartDay++;

                    if(workOrderStartDay > initDayStart) {
                      workOrderTimeStretch -= dayTimeStretchSkip;
        
                      if(workOrderTimeStretch <= 0) {
                        break;
                      }
                    }
                  } else {
                    forceWorkOrderChartingComplete = true;
                    break;
                  }
                }
              }
            }

            // Overtime Shift
            else if(this.employeesShiftVisibility[employeeIndex] == 1 && ( ( (workOrderStartHours >= 18 && workOrderStartHours <= 24) || workOrderStartHours == 0) || forceShift == 1) ) {


              if(workSlot < 8 || !isWorkSlotAdjusted) {

                // Adjusting the 24 hour clock start to be reflected in an 8 hour blocked start window
                if(!isWorkSlotAdjusted) {
                  if(forceShift != null && (workOrderStartDay >= initDayStart) && !initChartingStart ) {
                    workSlot = 0;
                  } else {

                    if(workSlot == 0) {
                      workSlot = 7;
                    } else {
                      workSlot -= 18;
                    }

                  }
  
                  isWorkSlotAdjusted = true;
                }

                this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                  workOrderIndex: workOrderIndex,
                  workOrderId: workOrderId
                });

                if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked"
                } else {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;

                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                  }
                }

                if(workOrderTimeStretch > 0) {
                  workOrderTimeStretch -= 30;
                  startHalf = 1;

                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                    workOrderIndex: workOrderIndex,
                    workOrderId: workOrderId
                  });

                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked"
                  } else {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;
                  
                    if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                      this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                    }
                  }
                }

                workSlot += 1;

                if(workSlot > 7) {
                  workSlot = 0;
                  startHalf = 0;

                  if(workOrderStartDay < 6) {
                    workOrderStartDay++;

                    if(workOrderStartDay > initDayStart) {
                      workOrderTimeStretch -= dayTimeStretchSkip;
        
                      if(workOrderTimeStretch <= 0) {
                        break;
                      }
                    }
                  } else {
                    forceWorkOrderChartingComplete = true;
                    break;
                  }
                }

              }

            }

            // Night Shift
            else if(this.employeesShiftVisibility[employeeIndex] == 2 && ( workOrderStartHours >= 1 && workOrderStartHours <= 8 || forceShift == 2)) {

              if(workSlot < 8 || !isWorkSlotAdjusted) {

                if(!isWorkSlotAdjusted) {

                  if(forceShift != null && (workOrderStartDay >= initDayStart) && !initChartingStart ) {
                    workSlot = 0;
                  } else {
                    workSlot -= 1;
                  }

                  isWorkSlotAdjusted = true;
                }

                this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                  workOrderIndex: workOrderIndex,
                  workOrderId: workOrderId
                });

                if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked"
                } else {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;

                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                  }
                }

                if(workOrderTimeStretch > 0) {
                  workOrderTimeStretch -= 30;
                  startHalf = 1;

                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                    workOrderIndex: workOrderIndex,
                    workOrderId: workOrderId
                  });

                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked"
                  } else {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;
                  
                    if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                      this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                    }
                  }
                }

                workSlot += 1;

                if(workSlot > 7) {
                  workSlot = 0;
                  startHalf = 0;

                  if(workOrderStartDay < 6) {
                    workOrderStartDay++;

                    if(workOrderStartDay > initDayStart) {
                      workOrderTimeStretch -= dayTimeStretchSkip;
        
                      if(workOrderTimeStretch <= 0) {
                        break;
                      }
                    }
                  } else {
                    forceWorkOrderChartingComplete = true;
                    break;
                  }
                }

              }

            }
            
          }

          isWorkSlotAdjusted = false;

          if(forceWorkOrderChartingComplete) {
            continue;
          }
        } else { 
          let startHalf = 1;

          while(workOrderTimeStretch > 0) {
            workOrderTimeStretch -= 30;
            startHalf = 1;
            let cssClass = "";

            if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
              cssClass = "altScheduled";
            }

            // Day Shift
            if(this.employeesShiftVisibility[employeeIndex] == 0 && (workOrderStartHours >= 9 && workOrderStartHours <= 17 || forceShift == 0)) {
              if(workSlot < 8 || !isWorkSlotAdjusted) {
                if(!isWorkSlotAdjusted) {
                  if(forceShift != null && (workOrderStartDay >= initDayStart) && !initChartingStart ) {
                    workSlot = 0;
                  } else {
                    workSlot -= 9;
                  }

                  isWorkSlotAdjusted = true;
                }

                if(workSlot >= 8) {
                  console.log("Error", workOrderId);
                  console.log("Error", this.workOrders[index]);
                  continue;
                }

                this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                  workOrderIndex: workOrderIndex,
                  workOrderId: workOrderId
                });

                if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked";
                } else {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;

                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                  }
                }

                if(workOrderTimeStretch > 0) {
                  startHalf = 0;
                  workOrderTimeStretch -= 30;

                  workSlot += 1;

                  if(workSlot > 7) {
                    workSlot = 0;
                    startHalf = 0;

                    if(workOrderStartDay < 6) {
                      workOrderStartDay++;

                      if(workOrderStartDay > initDayStart) {
                        workOrderTimeStretch -= 960;
          
                        if(workOrderTimeStretch <= 0) {
                          break;
                        }
                      }
                    } else {
                      forceWorkOrderChartingComplete = true;
                      break;
                    }
                  }

                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                    workOrderIndex: workOrderIndex,
                    workOrderId: workOrderId
                  });

                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked";
                  } else {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;

                    if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                      this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                    }
                  }
                }

                
              }
            }

            // Overtime Shift
            else if(this.employeesShiftVisibility[employeeIndex] == 1 && ( ( (workOrderStartHours >= 18 && workOrderStartHours <= 24) || workOrderStartHours == 0) || forceShift == 1) ) {

              if(workSlot < 8 || !isWorkSlotAdjusted) {
                // Adjusting the 24 hour clock start to be reflected in an 8 hour blocked start window
                if(!isWorkSlotAdjusted) {
                  if(forceShift != null && (workOrderStartDay >= initDayStart) && !initChartingStart ) {
                    workSlot = 0;
                  } else {
                    if(workSlot == 0) {
                      workSlot = 7;
                    } else {
                      workSlot -= 18;
                    }
                  }

                  isWorkSlotAdjusted = true;
                }

                this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                  workOrderIndex: workOrderIndex,
                  workOrderId: workOrderId
                });

                if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked";
                } else {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;

                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                  }
                }

                if(workOrderTimeStretch > 0) {
                  startHalf = 0;
                  workOrderTimeStretch -= 30;

                  workSlot += 1;

                  if(workSlot > 7) {
                    workSlot = 0;
                    startHalf = 0;

                    if(workOrderStartDay < 6) {
                      workOrderStartDay++;

                      if(workOrderStartDay > initDayStart) {
                        workOrderTimeStretch -= 960;
          
                        if(workOrderTimeStretch <= 0) {
                          break;
                        }
                      }
                    } else {
                      forceWorkOrderChartingComplete = true;
                      break;
                    }
                  }

                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                    workOrderIndex: workOrderIndex,
                    workOrderId: workOrderId
                  });

                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked";
                  } else {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;

                    if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                      this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                    }
                  }
                }
              }
            }

            // Night Shift
            else if(this.employeesShiftVisibility[employeeIndex] == 2 && workOrderStartHours >= 1 && workOrderStartHours <= 8 || forceShift == 2) {
              if(workSlot < 8 || !isWorkSlotAdjusted) {
                if(!isWorkSlotAdjusted) {
                  if(forceShift != null && (workOrderStartDay >= initDayStart) && !initChartingStart ) {
                    workSlot = 0;
                  } else {
                    workSlot -= 1;
                  }

                  isWorkSlotAdjusted = true;
                }

                this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                  workOrderIndex: workOrderIndex,
                  workOrderId: workOrderId
                });

                if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked";
                } else {
                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;

                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                  }
                }

                if(workOrderTimeStretch > 0) {
                  startHalf = 0;
                  workOrderTimeStretch -= 30;

                  workSlot += 1;

                  if(workSlot > 7) {
                    workSlot = 0;
                    startHalf = 0;

                    if(workOrderStartDay < 6) {
                      workOrderStartDay++;

                      if(workOrderStartDay > initDayStart) {
                        workOrderTimeStretch -= 960;
          
                        if(workOrderTimeStretch <= 0) {
                          break;
                        }
                      }
                    } else {
                      forceWorkOrderChartingComplete = true;
                      break;
                    }
                  }

                  this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].workOrders.push({
                    workOrderIndex: workOrderIndex,
                    workOrderId: workOrderId
                  });

                  if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled == true) {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = "doubleBooked";
                  } else {
                    this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].isScheduled = true;

                    if(this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][0][0].workOrderCount % 2 == 0) {
                      this.employeesWorkOrderVisibility[employeeIndex][workOrderStartDay][startHalf][workSlot].cssClass = cssClass;
                    }
                  }
                }
              }
            }

          }

          isWorkSlotAdjusted = false;

          if(forceWorkOrderChartingComplete) {
            continue;
          }
        }
      }

    }

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  chartWorkOrders(): void {
    console.log("Charting W/O's: ", this.workOrders);

    if(this.workOrders.length > 0)  {
      for(let index = 0; index < this.workOrders.length; index++) {

        if(this.workOrders[index].assignedStaff == undefined) {
          this.workOrders[index].assignedStaff = [];
        }

        if(this.workOrders[index].assignedTeams == undefined) {
          this.workOrders[index].assignedTeams = [];
        }

        let employeeId: string = this.workOrders[index].technicianId;
        let staff: Array<string> = this.workOrders[index].assignedStaff;
        let teams: Array<string> = this.workOrders[index].assignedTeams;

        let workOrderIndex: number = null;
        let workOrderId: string = this.workOrders[index].workOrderId;

        for(let searchIndex = 0; searchIndex < this.employeeGroup.length; searchIndex++) {
          // this.resetEmployeePlain(searchIndex);

          if(this.employeeGroup[searchIndex].id == employeeId) {

            this.chartEmployeeWorkOrder(employeeId, searchIndex).then( () => {
              this.setEmployeeWorkSlotTooltips(searchIndex);
            });
            
          }

        }

          for(let staffId of staff) {
            let searchIndex: number = this.employeeGroup.findIndex( (profile: Profile) => { return profile.id == staffId });

            if(searchIndex > -1) {
                    
              if(this.employeeGroup[searchIndex].id == staffId && staffId != employeeId) {

                this.chartEmployeeWorkOrder(staffId, searchIndex).then( () => {
                  this.setEmployeeWorkSlotTooltips(searchIndex);
                });

              }

            }

          }

          for(let teamId of teams) {

            this.navigatorService.getGroupPermission(teamId).then( (team: GroupPermissions) => {

              if(team != undefined && team != null) {

                for(let teamMember of team.employeeIds) {

                  let searchIndex: number = this.employeeGroup.findIndex( (profile: Profile) => { return profile.id == teamMember });

                  if(searchIndex > -1) {

                    if(this.employeeGroup[searchIndex].id == teamMember && teamMember != employeeId) {
                      
                      this.chartEmployeeWorkOrder(teamMember, searchIndex).then( () => {
                        this.setEmployeeWorkSlotTooltips(searchIndex);
                      });

                    }

                  }

                }

              }

            });

          }

        

      }
    } else {
      for(let searchIndex = 0; searchIndex < this.employeeGroup.length; searchIndex++) {
        this.resetPlain();
      }
    }

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  addWorkSlot($event) {
    // console.log("Work Slot Clicked!");
  }

  enterEvent(segment, $event, segmentElement): void {
    // console.log("Mouse Enter!");
    // console.log(segment);
  }

  autoShiftChange(): void {
    let shift = this.employeesShiftVisibility[0];

    for(let index: number = 0; index < this.employeeGroup.length; index++) {
      this.setShift(shift, this.employeeGroup[index].id, index);
    }
  }

  setShift(shift: number, employeeId:string, employeeIndex: number): void {
    switch(shift) {
      case 0:
        this.employeesShiftVisibility[employeeIndex] = 1;
        break;
      case 1:
        this.employeesShiftVisibility[employeeIndex] = 2;
        break;
      case 2:
        this.employeesShiftVisibility[employeeIndex] = 0;
        break;
    }

    this.resetEmployeePlain(employeeIndex);
    this.chartEmployeeWorkOrder(employeeId, employeeIndex);

    this.setEmployeeWorkSlotTooltips(employeeIndex);
    this.timeTrackerPosition();
  }

  changeShift(employeeId: string, employeeIndex: number): void {
    switch(this.employeesShiftVisibility[employeeIndex]) {
      case 0:
        this.employeesShiftVisibility[employeeIndex] = 1;
        break;
      case 1:
        this.employeesShiftVisibility[employeeIndex] = 2;
        break;
      case 2:
        this.employeesShiftVisibility[employeeIndex] = 0;
        break;
    }

    this.resetEmployeePlain(employeeIndex);
    this.chartEmployeeWorkOrder(employeeId, employeeIndex);

    this.setEmployeeWorkSlotTooltips(employeeIndex);
    this.timeTrackerPosition();
  }

  // Temp Disabled
  // addEvent(): void {
  //   this.events = [
  //     ...this.events,
  //     {
  //       title: 'New event',
  //       start: startOfDay(new Date()),
  //       end: endOfDay(new Date()),
  //       color: colors.red,
  //       draggable: true,
  //       resizable: {
  //         beforeStart: true,
  //         afterEnd: true,
  //       },
  //     },
  //   ];
  // }

  contextMenuAddEvent(date: Date): void {
    this.events = [];

    this.refresh.next();
  }

  // LEFT FOR EXAMPLES DURING DEVELOPMENT
  // DELETE FOR PRODUCTION
  // contextMenuAddEvent(date: Date): void {
  //   this.events.push({
  //     start: date,
  //     title: 'New event',
  //     color: colors.red,
  //   });
  //   this.refresh.next();
  // }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events[this.groupOptionSelected] = this.events[this.groupOptionSelected].filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    if(view == CalendarView.Month) {
    }
    
    this.view = view;
  }

  closeOpenMonthViewDay() {
    if(this.view == CalendarView.Month) {
    }

    this.activeDayIsOpen = false;
  }

  public tooltipEnter(): void {

  }

  public tooltipOut(): void {

  }

  public setEmployeeWorkSlotTooltips(employeeIndex: number): void {
    for(let dayIndex = 0; dayIndex < this.employeesWorkOrderVisibility[employeeIndex].length; dayIndex++) {
      for(let halfIndex = 0; halfIndex < this.employeesWorkOrderVisibility[employeeIndex][dayIndex].length; halfIndex++) {
        for(let workSlotIndex = 0; workSlotIndex < this.employeesWorkOrderVisibility[employeeIndex][dayIndex][halfIndex].length; workSlotIndex++) {
          this.employeesWorkOrderVisibility[employeeIndex][dayIndex][halfIndex][workSlotIndex].time = this.setWorkSlotTooltip(employeeIndex, workSlotIndex, halfIndex);
        
          if(this.employeesWorkOrderVisibility[employeeIndex][dayIndex][halfIndex][workSlotIndex].workOrders.length >= 1) {
            let workOrders: Array<any> = this.employeesWorkOrderVisibility[employeeIndex][dayIndex][halfIndex][workSlotIndex].workOrders;

            for(let workOrderIndex: number = 0; workOrderIndex < workOrders.length; workOrderIndex++) {
              let workOrder: WorkOrder = this.workOrders[workOrders[workOrderIndex].workOrderIndex];
              let workOrderCount: number = workOrderIndex + 1;

              this.employeesWorkOrderVisibility[employeeIndex][dayIndex][halfIndex][workSlotIndex].time += "\n" + workOrderCount + ": " + workOrder.description;
            }
          }

        }
      }
    }
  }

  public setWorkSlotTooltip(employeeIndex: number, workSlotIndex: number, halfIndex: number): string {

    let time: Date = new Date;
    let hours: number = workSlotIndex;
    let formattedTime: string = "";

    if(this.employeesShiftVisibility[employeeIndex] == 2) {
      hours += 1;
    } 
    
    else if(this.employeesShiftVisibility[employeeIndex] == 1) {
      hours += 17
    }
    
    else {
      hours += 9;
    }

    if(halfIndex == 0) {
      time.setMinutes(0);
    } else {
      time.setMinutes(30);
    }

    time.setHours(hours);

    return this.datePipe.transform(time, 'shortTime');
  }

  public getWorkSlotTooltip(employeeIndex: number, dayIndex: number, workSlotIndex: number, halfIndex: number): string {
    return this.employeesWorkOrderVisibility[employeeIndex][dayIndex][halfIndex][workSlotIndex].time;
  }

  public updateGanttExpandCollapse(expand: boolean): void {
    this.ganttExpand = expand;
  }

  public openAddWorkOrderDialog(date: any, employeeId: string, employeeIndex: number) {
    this.addWorkOrderDialogRef = this.dialog.open(
      VendorAddWorkOrderDialogComponent,
      {
        data: {
          date: date,
          assignedId: employeeId,
          address: this.incomingAddress
        }
      }
    );

    this.addWorkOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {

      if(data.isCompleted) {
        console.log("Work Order Completed");

        this.workOrders.push(data.workOrderData);
        this.navigatorService.updateWorkOrder(data.workOrderData);

        this.chartEmployeeWorkOrder(employeeId, employeeIndex);
        this.emitWorkOrderUpdate.emit(this.workOrders);
      }

      // this.files.push({ name, content: ''});
    });

    

    // this.addWorkOrderDialogRef.backdropClick().pipe(
    //   filter(data => data)
    // ).subscribe(data => {
    //   console.log("Dialog Closed");

    //   // this.files.push({ name, content: ''});
    // })
  }

  public openWorkOrderDialog(workOrderIndex: number, workOrderId: string, employeeId: string, employeeIndex: number) {

    if(this.workOrders[workOrderIndex].workOrderId != workOrderId) {
      console.error("Work Order ID Doesn't Match Work Order Being Opened");
      return;
    }

    this.openWorkOrderDialogRef = this.dialog.open(
      VendorWorkOrderDialogComponent,
      {
        data: {
          workOrderData: this.workOrders[workOrderIndex],
          requestId: employeeId
        }
      }
    );

    this.openWorkOrderDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed", data);

      this.navigatorService.updateWorkOrder(data);

      // this.files.push({ name, content: ''});
    })
  }

  public openWorkOrderAuditDialog(id: string): void {

    this.navigatorService.getWorkOrder(id).then( (workOrder: WorkOrder) => {

      if(workOrder != undefined && workOrder != null) {

        this.workAuditTrackerDialogRef = this.dialog.open(
          VendorWorkAuditTrackerDialogComponent,
          { data: workOrder }
        );

        this.workAuditTrackerDialogRef.beforeClosed().subscribe(data => {
          console.log("Before Closed: ", data);
        });
        
        this.workAuditTrackerDialogRef.afterClosed().pipe(
          filter(data => data)
        ).subscribe(data => {
          console.log("Dialog Closed: ", data);
    
        });

      }

    });

  }

  /** Builds and returns a new work order. */
  private createTestWorkOrder(technicianId: string): WorkOrder {
    const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
        NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

    let custPhone: string = null;
    let dateContactLog: Array<Date> = [new Date];
    let followOnMaintence: Array<string> = ["Fix Broken Stuff", "Refix Broken Stuff", "Try Not to Break New Stuff"];
    // let workOrderNumber:number = id + 123;
  
    return {
      technicianId: "1a23bcff",
      originatorId: this.navigatorService.getProfileId(),
      workOrderId: this.navigatorService.generateKey(this.keyTypes.WORK_ORDER_ID),
      companyId: this.navigatorService.getCompanyId(),

      originationDate: new Date,
      custPocName: name,
      custPocPhone: 1234567890,
      custPocEmail: 'null@gmail.com',
      custFirstName: name,
      custMiddleName: '',
      custLastName: 'Last Name',
      custEmail: 'null@gmail.com',
      custPhone: custPhone,
      custAddress: {
        street: "10945 Estate Ln",
        city: "Dallas",
        state: "TX",
        zip: 75238
      },
      custBillingAddress: {
        street: "10945 Estate Ln",
        city: "Dallas",
        state: "TX",
        zip: 75238
      },
      custBillAmount: 45,
      description: 'Customer Tales of Broken Stuff. Customer Tales of Broken Stuff. Customer Tales of Broken Stuff',
      dateContactLog: dateContactLog,
      dateInitialContact: new Date,
      dateScheduled: new Date,
      priority: 3,
      skillLvl: 2,
      specialNotes: "",
      status: 1,
      foreign_work_orders: followOnMaintence,
      isAutoGenerated: false,
      isCompleted: false,
      isPaid: false,
      customFields: []
    };

  }
}
import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NavigatorService } from '../../../services/vendor/navigator.service'
import { InventoryItem, InventoryTrackedFields } from '../../../data-models/models'

import { VendorAddInventoryItemDialogComponent } from '../../../shared/professional/vendor-add-inventory-item-dialog/vendor-add-inventory-item-dialog.component';
import { BLACK_ON_WHITE_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';

export enum ViewsEnum {
  CMMS = 0,
  LOCAL_BENCHSTOCK = 1
}

@Component({
  selector: 'app-vendor-inventory-menu',
  templateUrl: './vendor-inventory-menu.component.html',
  styleUrls: ['./vendor-inventory-menu.component.scss'],
})

export class VendorInventoryMenuComponent implements OnInit, AfterViewInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() pages: Array<any>;
  @Input() viewMode: number = 0;

  public viewTypes: any = ViewsEnum;
  public pageDirect: string = 'inventory';
  public focusedPage: any = null
  public trackedFieldsState: string = "read";
  public trackedFields: Array<InventoryTrackedFields> = [];
  public inventory: Array<InventoryItem> = [];
  addInventoryItemDialogRef: MatDialogRef<VendorAddInventoryItemDialogComponent>;

  private inventorySubscriber: Subscription;

  constructor(public appService:AppService, private navigator: NavigatorService, public dialog: MatDialog) { 
    // this.navigator.getTrackedFields().subscribe(data  => {
    //   if(data) {
    //     this.trackedFields = data;
    //   }
    // });

    
  }

  ngOnInit() {
    
    if(this.viewMode == this.viewTypes.CMMS) {
      this.pageDirect = 'inventory';
    }

    else if(this.viewMode == this.viewTypes.LOCAL_BENCHSTOCK) {
      this.pageDirect = 'local-benchstock';
    }

  }

  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(){ 
    if(this.inventorySubscriber) {
      this.inventorySubscriber.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.viewMode == this.viewTypes.CMMS) {
      this.pageDirect = 'inventory';
    }

    else if(this.viewMode == this.viewTypes.LOCAL_BENCHSTOCK) {
      this.pageDirect = 'local-benchstock';
    }
  }

  subscribeToInventory(): void {
    this.inventorySubscriber = this.navigator.getBaseInventory().subscribe(data => { 
      if(data.length == 0) {
        this.inventory.length = 0;
        return false;
      } 

      this.inventory = data; 
    });
  }

  public filterPages(group: number): Array<any> {
    return this.pages.filter(page => page.group == group);
  }

  public sidenavToggle(): void{
    this.onMenuIconClick.emit();
  }

  public add(event: Event): void {
    let newField: InventoryTrackedFields = {
      id: 10,
      title: "Blank",
      value: "",
      isDisplayed: true
    }

    // this.navigator.addTrackedField(newField);
  }

  public edit(event: Event): void {
    console.log("Edit Clicked!");

    switch(this.trackedFieldsState) {
      case "edit":
        this.trackedFieldsState = "read";
        break;
      case "read":
        this.trackedFieldsState = "edit";
        break;
      default:
        break;
    }
  }

  public softDelete(event: Event): void {
    let checkBoxes: any = document.querySelectorAll("mat-checkbox.inventoryMenuCheckBox.mat-accent.mat-checkbox-checked");

    for(let checkBoxIndex = 0; checkBoxIndex < checkBoxes.length; checkBoxIndex++) {
      // this.navigator.softDeleteTrackedField(Number(checkBoxes[checkBoxIndex].attributes.id.value));
    }
  }

  public secureDelete(event: Event): void {
    console.log("Secure Delete Clicked!");
  }

  public saveEdit(event: Event): void {
    let editFields: any = document.querySelectorAll(".editFieldLocator");

    for(let fieldIndex = 0; fieldIndex < editFields.length; fieldIndex++) {

      // this.navigator.editTrackedFieldTitle(editFields[fieldIndex].id, editFields[fieldIndex].value);
    }

    this.trackedFieldsState = "read";
  }

  public save(event: Event): void {
    console.log("Save Clicked!");
  }

  public openAddInventoryItemDialog() {
    this.addInventoryItemDialogRef = this.dialog.open(VendorAddInventoryItemDialogComponent);

    this.addInventoryItemDialogRef.afterClosed().pipe(
      filter(data => data)
    ).subscribe(data => {
      console.log("Dialog Closed");

      // this.files.push({ name, content: ''});
    })
  }


  eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
}
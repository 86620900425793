import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Settings, AppSettings } from '../../app.settings';
import { ViewportScroller } from '@angular/common';
import { VendorWorkAuditTrackerDialogComponent } from '../professional/vendor-work-audit-tracker-dialog/vendor-work-audit-tracker-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { InvoiceSettings, PaymentSettings, QuickActionBtn, QuickActionMenu, QuickActionSubMenu, StripePaymentDetails, StripePaymentLineItems, Timesheet, WorkOrder, WorkOrderQuickAction, WorkOrderService } from 'src/app/data-models/models';
import { NavigatorService, workOrderStatusTypes } from 'src/app/services/vendor/navigator.service';
import { CompanyTimesheetsService } from 'src/app/services/vendor/company-timesheets.service';
import { VendorWorkOrderDialogComponent } from '../professional/vendor-work-order-dialog/vendor-work-order-dialog.component';
import { VendorWorkOrderQuickActionDialogComponent } from '../professional/vendor-work-order-quick-action-dialog/vendor-work-order-quick-action-dialog.component';
import { PaymentHandlerService } from 'src/app/services/vendor/payment-handler.service';
import { timeStamp } from 'console';
import { HttpClient } from '@angular/common/http';
import { TwilioHandlerService } from 'src/app/services/vendor/twilio-handler.service';
import { VendorContactDialogComponent } from '../professional/vendor-contact-dialog/vendor-contact-dialog.component';
import { PlatformService } from 'src/app/services/vendor/platform.service';


export enum quickActionCategoryEnum {
  // WO
  ATTACHED_PARTS = 0,
  STATUS = 1,
  SERVICE = 2,
  PAYMENT = 3,
  AUDIT = 4,
  WORK_ORDER = 5,

  // Ops
  DIALPAD = 6,
  ANSWER_CALL = 7,
  HANGUP_CALL = 8,
  DECLINE_CALL = 9,
  TEXT_MESSAGE = 10,
  CRM_HISTORY = 11,
  VOICEMAIL = 12,
  CONTACTS = 13,
  RECENT_CALLS = 14,

  MENU_CHANGE = 998,
  BLANK = 999
}

export enum quickActionSliderEnum {
  WORK_ORDER = 0,
  PHONE = 1,

  BLANK = 999
}

interface SliderWorkOrder {
  id: string,
  status: number,
  slideIndex: number,
  offset: string,
  statusIcon: string,
  statusColor
}



@Component({
  selector: 'app-menu-gooey',
  templateUrl: './menu-gooey.component.html',
  styleUrls: ['./menu-gooey.component.scss']
})

export class MenuGooeyComponent implements OnInit, AfterViewInit {

  public quickActionCategories: any = quickActionCategoryEnum;
  public quickActionSliders: any = quickActionSliderEnum;

  public workOrderStatusTypes: any = workOrderStatusTypes;
  public menuPageFocus: number = 0;

  public activeSubPage: number | null = null

  public slideOutMenuExpanded: boolean = false;
  public slideOutMenuMinimized: boolean = false;

  @Input('quickActionMenuExpanded') quickActionMenuExpanded: boolean = false;
  @Input('demo') demo: boolean = false;
  @Input('parentID') parentID: string = "platform";
  @Input('quickActionMenu') quickActionMenu: Array<QuickActionMenu> = [ ];

  private advancedVersion: string = "2.0.0";
  public authorizedLevelV2Features: boolean = this.platformService.versionChecker(this.advancedVersion);

  public timesheet: Timesheet = {
    sheet_id: '',
    employee_id: '',
    company_id: '',
    hourly_payrate: 0,
    day_clock_in: [],
    day_clock_out: [],
    break_clock_in: [],
    break_clock_out: []
  };

  public clockedIn: boolean = false;
  public clockBreak: boolean = false;
  public mainMenuExpanded: boolean = false;

  public subMenus: Array<QuickActionSubMenu> = [];

  private workAuditTrackerDialogRef: MatDialogRef<VendorWorkAuditTrackerDialogComponent>;
  private workOrderDialogRef: MatDialogRef<VendorWorkOrderDialogComponent>;
  private workOrderQuickActionDialogRef: MatDialogRef<VendorWorkOrderQuickActionDialogComponent>;

  private openVendorContactDialogRef: MatDialogRef<VendorContactDialogComponent>;
  
  public sliderWorkOrders: Array<SliderWorkOrder> = [];
  private workOrders: Array<WorkOrder> = [];

  public focusSlideIndex: number = 0;
  public activeSlide: string = "";
  public focusedWorkOrderId: string = "";

  constructor(
    private platformService: PlatformService,
    private http: HttpClient,
    public appSettings:AppSettings, 
    private dialog: MatDialog, 
    private navigatorService: NavigatorService,
    private companyTimesheets: CompanyTimesheetsService,
    private twilioHandler: TwilioHandlerService) {
  }

  ngOnInit() {

    this.initializeDefaultMenu();
    this.getDailyTimesheet();

  }

  ngOnDestroy(){    
    
  }

  ngAfterViewInit() {
    this.getDailyWorkOrders();
  }

  private initializeDefaultMenu(): void {

    if(this.quickActionMenu.length == 0) {

      this.quickActionMenu.push({
        id: "field_technician",
        title: "Field Technician",
        slide_menu: this.quickActionSliders.WORK_ORDER,
        action_btns: [
  
          {
            category: this.quickActionCategories.ATTACHED_PARTS,
            icon: "inventory_2"
          },
          {
            category: this.quickActionCategories.SERVICE,
            icon: "home_repair_service"
          },
          {
            category: this.quickActionCategories.BLANK,
            icon: ""
          },
          {
            category: this.quickActionCategories.AUDIT,
            icon: "web_stories"
          }, 
          {
            category: this.quickActionCategories.BLANK,
            icon: ""
          },
          {
            category: this.quickActionCategories.PAYMENT,
            icon: "local_atm"
          },
  
          {
            category: this.quickActionCategories.MENU_CHANGE,
            icon: "more_horiz"
          } 
          
        ]
      });

      if(this.authorizedLevelV2Features) {

        this.quickActionMenu.push({
          id: "ops_specialist",
          title: "Operations Specialist",
          slide_menu: this.quickActionSliders.PHONE,
          action_btns: [
            {
              category: this.quickActionCategories.VOICEMAIL,
              icon: "voicemail"
            },
            {
              category: this.quickActionCategories.TEXT_MESSAGE,
              icon: "question_answer"
            },
            {
              category: this.quickActionCategories.RECENT_CALLS,
              icon: "format_list_bulleted"
            },
            {
              category: this.quickActionCategories.DIALPAD,
              icon: "dialpad"
            },
            {
              category: this.quickActionCategories.CONTACTS,
              icon: "recent_actors"
            },
            {
              category: this.quickActionCategories.CRM_HISTORY,
              icon: "manage_search"
            },
    
            {
              category: this.quickActionCategories.MENU_CHANGE,
              icon: "more_horiz"
            } 
            
          ]
        });

      }

    }

  }

  private getDailyWorkOrders(): void {

    if(!this.demo) {
      this.navigatorService.getAsyncDailyTechWorkOrders(this.navigatorService.getProfileId()).then( (workOrders: Array<WorkOrder>) => {

        console.log("Technician Work Orders: ", workOrders);

        if(workOrders != undefined && workOrders != null) {
          this.workOrders = workOrders;

          this.syncWorkOrdersToSlider();
          this.slideToOpenWorkOrder();

        }

      });  
    } else {
      this.workOrders = [];

      this.syncWorkOrdersToSlider();
      this.slideToOpenWorkOrder();
    }

  }

  private getDailyTimesheet(): void {

    if(!this.demo) {
      this.companyTimesheets.getDailyTimesheet(this.navigatorService.getProfileId()).then( (timesheet: Timesheet) => {
        this.timesheet = timesheet;
        this.initializeClockVariables();
      });
    }

  }

  private initializeClockVariables(): void {

    if(this.timesheet != undefined && this.timesheet != null) {
      let latest_day_clockIn: number = 0;
      let latest_day_clockOut: number = 0;
      let latest_break_clockIn: number = 0;
      let latest_break_clockOut: number = 0;

      if(this.timesheet.day_clock_in.length > 0) {
        latest_day_clockIn = this.timesheet.day_clock_in[this.timesheet.day_clock_in.length -1].date.getTime();
      }

      if(this.timesheet.day_clock_out.length > 0) {
        latest_day_clockOut = this.timesheet.day_clock_out[this.timesheet.day_clock_out.length -1].date.getTime();
      }

      if(this.timesheet.break_clock_in.length > 0) {
        latest_break_clockIn = this.timesheet.break_clock_in[this.timesheet.break_clock_in.length -1].date.getTime();
      }

      if(this.timesheet.break_clock_out.length > 0) {
        latest_break_clockOut = this.timesheet.break_clock_out[this.timesheet.break_clock_out.length -1].date.getTime();
      }


      // Clocked In
      if(latest_day_clockOut < latest_day_clockIn) {
        this.clockedIn = true;
        this.clockBreak = false;
      }

      // On Break
      if(latest_day_clockIn < latest_break_clockOut) {
        this.clockedIn = false;
        this.clockBreak = true;
        
        // Off Break
        if(latest_day_clockIn < latest_break_clockIn) {
          this.clockedIn = true;
          this.clockBreak = false;
        }
      }

      // Clocked Out
      if(latest_day_clockOut >= latest_day_clockIn) {
        this.clockedIn = false;
        this.clockBreak = false;
      }

    }
  }

  private initializeQuickMenuVariables(): void {

    for(let subMenu of this.activeSlide)
    this.subMenus.push();

  }

  public clockIn(): void {
    this.clockedIn = true;

    if(this.clockBreak) {
      this.clockBreak = false;
      this.companyTimesheets.breakClockIn(this.navigatorService.getProfileId());
    } else {
      this.companyTimesheets.dayClockIn(this.navigatorService.getProfileId());
    }

  }

  public clockOut(): void {
    this.companyTimesheets.dayClockOut(this.navigatorService.getProfileId());
    this.clockedIn = false;
    this.clockBreak = false;
  }

  public takeBreak(): void {
    this.companyTimesheets.breakClockOut(this.navigatorService.getProfileId());
    this.clockBreak = true;
    this.clockedIn = false;
  }

  public syncWorkOrdersToSlider(): void {

    let adjustedSyncIndex: number = 0;
    this.sliderWorkOrders = [];

    if(this.workOrders.length % 2 == 0) {
      adjustedSyncIndex = Math.abs(Math.floor( (this.workOrders.length / 2)));
    } else {
      adjustedSyncIndex = Math.abs(Math.floor(this.workOrders.length / 2));
    }

    this.focusSlideIndex = adjustedSyncIndex;

    for(let workOrder of this.workOrders) {

      this.sliderWorkOrders.push({
        id: workOrder.workOrderId,
        status: workOrder.status,
        slideIndex: adjustedSyncIndex,
        offset: "5px",
        statusIcon: this.getWorkOrderStatusIcon(workOrder.status),
        statusColor: this.getWorkOrderStatusColor(workOrder.status)
      });

      if(adjustedSyncIndex == 0) {
        this.activeSlide = workOrder.workOrderId;
      }

      adjustedSyncIndex--;
    }

    if(this.sliderWorkOrders.length % 2 == 0) {

      this.sliderWorkOrders.push({
        id: null,
        status: 0,
        slideIndex: adjustedSyncIndex,
        offset: "5px",
        statusIcon: this.getWorkOrderStatusIcon(99),
        statusColor: this.getWorkOrderStatusColor(99)
      });

    }

  }

  public slideWorkOrder(element, slideIndex: number, activeSlideId: string): void {
    this.activeSlide = activeSlideId;
    this.focusedWorkOrderId = activeSlideId;

    let workOrderIndex: number = this.sliderWorkOrders.findIndex( (slide: SliderWorkOrder) => { return activeSlideId == slide.id });

    // let slider: any = document.getElementsByClassName("slideFocus")[0];

    // if(slider != undefined && slider != null) {
      let em: number = this.getEmPixels(document, element);
      // let em: number = 16;
      let slideCalc: number = (slideIndex * (3.75 * em) ) + ((slideIndex + 1) * (0.55 * em) );

      if(slideIndex <= 0) {
        slideCalc += -2;

        slideCalc += slideIndex * 2;
      }

      if(workOrderIndex > -1) {
        this.sliderWorkOrders[0].offset = slideCalc + "px";
      }

      // slider.style.marginLeft = slideCalc + "px";
    // }
  }

  private slideToOpenWorkOrder(): void {
    let self = this;

    if(this.sliderWorkOrders.length > 0) {
      let sliderWork: SliderWorkOrder = this.sliderWorkOrders.find( (slider: SliderWorkOrder) => { 
        let openSlide: boolean = 
          slider.status != this.workOrderStatusTypes.COMPLETED &&
          slider.status != this.workOrderStatusTypes.CANCELED &&
          slider.status != this.workOrderStatusTypes.CLOSED &&
          slider.status != this.workOrderStatusTypes.HOLD


        return openSlide
      });


      if(sliderWork != undefined && sliderWork != null) {

        let slideIndex: number = sliderWork.slideIndex;
        let workOrderId: string = sliderWork.id;

        let slider: any = document.getElementsByClassName("slideFocus")[0];
        setTimeout(function() { self.slideWorkOrder(slider, slideIndex, workOrderId); }, 100 );

      }
      
    }
  }

  private getEmPixels(element, documentElement): number {
    var extraBody;
    var important = "!important;";
    var style = "position:absolute" + important + "visibility:hidden" + important + "width:1em" + important + "font-size:1em" + important + "padding:0" + important;

    if (!element) {
        // Emulate the documentElement to get rem value (documentElement does not work in IE6-7)
        element = extraBody = document.createElement("body");
        extraBody.style.cssText = "font-size:1em" + important;
        documentElement.insertBefore(extraBody, document.body);
    }

    // Create and style a test element
    var testElement = document.createElement("i");
    testElement.style.cssText = style;
    element.body.appendChild(testElement);

    // Get the client width of the test element
    var value = testElement.clientWidth;

    if (extraBody) {
        // Remove the extra body element
        documentElement.body.removeChild(extraBody);
    }
    else {
        // Remove the test element
        element.body.removeChild(testElement);
    }

    // Return the em value in pixels
    return value;
  }

  public toggleMainMenu(event): void {

    if(this.clockedIn || this.demo) {
      if(this.mainMenuExpanded) {
        this.mainMenuExpanded = false;
      } else {
        this.mainMenuExpanded = true;

        this.slideToOpenWorkOrder();
      }
    } else {
      event.preventDefault();
    }
  }


  public menuClickAction(category: number): void {

    this.activeSubPage = category;

    switch(category) {

      case this.quickActionCategories.ATTACHED_PARTS:
        this.openWorkOrderQuickActionDialog(category);
        break;

      case this.quickActionCategories.STATUS:
        this.openWorkOrderQuickActionDialog(category);
        break;

      case this.quickActionCategories.SERVICE:
        this.openWorkOrderQuickActionDialog(category);
        break;

      case this.quickActionCategories.PAYMENT:
        this.openWorkOrderQuickActionDialog(category);
        break;

      case this.quickActionCategories.AUDIT:
        this.openWorkOrderAuditDialog();
        break;

      case this.quickActionCategories.WORK_ORDER:
        this.openWorkOrderDialog();
        break;

      case this.quickActionCategories.DIALPAD:
        this.slideOutMenuToggle(category);
        break;

      case this.quickActionCategories.ANSWER_CALL:
        break;

      case this.quickActionCategories.HANGUP_CALL:
        break;

      case this.quickActionCategories.CONTACTS:
        this.slideOutMenuToggle(category);
        // this.openContactDialog();
        break;

      case this.quickActionCategories.RECENT_CALLS:
        this.slideOutMenuToggle(category);
        break; 

      case this.quickActionCategories.DECLINE_CALL:
        break;

      case this.quickActionCategories.TEXT_MESSAGE:
        break;

      case this.quickActionCategories.CRM_HISTORY:
        break;

      case this.quickActionCategories.VOICEMAIL:
        break;

      case this.quickActionCategories.MENU_CHANGE:
        this.menuChange();
        break;

      case this.quickActionCategories.BLANK:
        break;

    }

  }
  // public onClick(elementId: string): void { 
  //   this.viewportScroller.scrollToAnchor(elementId);
  // }


  private menuChange(): void {
    
    if(
      this.quickActionMenu != undefined &&
      this.quickActionMenu != null &&
      this.quickActionMenu.length > 0 ) {


        if(this.quickActionMenu.length - 1 == this.menuPageFocus) {
          this.menuPageFocus = 0;
        } else {
          this.menuPageFocus++;
        }

      }

  }

  public slideOutMenuToggle(category: number): void {

    let subMenuIndex: number = this.subMenus.findIndex( (subMenu: QuickActionSubMenu) => { return subMenu.category == category;} );

    if(subMenuIndex == -1) {
      this.subMenus.push({
        category: category,
        minimized: false
      })
    } else {
      this.subMenus.splice(subMenuIndex, 1);
    }

  }

  public minimizeSlideOutMenuToggle(category: number): void {

    let subMenuIndex: number = this.subMenus.findIndex( (subMenu: QuickActionSubMenu) => { return subMenu.category == category;} );

    if(subMenuIndex > -1) {

      if(this.subMenus[subMenuIndex].minimized) {
        this.subMenus[subMenuIndex].minimized = false;
      } else {
        this.subMenus[subMenuIndex].minimized = true;
      }

    }

  }

  private getWorkOrderStatusIcon(workOrderStatus: number): string {

    let icon: string = "";
    let status: string = "";

    switch(workOrderStatus) {

      case this.workOrderStatusTypes.STANDBY:
        icon = 'sensor_occupied';
        status = "standby";
        break;

      case this.workOrderStatusTypes.HOLD:
        status = "hold";
        icon = 'hourglass_bottom';
        break;

      case this.workOrderStatusTypes.ENROUTE:
        status = "enroute";
        icon = 'local_shipping';
        break;

      case this.workOrderStatusTypes.STARTED:
        status = "started";
        icon = 'construction';
        break;

      case this.workOrderStatusTypes.COMPLETED:
        status = "completed";
        icon = 'published_with_changes';
        break;

      case this.workOrderStatusTypes.CLOSED:
        status = "closed";
        icon = 'task_alt';
        break;

      case this.workOrderStatusTypes.CANCELED:
        status = "canceled";
        icon = 'free_cancellation';
        break;

      default:
        icon = 'sensor_occupied';
        status = "standby";
        break;
    }

    return icon;
  }

  private getWorkOrderStatusColor(workOrderStatus: number): string {

    let color: string = "";

    switch(workOrderStatus) {

      case this.workOrderStatusTypes.STANDBY:
        color = '#1d00e9';
        break;

      case this.workOrderStatusTypes.HOLD:
        color = "#d64b0b";
        break;

      case this.workOrderStatusTypes.ENROUTE:
        color = "#cda30b";
        break;

      case this.workOrderStatusTypes.STARTED:
        color = "#37b003";
        break;

      case this.workOrderStatusTypes.COMPLETED:
        color = "#008ce9";
        break;

      case this.workOrderStatusTypes.CLOSED:
        color = "#1d00e9";
        break;

      case this.workOrderStatusTypes.CANCELED:
        color = "#d64b0b";
        break;

      default:
        color = '#1d00e9';
        break;
    }

    return color;
  }



  public openWorkOrderAuditDialog(): void {

    this.navigatorService.getWorkOrder(this.focusedWorkOrderId).then( (workOrder: WorkOrder) => {

      if(workOrder != undefined && workOrder != null) {

        this.workAuditTrackerDialogRef = this.dialog.open(
          VendorWorkAuditTrackerDialogComponent,
          { data: workOrder }
        );

        this.workAuditTrackerDialogRef.beforeClosed().subscribe(data => {
          console.log("Before Closed: ", data);
        });
        
        this.workAuditTrackerDialogRef.afterClosed().pipe(
          filter(data => data)
        ).subscribe(data => {
          console.log("Dialog Closed: ", data);
    
        });

      }

    });

  }

  public openWorkOrderDialog(): void {

    this.navigatorService.getWorkOrder(this.focusedWorkOrderId).then( (workOrder: WorkOrder) => {

      if(workOrder != undefined && workOrder != null) {

        this.workOrderDialogRef = this.dialog.open(
          VendorWorkOrderDialogComponent,
          { data: workOrder }
        );

        this.workOrderDialogRef.beforeClosed().subscribe(data => {
          console.log("Before Closed: ", data);
        });
        
        this.workOrderDialogRef.afterClosed().pipe(
          filter(data => data)
        ).subscribe(data => {
          console.log("Dialog Closed: ", data);


          this.navigatorService.updateWorkOrder(data).then(incomingData => {

            this.getDailyWorkOrders();

          });

        });

      }

    });
  }

  public openWorkOrderQuickActionDialog(category: number): void {

    this.navigatorService.getWorkOrder(this.focusedWorkOrderId).then( (workOrder: WorkOrder) => {

      let quickAction: WorkOrderQuickAction = {
        category: category,
        workOrderId: this.focusedWorkOrderId
      }

      this.workOrderQuickActionDialogRef = this.dialog.open(
        VendorWorkOrderQuickActionDialogComponent,
        { data: quickAction }
      );

      this.workOrderQuickActionDialogRef.beforeClosed().subscribe(data => {
        console.log("Before Closed: ", data);
      });
      
      this.workOrderQuickActionDialogRef.afterClosed().pipe(
        filter(data => data)
      ).subscribe(data => {
        console.log("Dialog Closed: ", data);


        this.navigatorService.updateWorkOrder(data).then(incomingData => {

          this.getDailyWorkOrders();

        });

      });

    });
  }

  public openContactDialog() {

    this.openVendorContactDialogRef = this.dialog.open(
      VendorContactDialogComponent
    );

    this.openVendorContactDialogRef.afterClosed().subscribe(data => {
      console.log("Dialog Closed", data);
    })
  }
  
}